import * as _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./InvoicesList.scss";
import YeulsTable from "../../../../yeuls-table";
import { NumbersService } from "../../../../../services/numbers";
import DownloadInvoiceIcon from "../../../../../shared/icons/download-invoice-icon.svg";
import GreyDownloadInvoiceIcon from "../../../../../shared/icons/grey-download-invoice-icon.svg";
import { Checkbox } from "@material-ui/core";

const InvoicesList = (props) => {
  const { invoices, clients, invoiceChanges, setInvoiceChanges, filterValue } = props;
  const { t, i18n } = useTranslation();
  const headers = useMemo(() => [t("created-date"), t("invoice-number"), t("client-name"), t("total"), t("currency"), t("rate"), t("total-nis"), t("paid"), "."], [i18n.language]);
  const columns = "createdDateView.invoice_number.clientNameView.amountCurrencyView.currencyView.currencyRateView.amountNisView.paidView.attachmentsView";

  const onAttachmentDownloadClick = (invoice) => {
    if (!_.isNil(invoice.invoice_file_link)) {
      window.open(invoice.invoice_file_link, '_blank');
    }

    if (!_.isNil(invoice.receipt_file_link)) {
      window.open(invoice.receipt_file_link, '_blank');
    }

    if (!_.isNil(invoice.invoice_receipt_file_link)) {
      window.open(invoice.invoice_receipt_file_link, '_blank');
    }
  }
  const isAttachmentDownloadAvailable = (invoice) => {
    return !_.isNil(invoice.invoice_file_link) ||
      !_.isNil(invoice.receipt_file_link) ||
      !_.isNil(invoice.invoice_receipt_file_link)
  }

  const mappedInvoices = useMemo(() => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const filterValueToLowerCase = filterValue?.toLowerCase();

    return _(invoices)
      .filter((invoice) => `${invoice.invoice_number || ""}`.indexOf(filterValueToLowerCase) !== -1)
      .orderBy("created_on", "desc")
      .map((invoice) => {
        const createdDateView = invoice.created_on ? new Date(invoice?.created_on).toLocaleDateString("en-GB", dateOptions) : "";
        const clientNameView = _.find(clients, { id: invoice.client_id })?.client_name || "";
        const amountCurrencyView = NumbersService.numberWithCommas(invoice.amount_currency);
        const currencyView = NumbersService.getCurrency(invoice.currency);
        const amountNisView = NumbersService.numberWithCommas(invoice.amount_with_vat);
        const currencyRateView = NumbersService.roundCurrency(invoice.currency_rate);
        const attachmentsView =
          <div onClick={() => onAttachmentDownloadClick(invoice)}>
            {isAttachmentDownloadAvailable(invoice) ?
              <img src={DownloadInvoiceIcon} alt="download invoice"/> :
              <img src={GreyDownloadInvoiceIcon} alt="download invoice"/>}
          </div>

        const paidView = <Checkbox
          classes={{ checked: 'table-checkbox-checked', root: 'table-checkbox-root' }}
          checked={invoiceChanges[invoice.id] ? !!invoiceChanges[invoice.id].paid : !!invoice.paid}
          onChange={(e) => setInvoiceChanges({
            ...invoiceChanges,
            [invoice.id]: { ...invoice, paid: e.target.checked }
          })}
        />;

        return {
          ...invoice,
          createdDateView,
          clientNameView,
          amountCurrencyView,
          currencyView,
          currencyRateView,
          amountNisView,
          attachmentsView,
          paidView
        }
      })
      .value();

  }, [invoices, invoiceChanges, filterValue]);

  return <YeulsTable
    data={mappedInvoices}
    headers={headers}
    columns={columns}
    totalCount={mappedInvoices.length}
    perPageItemCount={12}
    partialPageCount={3}
    nextPageText=">"
    prePageText="<"
    className="invoices-table-container"
  />;
}

export default InvoicesList;
