import * as actions from '../redux/actions/index';
import { connect } from 'react-redux';
import Auth from '../components/auth';

export function mapStateToProps({ userState, languageState }) {
  return {
    user: userState.user,
    language: languageState,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (payload) => dispatch(actions.setUserInfo(payload)),
    setLanguage: (payload) => dispatch(actions.setLanguage(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
