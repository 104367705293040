import * as _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import YeulsTable from "../../../../yeuls-table";

import "./SalariesList.scss";
import DownloadInvoiceIcon from "../../../../../shared/icons/download-invoice-icon.svg";
import GreyDownloadInvoiceIcon from "../../../../../shared/icons/grey-download-invoice-icon.svg";

const SalariesList = (props) => {
  const { salaries, filterValue } = props;
  const { t, i18n } = useTranslation();

  const headers = useMemo(() => [t("year"), t("month"), t("monthly-files"), t("salary")], [i18n.language]);
  const columns = "year.month.monthlyOverviewLinkView.salaryLinkView";

  const mappedSalaries = useMemo(() => {
    // const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    // const filterValueToLowerCase = filterValue?.toLowerCase();

    return _(salaries)
      // .filter((salary) => `${salary.salary_number || ""}`.indexOf(filterValueToLowerCase) !== -1)
      .orderBy(["year", "month"], ["desc", "desc"])
      .map((salary) => {
        // const dateView = salary.created_on ? new Date(salary?.created_on).toLocaleDateString("en-GB", dateOptions) : "";
        const monthlyOverviewLinkView = salary.monthly_overview_file_link ?
          <span onClick={()=>{window.open(salary.monthly_overview_file_link, '_blank')}}><img src={DownloadInvoiceIcon} alt="download invoice"/>{salary.monthly_file_name} </span>:
          <img src={GreyDownloadInvoiceIcon} alt="download invoice"/>;
        const salaryLinkView = salary.salary_file_link ?
          <span onClick={()=>{window.open(salary.salary_file_link, '_blank')}}><img src={DownloadInvoiceIcon} alt="download invoice"/>{salary.salary_file_name} </span>:
          <img src={GreyDownloadInvoiceIcon} alt="download invoice"/>;

        return {
          ...salary,
          monthlyOverviewLinkView,
          salaryLinkView
        }
      })
      .value();
  }, [salaries, filterValue])

  return <YeulsTable
    data={mappedSalaries}
    headers={headers}
    columns={columns}
    totalCount={mappedSalaries.length}
    perPageItemCount={12}
    partialPageCount={3}
    nextPageText=">"
    prePageText="<"
    className="salaries-table-container"
  />;
}

export default SalariesList;