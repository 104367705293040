export const Invoice = {
  INVOICE: '/invoice',
  USER_INVOICE: '/invoice/user',
  INVOICE_UPDATE: '/invoice/update',
  MISSED_INVOICE: '/invoice/miss',
};

export const Receipt = {
  MISSED_RECEIPT: '/receipt/miss',
  RECEIPT: '/receipt',
  USER_RECEIPT: '/receipt/user',
};

export const InvoiceReceipts = {
  INVOICE_RECEIPT_FILES: '/invoice-receipt/files',
  MISSED_INVOICE_RECEIPT: '/invoice-receipt/miss',
};

export const Salary = {
  SALARY: '/salary',
  SALARY_ADD: '/salary/add',
  USER_SALARY: 'salary/user',
  USER_ONE_ZERO_SIX: 'salary/one-zero-six',
  SALARY_UPDATE: '/salary/update',
  SALARY_FILES: '/salary/files',
  MONTHLY_FILES: '/salary/monthly/files',
  ONE_ZERO_SIX_FILES: '/salary/one-zero-six/files',
  SALARY_MISSED: '/salary/miss',
  MONTHLY_MISSED: '/salary/monthly/miss',
  IMPORT: '/salary/import',
  SALARY_RECORD: '/salary/salary-record',
};

export const Users = {
  USER: '/user',
  USER_DOCUMENTS: '/user/documents',
  IS_MANAGER: '/user/is-manager',
  USER_WORKERS: '/user/workers',
  TRANSFORM_USER_TO_COMPANY: '/user/transform-user-to-company'
}

export const Companies = {
  COMPANY: '/company',
  COMPANY_DOCUMENTS: '/company/documents',
  USER_COMPANY: '/company/user-company',
}

export const Expenses = {
  EXPENSE: '/expense',
  EXPENSE_UPDATE: '/expense/update',
}

export const WorkerReport = {
  REPORT: '/worker-report/report',
  REPORT_EMPLOYEE: '/worker-report/report-employee',
  REPORT_EMPLOYEE_ITEM: '/worker-report/report-employee-item',
  SUBMIT_BY_MANAGER: '/worker-report/submit-by-manager',
  SUBMIT_BY_EMPLOYEE: '/worker-report/submit-by-employee'
}

export const Client = {
  CLIENT: "/client",
  CLIENT_AGENT: "/client/client-agent",
  CLIENT_DOCUMENTS: '/client/documents',
}

export const Supplier = {
  SUPPLIER: "/supplier",
  SUPPLIER_AGENT: "/supplier/supplier-agent"
}

export const Yeuls = {
  MERGE_AGENTS:"/yeuls/merge-agents",
  MERGE_DOCS:"/yeuls/merge-docs",
  MERGE_SALARIES:"/yeuls/merge-salaries",
  PUSH:"/yeuls/push",
  SYNC_STORAGE_DOCUMENTS: "/yeuls/sync-storage-documents"
}