import React  from "react";

import "./InsuranceUserForm.scss";
import { Grid } from "@material-ui/core";
import InputComponent from "../../input-component";

const InsuranceUserForm = (props) => {
  const { insuranceFormData, setInsuranceFormData} = props;

  return <Grid spacing={3} container>
    <Grid item xs={12}>
      <InputComponent multiline minRows={3} field="insurance_notes" tLabel="insurance-notes" formData={insuranceFormData} setFormData={setInsuranceFormData}/>
    </Grid>
  </Grid>
}

export default InsuranceUserForm;