import * as _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DateCarouselItem from './date-carousel-item';
import './DateCarousel.scss';

const DateCarousel = (props) => {
  const { onMonthChange } = props;
  const { t, i18n, } = useTranslation();
  const [months, setMonth] = useState([t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]);
  const [currentMonth, setCurrentMonth] = useState(months[new Date().getMonth()]);
  const [currentYear, setCurrenYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const newMonthsArray = [t('January'), t('February'), t('March'), t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')];
    const index = _.findIndex(months, (month)=> month === currentMonth);

    setMonth(newMonthsArray);
    setCurrentMonth(newMonthsArray[index]);
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    if (onMonthChange) {
      const currentIndex = months.findIndex((value) => currentMonth === value);

      onMonthChange({ month: currentIndex + 1, year: currentYear });
    }
    // eslint-disable-next-line
  }, [currentMonth, currentYear]);

  const onPreviousMonthClick = () => {
    const currentIndex = months.findIndex((value) => currentMonth === value);

    if (currentIndex === 0) {
      setCurrentMonth(months[11]);
      return;
    }

    setCurrentMonth(months[currentIndex - 1]);
  }

  const onNextMonthClick = () => {
    const currentIndex = months.findIndex((value) => currentMonth === value);

    if (currentIndex === 11) {
      setCurrentMonth(months[0]);
      return;
    }

    setCurrentMonth(months[currentIndex + 1]);
  }

  const onPreviousYearClick = () => {
    setCurrenYear(currentYear - 1)
  }

  const onNextYearClick = () => {
    setCurrenYear(currentYear + 1)
  }

  return <div className="date-carousel-wrapper">
    <DateCarouselItem value={currentMonth} onPreviousClick={onPreviousMonthClick} onNextClick={onNextMonthClick} className="month"/>
    <DateCarouselItem value={currentYear} onPreviousClick={onPreviousYearClick} onNextClick={onNextYearClick}/>
  </div>
}

export default DateCarousel;