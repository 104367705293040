import { useState } from 'react';

/**
 * This hook is used to toggle modals
 */
export const useDialog = () => {
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleDialogOpen = () => {
		setDialogOpen(true);
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	return {
		dialogOpen,
		handleDialogOpen,
		handleDialogClose,
	};
};
