import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './index.scss';
import App from './App';
import { store } from './redux/store/index';
import { SnackbarProvider } from 'notistack';

import instantiateAxiosConfig from './api/config';
import requestInterceptor from './api/requestInterceptor';

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <SnackbarProvider maxSnack={3} autoHideDuration={3000} preventDuplicate>
        <App/>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root'),
  () => {
    instantiateAxiosConfig();
    requestInterceptor();
  }
);
