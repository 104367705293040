import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./BankUserDetails.scss";
import { Mode } from "../../../../../shared/constants";
import DetailComponent from "../../../common-components/details-component";
import BankForm  from "../../../common-components/forms/bank-form";

const BankUserDetails = (props) => {
  const { t } = useTranslation();
  const { mode, user, userFormData, setUserFormData } = props;

  return <div className="user-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("bank-name")} value={user.bank_name}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("bank-number")} value={user.bank_number}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("bank-branch-number")} value={user.bank_branch_number}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("bank-account-number")} value={user.bank_account_number}/> </Grid>
    </Grid>}
    {mode === Mode.edit && <BankForm bankFormData={userFormData} setBankFormData={setUserFormData}/>}
  </div>
}

export default BankUserDetails;