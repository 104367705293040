import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider, Grid } from "@material-ui/core";

import "./CompanyUserItem.scss";
import UserCompaniesFormItem from "../../../../common-components/forms/user-companies-form";
import { getUserNameView } from "../../../../common-components/utils";
import { CompanyRole } from "../../../../../../shared/constants";

const defaultUserCompanyFormData = {
  daily_rate: { value: null, error: false },
  hourly_rate: { value: null, error: false },
  company_role: { value: CompanyRole.employee, error: false },
  is_daily_rate: { value: true, error: false },
}

const CompanyUserItem = (props) => {
  const { t } = useTranslation();
  const { user, onChange } = props;
  const [expanded, setExpanded] = useState(false)
  const [companyUserFormData, setCompanyUserFormData] = useState(defaultUserCompanyFormData);

  useEffect(() => {

    onChange(!expanded ? null : {
      daily_rate: +companyUserFormData.daily_rate.value || null,
      hourly_rate: +companyUserFormData.hourly_rate.value || null,
      company_role: companyUserFormData.company_role.value,
      is_daily_rate: companyUserFormData.is_daily_rate.value,
      user_id: user.id
    });
    // eslint-disable-next-line
  }, [companyUserFormData, expanded]);

  return <div className="company-user-form-item">
    <div className="company-user-form-item-header">
      <Grid container>
        <Grid xs={1} item className="vertically-center">
          <Checkbox checked={expanded} onChange={(e) => setExpanded(e.target.checked)}/>
        </Grid>
        <Grid xs={11} item className="vertically-center">
          {getUserNameView(user, t)}
        </Grid>
      </Grid>
    </div>
    {expanded && <Divider className="company-user-form-divider"/>}
    {expanded && <div className="company-user-form-item-content">
      <UserCompaniesFormItem companyUserFormData={companyUserFormData} setCompanyUserFormData={setCompanyUserFormData}/>
    </div>}
  </div>

}

export default CompanyUserItem;