import React from "react";

import "./RateButton.scss";

const RateButton = (props) => {
  const {onClick, children, isActive} = props;

  return <div className={`monthly-report-employee-table-rate-button ${isActive ? 'active' : ''}`}
              onClick={onClick}>
    {children}
  </div>
}

export default RateButton;