import { Box, Button, Grid } from '@material-ui/core';
import Text from '../../../../containers/Text';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import React from 'react';
import useStyles from './file.styles';
import './FileComponent.scss';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { IoIosAttach } from 'react-icons/io';

export const FileComponent = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [filesCollection, setFilesCollection] = useState([]);
  const [deleteFile, setDeleteFile] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFilesCollection(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: props?.multiple ? props?.multiple : false,
    onDrop,
    accept: props?.fileType ? props?.fileType : 'application/pdf',
  });

  const removeFile = (id, isNeedToPush = false) => {
    if (props.removeFileKey) {
      props.setFormData({
        ...props.formData,
        [props.removeFileKey]: { value: true, error: false },
      });
    }
    if (isNeedToPush) {
      props.removedFile(id);
    }
  };

  const viewFile = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  useEffect(() => {
    if (filesCollection.length > 0 && props.removeFileKey) {
      props.setFormData({
        ...props.formData,
        [props.field]: { value: filesCollection, error: false },
        [props.removeFileKey]: { value: false, error: false },
      });
    } else {
      props.setFormData({
        ...props.formData,
        [props.field]: { value: filesCollection, error: false },
      });
    }
  }, [filesCollection]);

  return (
    <Grid item xs={props?.size || 6} className="file-wrapper">
      <label className="input-component-item-label">{t(props?.label)}</label>
      <Box p={3} className="input-component-item-box">
        <div
          {...getRootProps({
            className: isDragActive ? classes.onDrag : classes.dropzone,
          })}
        >
          <input {...getInputProps()} />
          <p style={{ margin: '5px' }}>
            <Text text={t(props?.multiple ? 'drag-n-drop' : 'drag-drop')} />
          </p>
          <p style={{ margin: '5px' }}>
            <Text
              text={t(
                props?.multiple ? 'drag-n-drop-format' : 'drag-drop-format'
              )}
            />
          </p>
          <p style={{ margin: '5px' }}>
            <Text text={props?.format} />
          </p>
        </div>
        {props.formData[props.field]?.error && (
          <p style={{ margin: '5px', color: 'red' }}>
            <Text text={t('required')} />
          </p>
        )}
        {filesCollection.map((f, id) => (
          <>
            <div key={f.name} className={classes.file}>
              <div
                className={
                  i18n.language !== 'heb'
                    ? classes.fileName
                    : classes.fileNameRevert
                }
              >
                <IoIosAttach />
                {f.name}
              </div>
              <div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    window.open(URL.createObjectURL(f), '_blank', 'noreferrer');
                  }}
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    color: '#003164',
                  }}
                  startIcon={<AiOutlineEye />}
                ></Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    removeFile(id);
                    setFilesCollection(
                      filesCollection.filter((file, index) => index !== id)
                    );
                  }}
                  className={classes.removeButton}
                  startIcon={<AiOutlineDelete />}
                ></Button>
              </div>
            </div>
          </>
        ))}
        {((filesCollection.length === 0 && !props.multiple) || props.multiple) && props.file && props.file.length > 0 && (
          <>
            {props.file.filter(f => deleteFile.length === 0 ? true : !deleteFile.includes(f.id)).map((f) => (
              <div key={f.id} className={classes.file}>
                <div
                  className={
                    i18n.language !== 'heb'
                      ? classes.fileName
                      : classes.fileNameRevert
                  }
                >
                  <IoIosAttach />
                  {f.fileName}
                </div>
                <div>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      viewFile(f.file);
                    }}
                    className={classes.removeButton}
                    startIcon={<AiOutlineEye />}
                  ></Button>
                  {/* <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        downloadFile(f.file, f.fileName);
                      }}
                      className={classes.removeButton}
                      startIcon={<AiOutlineDownload />}
                    ></Button> */}
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setDeleteFile(
                        deleteFile.length > 0 ? deleteFile.concat(f.id) : [f.id]
                      );
                      removeFile(f.id, true);
                    }}
                    className={classes.removeButton}
                    startIcon={<AiOutlineDelete />}
                  ></Button>
                </div>
              </div>
            ))}
          </>
        )}
      </Box>
    </Grid>
  );
};
