import * as actions from '../redux/actions/index';
import { connect } from 'react-redux';
import UserPage from '../components/user-page';

export function mapStateToProps({ userState }) {
  return {
    user: userState.user,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (payload) => dispatch(actions.setUserInfo(payload)),
    setMonthlyOverview: (payload) =>
      dispatch(actions.setMonthlyOverview(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
