import React, { useMemo, useState } from "react";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MenuItem, TextField } from "@material-ui/core";

import YeulsButtonSwitcher from "./yeuls-button-switcher";
import "./InvoiceConfig.scss";
import { useTranslation } from "react-i18next";
import MonthlyReportHeader from "../../monthly-report-header";
import { Currency } from "../../../../shared/constants";


const InvoiceConfig = (props) => {
  const { dailyRate, exchangeRate } = props;
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(new Date());
  // const [currency, setCurrency] = useState();
  const languageOptions = useMemo(() => {
    return [
      { id: 0, label: t('heb'), onSelect: () => {}, activeByDefault: true },
      { id: 1, label: t('eng'), onSelect: () => {} }
    ];
    // eslint-disable-next-line
  }, [i18n.language]);
  const vatOptions = useMemo(() => {
    return [
      { id: 0, label: t('yes'), onSelect: () => {}, activeByDefault: true },
      { id: 1, label: t('no'), onSelect: () => {} }
    ];
    // eslint-disable-next-line
  }, [i18n.language]);


  return <div className="invoice-config-wrapper">
    <div className="invoice-config-items-wrapper">
      <div className="invoice-config-item">
        <label className="invoice-config-label">{t('date')}</label>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.yyyy"
          value={date}
          className="invoice-config-date-picker"
          InputAdornmentProps={{ position: 'end', variant: 'standard' }}
          onChange={(newValue) => {
            setDate(newValue);
          }}
        />
      </div>

      <div className="invoice-config-item">
        <label className="invoice-config-label">{t('currency')}</label>

        <TextField
          variant="outlined"
          select
          className="invoice-config-currency"
        >
          <MenuItem key={Currency.nis} value={Currency.nis}>{t(Currency.nis)}</MenuItem>
          <MenuItem key={Currency.usd} value={Currency.usd}>{t(Currency.usd)}</MenuItem>
          <MenuItem key={Currency.eur} value={Currency.eur}>{t(Currency.eur)}</MenuItem>
        </TextField>
      </div>

      <div className="invoice-config-item">
        <label className="invoice-config-label">{t('language')}</label>
        <YeulsButtonSwitcher options={languageOptions}/>
      </div>

      <div className="invoice-config-item">
        <label className="invoice-config-label">{t('include-vat-17')}</label>
        <YeulsButtonSwitcher options={vatOptions}/>
      </div>
    </div>
    <MonthlyReportHeader dailyRate={dailyRate} exchangeRate={exchangeRate}/>
  </div>
}

export default InvoiceConfig;