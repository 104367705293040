import React from "react";
import { useTranslation } from "react-i18next";

import "./EmployeeSalaryFooter.scss";
import { NumbersService } from "../../../../services/numbers";
import { SalaryStatus } from "../../../../shared/constants";

const EmployeeSalaryFooter = (props) => {
  const { t } = useTranslation();
  const { totalCost, totalFeeCost, feePercent, currentSalary } = props;

  return <footer className="employee-salary-footer">
    <div className="employee-salary-footer-item">
      <div>{t("total-cost")}</div>
      <div>₪{` ${NumbersService.numberWithCommas(currentSalary?.status !== SalaryStatus.done ? totalCost : currentSalary.agent_total_cost, true)}`}</div>
    </div>
    <div className="employee-salary-footer-item">
      <div>{`${t("total-fee-cost")} ${currentSalary?.status !== SalaryStatus.done ? feePercent : currentSalary.agent_fee_from_ac_table}%`}</div>
      <div>₪{` ${NumbersService.numberWithCommas(currentSalary?.status !== SalaryStatus.done ? totalFeeCost : currentSalary.agent_total_fee_from_ac_table, true)}`}</div>
    </div>
    <div className="employee-salary-footer-item">
      <div>{t("total")}</div>
      <div>₪{` ${NumbersService.numberWithCommas(currentSalary?.status !== SalaryStatus.done ? Math.floor(( totalCost - totalFeeCost ) * 100) / 100 : currentSalary.agent_total_paid, true)}`}</div>
    </div>
    {currentSalary?.status === SalaryStatus.done &&
    <div className="employee-salary-footer-item">
      <div>{t('balance')}</div>
      <div>₪{NumbersService.numberWithCommas(currentSalary.agent_total_balance, true)}</div>
    </div>}
  </footer>
}

export default EmployeeSalaryFooter;