import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './NewCompany.scss';
import {
  validateGeneralCompanyForm,
  validateTermForm,
} from '../../common-components/validators';
import ProgressIndicator from '../../common-components/progress-indicator';
import GeneralCompanyForm from '../../common-components/forms/general-company-form';
import TermsAndConditionsForm from '../../common-components/forms/terms-and-conditions-form';
import YeulsButton from '../../../yeuls-button';
import CompanyLinks from './company-links';
import { CompanyAccountDocuments } from './company-account-documents';
import { jsonToFormData } from '../../common-components/utils';

const defaultCompanyFormData = {
  company_name: { value: null, error: false },
  email: { value: null, error: false },
  phone: { value: null, error: false },
  country: { value: null, error: false },
  zip: { value: null, error: false },
  city: { value: null, error: false },
  address: { value: null, error: false },
  business_number: { value: null, error: false },
  withholding_tax: { value: null, error: false },
  withholding_tax_expiration: { value: null, error: false },
  general_ledger: { value: null, error: false },

  yeuls_fee: { value: null, error: false },
  max_yeuls_fee: {
    value: +process.env.REACT_APP_MAX_YEULS_FEE || null,
    error: false,
  },
  registration_fee: {
    value: process.env.REACT_APP_REGISTRATION_FEE || null,
    error: false,
  },
  registration_fee_free: { value: false, error: false },
  included_vat: { value: true, error: false },
  salary_date: { value: null, error: false },
  calculation_type: { value: null, error: false },
  report_type: { value: null, error: false },
  report_fee: { value: null, error: false },
  default_daily_rate: { value: null, error: false },
  currency: { value: null, error: false },
  start_date: { value: null, error: false },
  end_date: { value: null, error: false },
  notes: { value: null, error: false },
  serviceAgreement: { value: null, error: false },
  otherDocs: { value: null, error: false },
};
const steps = {
  general: 1,
  termsAndConditions: 2,
  links: 3,
  accountDocuments: 4,
};

const NewCompany = (props) => {
  const { onCreateCompany, onCancel, users, clients, suppliers } = props;
  const { t, i18n } = useTranslation();
  const stepsConfig = useMemo(
    () => [
      {
        value: steps.general,
        label: t('general'),
        validate: validateGeneralCompanyForm,
      },
      {
        value: steps.termsAndConditions,
        label: t('terms-and-conditions'),
        validate: validateTermForm,
      },
      { value: steps.links, label: t('links') },
      { value: steps.accountDocuments, label: t('account-documents') },
    ],
    // eslint-disable-next-line
    [i18n.language]
  );
  const [activeStep, setActiveStep] = useState(stepsConfig[0]);
  const [companyFormData, setCompanyFormData] = useState(
    defaultCompanyFormData
  );
  const [companyUsers, setCompanyUsers] = useState([]);
  const [companyClients, setCompanyClients] = useState([]);
  const [companySuppliers, setCompanySuppliers] = useState([]);

  const nextStep = () => {
    if (activeStep.value !== steps.accountDocuments) {
      if (
        !activeStep.validate ||
        (activeStep.validate,
        activeStep.validate(companyFormData, setCompanyFormData))
      )
        setActiveStep(stepsConfig[activeStep.value]);
    } else {
      saveCompany();
    }
  };

  const saveCompany = () => {
    const company = {
      company_name: companyFormData.company_name.value,
      business_number: companyFormData.business_number.value,
      email: companyFormData.email.value,
      phone: companyFormData.phone.value,
      country: companyFormData.country.value,
      city: companyFormData.city.value,
      address: companyFormData.address.value,
      zip: companyFormData.zip.value,
      withholding_tax: +companyFormData.withholding_tax.value,
      withholding_tax_expiration: companyFormData.withholding_tax_expiration.value,
      general_ledger: companyFormData.general_ledger.value,
      companyUsers,
      companyClients,
      companySuppliers,
      companyTerms: {
        yeuls_fee: +companyFormData.yeuls_fee.value,
        salary_date: companyFormData.salary_date.value,
        calculation_type: companyFormData.calculation_type.value,
        max_yeuls_fee: +companyFormData.max_yeuls_fee.value,
        registration_fee: +companyFormData.registration_fee.value,
        registration_fee_paid: !companyFormData.registration_fee.value,
        included_vat: companyFormData.included_vat.value,
        report_type: companyFormData.report_type.value,
        report_fee: +companyFormData.report_fee.value,
        default_daily_rate: +companyFormData.default_daily_rate.value,
        currency: companyFormData.currency.value,
        start_date: companyFormData.start_date.value,
        end_date: companyFormData.end_date.value,
        notes: companyFormData.notes.value,
      },
    };

    const formData = {
      serviceAgreement: companyFormData.serviceAgreement.value,
      otherDocs: companyFormData.otherDocs.value,
      formData: JSON.stringify(company),
    };

    onCreateCompany(jsonToFormData(formData));
  };

  return (
    <div className="new-company-wrapper">
      <div className="new-company-indicator-wrapper">
        <ProgressIndicator
          steps={stepsConfig}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </div>
      <div className="new-company-form-container">
        {activeStep?.value === steps.general && (
          <GeneralCompanyForm
            generalFormData={companyFormData}
            setGeneralFormData={setCompanyFormData}
          />
        )}
        {activeStep?.value === steps.termsAndConditions && (
          <TermsAndConditionsForm
            termFormData={companyFormData}
            setTermFormData={setCompanyFormData}
            showDefaultDailyRate={true}
            showRegistrationFee={true}
          />
        )}
        {activeStep?.value === steps.links && (
          <CompanyLinks
            users={users}
            companyUsers={companyUsers}
            setCompanyUsers={setCompanyUsers}
            clients={clients}
            companyClients={companyClients}
            setCompanyClients={setCompanyClients}
            suppliers={suppliers}
            companySuppliers={companySuppliers}
            setCompanySuppliers={setCompanySuppliers}
          />
        )}
        {activeStep?.value === steps.accountDocuments && (
          <CompanyAccountDocuments companyFormData={companyFormData} setCompanyFormData={setCompanyFormData} />
        )}
      </div>
      <div className="new-company-buttons-container">
        <YeulsButton variant="negative" onClick={onCancel}>
          {t('cancel')}
        </YeulsButton>
        <YeulsButton onClick={nextStep}>{t('next')}</YeulsButton>
      </div>
    </div>
  );
};

export default NewCompany;
