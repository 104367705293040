import { Tooltip } from '@material-ui/core';
import { map } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import YeulsTable from '../../yeuls-table';
import './WorkerList.scss';

export const WorkerList = ({ workers, setSelectedWorker }) => {
  const { t, i18n } = useTranslation();
  const headers = useMemo(
    () => [t('name'), t('id'), t('agent-id'), t('email'), t('status')],
    [i18n.language]
  );

  const mappedWorkers = useMemo(
    () =>
      map(workers, (worker) => {
        const statusView = worker.active ? (
          <div className="status active">{t('active')}</div>
        ) : (
          <div className="status dismissed">{t('dismissed')}</div>
        );
        const emailView = (
          <Tooltip title={worker.email}>
            <div>{worker.email}</div>
          </Tooltip>
        );
        const nameView = (
          <div
            className="clickable-block"
            onClick={() => setSelectedWorker(worker)}
          >
            {worker.firstname} {worker.lastname}
          </div>
        );
        const identityNumber = worker.identity_number
          ? '000000000' + worker.identity_number
          : '';
        const identityNumberView = identityNumber.substring(
          identityNumber.length - 9
        );

        return {
          ...worker,
          statusView,
          emailView,
          identityNumberView,
          nameView,
        };
        // eslint-disable-next-line
      }),
    [workers]
  );

  return (
    <div className="worker-list-wrapper">
      <div className="worker-list-table-wrapper">
        <YeulsTable
          data={mappedWorkers}
          headers={headers}
          columns="nameView.identityNumberView.santec_account_id.emailView.statusView"
          pagination={false}
        />
      </div>
    </div>
  );
};
