import axios from "axios";
import * as endpoints from "../endpoints";
import { constants } from "../constants";

const baseUrl = constants.baseURL;

export const mergeAgents = async () => {
  return axios({
    method: 'post',
    url: endpoints.Yeuls.MERGE_AGENTS,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const mergeDocs = async () => {
  return axios({
    method: 'post',
    url: endpoints.Yeuls.MERGE_DOCS,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const mergeSalaries = async () => {
  return axios({
    method: 'post',
    url: endpoints.Yeuls.MERGE_SALARIES,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const pushAgents = async () => {
  return axios({
    method: 'post',
    url: endpoints.Yeuls.PUSH,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const syncStorageDocuments = async () => {
  return axios({
    method: 'post',
    url: endpoints.Yeuls.SYNC_STORAGE_DOCUMENTS,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};
