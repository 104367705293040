import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Button, Dialog } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import YeulsButton from "../yeuls-button";
import "./ConfirmDialog.scss";

const ConfirmDialog = (props) => {
  const { open, onClose, onSubmit, title, children, confirmTitleKey = 'confirm', rejectTitleKey = 'cancel' } = props;
  const { t } = useTranslation();
  const currentLng = i18n.language;

  return <Dialog
    // fullScreen={true}
    open={open}
    onClose={onClose}>
    <div className={`confirm-dialog-wrapper ${currentLng === 'heb' ? 'rotate' : ''}`}>
      <div className="confirm-dialog-header">
        <div className="confirm-dialog-title">
          {title}
        </div>
        <Button onClick={onClose}>
          <CloseIcon/>
        </Button>
      </div>
      <div className="confirm-dialog-content">
        {children}
      </div>
      <div className="confirm-dialog-footer">
        <YeulsButton variant="negative" onClick={onClose}>{t(rejectTitleKey)}</YeulsButton>
        <YeulsButton onClick={onSubmit}>{t(confirmTitleKey)}</YeulsButton>
      </div>
    </div>
  </Dialog>
}

export default ConfirmDialog;