import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

import "./InputComponent.scss";

const InputComponent = ({ field, tLabel, formData, setFormData, ...props }) => {
  const { t } = useTranslation();

  return <div className="input-component-item">
    <label className="input-component-item-label">{t(tLabel)}</label>
    <TextField
      variant="outlined"
      className="input-component-item-input"
      value={formData[field]?.value || ''}
      error={formData[field]?.error || false}
      onChange={(event) => setFormData({ ...formData, [field]: { value: event.target.value, error: false } })}
      {...props}
    />
  </div>
}

export default InputComponent;