import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./TermsAndConditionsDetais.scss";
import { Mode } from "../../../../../shared/constants";
import DetailComponent from "../../../common-components/details-component";
import TermsAndConditionsForm from "../../../common-components/forms/terms-and-conditions-form";

const TermsAndConditionsCompanyDetails = (props) => {
  const { t } = useTranslation();
  const { mode, company, companyFormData, setCompanyFormData } = props;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  return <div className="company-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("yeuls-fee")} value={company.companyTerms?.yeuls_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("max-yeuls-fee")} value={company.companyTerms?.max_yeuls_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("salary-date")} value={company.companyTerms?.salary_date}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("calculation-type")} value={company.companyTerms?.calculation_type}/></Grid>
      <Grid item xs={6}><DetailComponent
        label={t("start-date")}
        value={company.companyTerms?.start_date ?
          new Date(company.companyTerms?.start_date).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent
        label={t("end-date")}
        value={company.companyTerms?.end_date ?
          new Date(company.companyTerms?.end_date).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("included-vat")} value={company.companyTerms?.included_vat ? t("yes") : t("no")}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("report-type")} value={company.companyTerms?.report_type}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("report-fee")} value={company.companyTerms?.report_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("default-daily-rate")} value={company.companyTerms?.default_daily_rate}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("currency")} value={company.companyTerms?.currency}/></Grid>
      <Grid item xs={6}/>
      <Grid item xs={6}><DetailComponent label={t("registration-fee")} value={company.companyTerms?.registration_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("registration-fee-paid")} value={company.companyTerms?.registration_fee_paid ? t("yes") : t("no")}/></Grid>
      <Grid item xs={12}><DetailComponent label={t("notes")} value={company.companyTerms?.notes}/></Grid>
    </Grid>}
    {mode === Mode.edit &&
    <TermsAndConditionsForm termFormData={companyFormData} setTermFormData={setCompanyFormData} showEndDate={true} showDefaultDailyRate={true}/>}
  </div>
}

export default TermsAndConditionsCompanyDetails;