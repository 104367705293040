import React from "react";

import "./ClientsList.scss";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import YeulsTable from "../../yeuls-table";

const ClientsList = (props) => {
  const { t } = useTranslation();
  const { clients, setSelectedClient } = props;

  const mappedClients = _.map(clients, (client) => ( {
    ...client,
    agentsCount: client.clientAgents?.length || 0,
    nameView: <div className="clickable-block" onClick={() => setSelectedClient(client)}>{client.client_name}</div>
  } ));
  const headers = [t("client-name"), t("business-number"), t("agent-id"), t("email"), t("agents-count")];

  return <div className={"clients-list-wrapper"}>
    <YeulsTable
      data={mappedClients}
      headers={headers}
      columns="nameView.business_number.santec_account_id.email.agentsCount"
      totalCount={mappedClients.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className="clients-table-container"
    />
  </div>
}

export default ClientsList;