import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadInvoiceIcon from '../../shared/icons/download-invoice-icon.svg';
import GreyDownloadInvoiceIcon from '../../shared/icons/grey-download-invoice-icon.svg';
import CheckBox from '../../shared/icons/check-box.svg';

import Text from '../../containers/Text';
import './Table.scss';

import { NumbersService } from '../../services/numbers';

import * as moment from 'moment';
import * as _ from 'lodash';

const InvoiceRow = ({
  invoice,
  id,
  isOriginAmountNotEmpty,
  isNotesNotEmpty,
}) => {
  const { t } = useTranslation();

  const getDate = (date) => {
    return moment(date).format('DD.MM.YYYY');
  };

  if (_.isUndefined(invoice.id)) {
    return null;
  }

  const onRowClick = () => {
    if (!_.isNil(invoice.invoice_file_link)) {
      window.open(invoice.invoice_file_link, '_blank');
    }

    if (!_.isNil(invoice.receipt_file_link)) {
      window.open(invoice.receipt_file_link, '_blank');
    }

    if (!_.isNil(invoice.invoice_receipt_file_link)) {
      window.open(invoice.invoice_receipt_file_link, '_blank');
    }
  };

  const getCurrency = (cur) => {
    switch (cur) {
      case 1: {
        return '$';
      }
      case 2: {
        return '€';
      }
      default:
        return '₪';
    }
  };

  return (
    <tr onClick={onRowClick} key={id}>
      <td className="date">
        <Text text={getDate(invoice.created_on)} />
      </td>
      <td className="number">
        <Text text={invoice.invoice_number} />
      </td>
      {isNotesNotEmpty && (
        <td className="notes">
          {invoice?.message && <Text text={invoice.message} />}
        </td>
      )}
      <td className="type text">
        <Text text={t(invoice.type.toLowerCase())} />
        {(invoice.paid === true || invoice.paid === 1) && (
          <Text
            text={<img src={CheckBox} className="check-box" alt="check box" />}
          />
        )}
      </td>
      <td className="customer">
        <Text text={invoice.client_name} />
      </td>

      {invoice.amount_currency !== 0 && (
        <>
          <td className="origin-amount">
            <Text
              text={`${NumbersService.numberWithCommas(
                invoice.amount_currency
              )}`}
            />
          </td>
          <td className="currency">
            <Text text={getCurrency(invoice.currency)} />
          </td>
          <td className="rate">
            <Text text={invoice.currency_rate.toFixed(2)} />
          </td>
        </>
      )}

      {isOriginAmountNotEmpty && invoice.amount_currency === 0 && (
        <>
          <td className="origin-amount"></td>
          <td className="currency"></td>
          <td className="rate"></td>
        </>
      )}

      <td className="amount">
        <div>
          <Text
            text={`${NumbersService.numberWithCommas(invoice.amount_with_vat)}`}
          />
          <div className="space"></div>
          <Text text={t('nis')} />
        </div>
        {!_.isNil(invoice.invoice_file_link) ||
        !_.isNil(invoice.receipt_file_link) ||
        !_.isNil(invoice.invoice_receipt_file_link) ? (
          <img src={DownloadInvoiceIcon} alt="download invoice" />
        ) : (
          <img src={GreyDownloadInvoiceIcon} alt="download invoice" />
        )}
      </td>
    </tr>
  );
};

export default InvoiceRow;
