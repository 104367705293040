import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getUsers = async (requestConfig = {}) => {
  const options = {
    clients: false,
    suppliers: false,
    ...requestConfig
  }

  return axios({
    method: 'get',
    url: `${endpoints.Users.USER}?clients=${options.clients}&suppliers=${options.suppliers}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getUserData = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Users.USER}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
}

export const checkIsManager = async () => {
  return axios({
    method: 'get',
    url: `${endpoints.Users.IS_MANAGER}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getUserWorkers = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Users.USER_WORKERS}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getUserAccountDocuments = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Users.USER_DOCUMENTS}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const updateUser = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.Users.USER,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const createUser = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Users.USER,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const transformUserToCompany = async (userId) => {
  return axios({
    method: 'post',
    url: `${endpoints.Users.TRANSFORM_USER_TO_COMPANY}/${userId}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

