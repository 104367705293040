import * as _ from "lodash";
import React from "react";

import "./ProgressIndicator.scss";
import { Divider } from "@material-ui/core";

const ProgressIndicator = (props) => {
  const { steps, activeStep, setActiveStep } = props;

  return <div className="progress-indicator-wrapper">
    <Divider className="progress-indicator-divider"/>
    {_.map(steps, (step) => <div
      onClick={() => {
        if (step.value < activeStep.value)
          setActiveStep(step);
      }}
      key={step.value + step.label}
      className={`progress-indicator-item ${step.value < activeStep?.value ? "before" : ( step.value === activeStep?.value ? "active" : "" )}`}>
      <div className="progress-indicator-item-index">{step.value}</div>
      <div className="progress-indicator-item-label">{step.label}</div>
    </div>)}
  </div>
}

export default ProgressIndicator;