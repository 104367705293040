import * as _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

import YeulsTable from "../../../yeuls-table";
import "./OutcomeTable.scss";

const OutcomeTable = (props) => {
  const { viewExpenses, onHeaderCheckboxClick } = props;
  const outcomeTableColumns = "includeInSalaryView.dateView.amountView.numberView.supplierView.notesView.paidByView.statusView";
  const { t } = useTranslation();

  const outcomeTableHeaders = useMemo(() => [
      <div className="table-checkbox-checked-header">
        <Checkbox
          indeterminate={!_.includes([0, viewExpenses.length], _.filter(viewExpenses, (i) => i.include_in_salary).length)}
          checked={_.filter(viewExpenses, (i) => i.include_in_salary).length === viewExpenses.length}
          onChange={(e) => onHeaderCheckboxClick(e.target.checked)}
          classes={{
            checked: 'table-checkbox-checked',
            indeterminate: 'table-checkbox-checked',
            root: 'table-checkbox-root'
          }}/>
      </div>,
      t("date"), t("amount"), t("number"), t("supplier"), t("notes"), t("paid-by"), t("status")],
    // eslint-disable-next-line
    [viewExpenses]);

  return <div className="outcome-table-wrapper">
    <YeulsTable
      data={viewExpenses}
      headers={outcomeTableHeaders}
      columns={outcomeTableColumns}
      pagination={false}/>
  </div>
}

export default OutcomeTable;