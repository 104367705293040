import * as _ from "lodash";
import React, { useMemo, useState } from "react";

import "./UserCompaniesList.scss";
import UserCompanyItem from "./user-company-item";
import AccountsSearchField from "../../../common-components/accounts-search-field";

const UserCompaniesList = (props) => {
  const { companies, userCompanies, setUserCompanies } = props;
  const [filterValue, setFilterValue] = useState("");

  const filteredCompanies = useMemo(() => {
    if(filterValue?.length < 1)
      return [];

    const filterValueToLowerCase = filterValue?.toLowerCase();
    return _.filter(companies, (company) =>
      ( company.company_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( company.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( company.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);
  }, [companies, filterValue]);

  const userCompanyChangeHandler = (companyId, userCompany) => {
    if (userCompany) {
      setUserCompanies([..._.filter(userCompanies, (uc) => uc.company_id !== companyId), userCompany])
    } else {
      setUserCompanies([..._.filter(userCompanies, (uc) => uc.company_id !== companyId)])
    }
  }

  return <div className="user-companies-details-wrapper">
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue}/>
    {_.map(filteredCompanies, (company) =>
      <React.Fragment key={company.id}>
        <UserCompanyItem
          company={company}
          onChange={(userCompany) => userCompanyChangeHandler(company.id, userCompany)}/>
      </React.Fragment>)}
  </div>
}

export default UserCompaniesList;