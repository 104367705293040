import React from "react";
import { useTranslation } from "react-i18next";

import "./MonthlyReportFooter.scss";

const MonthlyReportFooter = (props) => {
  const { t } = useTranslation();
  const { employee } = props;

  return <footer className="monthly-report-footer">
    <div className="monthly-report-footer-item">
      <div>{t("total-cost-with-colon")}</div>
      <div>{employee?.totalCostView}</div>
    </div>
    <div className="monthly-report-footer-item">
      <div>{t("total-fee")}</div>
      <div>{employee?.totalFeeView}</div>
    </div>
    {typeof employee?.days === "number" && <div className="monthly-report-footer-item">
      <div>{t("days")}</div>
      <div>{employee?.days}</div>
    </div>}
    <div className="monthly-report-footer-item">
      <div>{t("total")}</div>
      <div>{employee?.totalView}</div>
    </div>
  </footer>
}

export default MonthlyReportFooter;