import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./GeneralComapnyDetais.scss";
import { Mode } from "../../../../../shared/constants";
import DetailComponent from "../../../common-components/details-component";
import GeneralCompanyForm from "../../../common-components/forms/general-company-form";

const GeneralCompanyDetails = (props) => {
  const { t } = useTranslation();
  const { mode, company, companyFormData, setCompanyFormData } = props;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  return <div className="company-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("company-name")} value={company.company_name}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("business-number")} value={company.business_number}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("email")} value={company.email}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("phone")} value={company.phone}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("withholding-tax")} value={company.withholding_tax}/> </Grid>
      <Grid item xs={6}><DetailComponent
        label={t("withholding-tax-expiration")}
        value={company.withholding_tax_expiration ?
          new Date(company.withholding_tax_expiration).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent
        label={t("general-ledger")}
        value={company.general_ledger ?
          new Date(company.general_ledger).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("country")} value={company.country}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("city")} value={company.city}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("zip")} value={company.zip}/> </Grid>
      <Grid item xs={12}><DetailComponent label={t("address")} value={company.address}/> </Grid>
    </Grid>}
    {mode === Mode.edit &&
    <GeneralCompanyForm generalFormData={companyFormData} setGeneralFormData={setCompanyFormData}/>}
  </div>
}

export default GeneralCompanyDetails;