import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dropzone: {
    borderRadius: '4px',
    width: '100%',
    minHeight: '80px',
    padding: '20px 0',
    backgroundColor: 'rgba(78, 78, 78, 0.1)',
    border: '2px dashed rgba(78, 78, 78, 0.4)',
    flexShrink: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.1s linear',
  },

  onDrag: {
    borderRadius: '4px',
    width: '100%',
    minHeight: '80px',
    padding: '20px 0',
    backgroundColor: 'rgba(78, 78, 78, 0.1)',
    flexShrink: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.1s linear',
    border: '2px dashed #003164',
    boxShadow: '0px 10px 100px #e7f0f7',
  },

  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0',
    marginTop: '5px',
  },

  fileName: {
    display: 'flex',
    alignItems: 'center',
  },

  fileNameRevert: {
    display: 'flex',
    alignItems: 'center',
    transform: 'rotateY(180deg)',
  },

  removeButton: {
    backgroundColor: 'none',
    color: '#003164',
  },
}));
