import React, { useMemo, useState } from "react";

import "./CompanyLinks.scss";
import { useTranslation } from "react-i18next";
import YeulsTabs from "../../../../yeuls-tabs";
import CompanyClientsList from "../company-clients-list";
import CompanyUsersList from "../company-users-list";
import CompanySuppliersList from "../company-suppliers-list";

const tabIds = {
  usersList: 0,
  clientsList: 1,
  suppliersList: 2,
}

const CompanyLinks = (props) => {
  const {
    users, companyUsers, setCompanyUsers,
    clients, companyClients, setCompanyClients,
    suppliers, companySuppliers, setCompanySuppliers
  } = props;
  const { i18n } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.usersList);
  const tabsConfig = useMemo(() => [{
      id: tabIds.usersList,
      label: "employees-list",
      defaultSelectedTab: currentTabId === tabIds.usersList
    }, {
      id: tabIds.clientsList,
      label: "clients-list",
      defaultSelectedTab: currentTabId === tabIds.clientsList
    }, {
      id: tabIds.suppliersList,
      label: "suppliers-list",
      defaultSelectedTab: currentTabId === tabIds.suppliersList
    }],
    // eslint-disable-next-line
    [i18n.language]);

  return <div className="company-links-wrapper">
    <div className="company-links-header-wrapper">
      <YeulsTabs
        config={tabsConfig}
        onChange={setCurrentTabId}/>
    </div>
    {currentTabId === tabIds.usersList &&
    <CompanyUsersList users={users} companyUsers={companyUsers} setCompanyUsers={setCompanyUsers}/>}
    {currentTabId === tabIds.clientsList &&
    <CompanyClientsList clients={clients} companyClients={companyClients} setCompanyClients={setCompanyClients}/>}
    {currentTabId === tabIds.suppliersList &&
    <CompanySuppliersList suppliers={suppliers} companySuppliers={companySuppliers} setCompanySuppliers={setCompanySuppliers}/>}
  </div>
}

export default CompanyLinks;