import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid, MenuItem, Switch } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import "./TermsAndConditionsForm.scss";
import InputComponent from "../../input-component";
import { CalculationType, Currency, ReportType, SalaryDate } from "../../../../../shared/constants";

const TermsAndConditionsForm = (props) => {
  const { t } = useTranslation();
  const {
    termFormData,
    setTermFormData,
    showEndDate = false,
    showDefaultDailyRate = false,
    showRegistrationFee = true
  } = props;

  return <Grid spacing={3} container>
    <Grid item xs={6}>
      <div className="input-component-item">
        <label className="input-component-item-label">{t("start-date")}</label>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.yyyy"
          value={termFormData.start_date.value}
          className="terms-and-conditions-date-picker"
          InputAdornmentProps={{ position: 'end', variant: 'standard' }}
          onChange={(newValue) => {
            setTermFormData({ ...termFormData, start_date: { value: newValue?.toDate(), error: false } })
          }}
        />
      </div>
    </Grid>
    <Grid item xs={6}>
      {showEndDate && <div className="input-component-item">
        <label className="input-component-item-label">{t("end-date")}</label>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.yyyy"
          value={termFormData.end_date.value}
          className="terms-and-conditions-date-picker"
          InputAdornmentProps={{ position: 'end', variant: 'standard' }}
          onChange={(newValue) => {
            setTermFormData({ ...termFormData, end_date: { value: newValue?.toDate(), error: false } })
          }}
        />
      </div>}
    </Grid>
    <Grid item xs={6}>
      <InputComponent type="number" field="yeuls_fee" tLabel="yeuls-fee" formData={termFormData}
                      setFormData={setTermFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent type="number" field="max_yeuls_fee" tLabel="max-yeuls-fee" formData={termFormData}
                      setFormData={setTermFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="salary_date" tLabel="salary-date" formData={termFormData} setFormData={setTermFormData}
                      select>
        <MenuItem key={SalaryDate.first} value={SalaryDate.first}>{t(SalaryDate.first)}</MenuItem>
        <MenuItem key={SalaryDate.ninth} value={SalaryDate.ninth}>{t(SalaryDate.ninth)}</MenuItem>
      </InputComponent>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="calculation_type" tLabel="calculation-type" formData={termFormData}
                      setFormData={setTermFormData} select>
        <MenuItem key={CalculationType.cost} value={CalculationType.cost}>{t(CalculationType.cost)}</MenuItem>
        <MenuItem key={CalculationType.gross} value={CalculationType.gross}>{t(CalculationType.gross)}</MenuItem>
      </InputComponent>
    </Grid>
    <Grid item xs={12}>
      <InputComponent multiline minRows={2} field="notes" tLabel="notes" formData={termFormData}
                      setFormData={setTermFormData}/>
    </Grid>
    <Divider className="terms-and-conditions-divider"/>
    <Grid item xs={6}>
      <InputComponent field="currency" tLabel="currency" formData={termFormData} setFormData={setTermFormData} select>
        <MenuItem key={Currency.nis} value={Currency.nis}>{t(Currency.nis)}</MenuItem>
        <MenuItem key={Currency.usd} value={Currency.usd}>{t(Currency.usd)}</MenuItem>
        <MenuItem key={Currency.eur} value={Currency.eur}>{t(Currency.eur)}</MenuItem>
      </InputComponent>
    </Grid>
    <Grid item xs={6} className="employees-switch-wrapper">
      <div className="employees-form-item">
        <div className="employees-form-item-label">{t("included-vat")}</div>
        <Switch
          classes={{ root: 'switch-root', track: 'switch-track', switchBase: 'switch-base' }}
          checked={termFormData.included_vat.value}
          onChange={(e) => setTermFormData({
            ...termFormData,
            included_vat: { value: e.target.checked }
          })}/>
      </div>
    </Grid>
    {showRegistrationFee && <>
      <Divider className="terms-and-conditions-divider"/>
      <Grid item xs={6}>
        <InputComponent type="number" field="registration_fee" tLabel="registration-fee" formData={termFormData}
                        setFormData={setTermFormData} disabled={termFormData.registration_fee_free.value}/>
      </Grid>
      <Grid item xs={6} className="employees-switch-wrapper">
        <div className="employees-form-item">
          <div className="employees-form-item-label">{t("registration-fee-free")}</div>
          <Switch
            classes={{ root: 'switch-root', track: 'switch-track', switchBase: 'switch-base' }}
            checked={termFormData.registration_fee_free.value}
            onChange={(e) => setTermFormData({
              ...termFormData,
              registration_fee: { value: e.target.checked ? 0 : process.env.REACT_APP_REGISTRATION_FEE },
              registration_fee_free: { value: e.target.checked }
            })}/>
        </div>
      </Grid>
    </>}
    <Divider className="terms-and-conditions-divider"/>
    <Grid item xs={6}>
      <InputComponent
        select
        field="report_type"
        tLabel="report-type"
        formData={termFormData}
        setFormData={setTermFormData}>
        <MenuItem key={ReportType.fixed} value={ReportType.fixed}>{t(ReportType.fixed)}</MenuItem>
        <MenuItem key={ReportType.invoice} value={ReportType.invoice}>{t(ReportType.invoice)}</MenuItem>
        <MenuItem key={ReportType.daily} value={ReportType.daily}>{t(ReportType.daily)}</MenuItem>
        <MenuItem key={ReportType.hourly} value={ReportType.hourly}>{t(ReportType.hourly)}</MenuItem>
      </InputComponent>
    </Grid>
    <Grid item xs={6}>
      <InputComponent type="number" field="report_fee" tLabel="report-fee" formData={termFormData}
                      setFormData={setTermFormData}/>
    </Grid>
    {showDefaultDailyRate && <>
      <Grid item xs={6}>
        <InputComponent type="number" field="default_daily_rate" tLabel="default-daily-rate" formData={termFormData}
                        setFormData={setTermFormData}/>
      </Grid>
      <Grid item xs={6}/>
    </>}


  </Grid>
}

export default TermsAndConditionsForm;