import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getClients = async () => {
  return axios({
    method: 'get',
    url: endpoints.Client.CLIENT,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const createClient = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Client.CLIENT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateClient = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.Client.CLIENT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const createClientAgent = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Client.CLIENT_AGENT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateClientAgent = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.Client.CLIENT_AGENT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const deleteClientAgent = async (id) => {
  return axios({
    method: 'delete',
    url: `${endpoints.Client.CLIENT_AGENT}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getClientAccountDocuments = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Client.CLIENT_DOCUMENTS}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};