import * as _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import './CompanyDetails.scss';
import { useTranslation } from 'react-i18next';
import YeulsTabs from '../../../yeuls-tabs';
import YeulsButton from '../../../yeuls-button';
import { Mode } from '../../../../shared/constants';
import GeneralCompanyDetails from './general-company-details';
import TermsAndConditionsCompanyDetails from './terms-and-conditions-company-detais';
import CompanyUsersItemDetails from './company-users-list-details';
import CompanyClientsListDetails from './company-clients-list-details';
import CompanySuppliersListDetails from './company-suppliers-list-details';
import AgentDocuments from '../../common-components/agent-documents';
import { getCompanyAccountDocuments } from '../../../../api/requests/companies';
import { CompanyAccountDocuments } from './company-account-documents';
import { jsonToFormData } from '../../common-components/utils';
import { CompanyDocumentType } from '../../../../api/constants';

const tabIds = {
  general: 0,
  termsAndConditions: 1,
  users: 2,
  clients: 3,
  suppliers: 4,
  documents: 5,
  accountDocuments: 6,
};

const CompanyDetails = (props) => {
  const { company, onUpdateCompany, users, clients, suppliers } = props;
  const { t, i18n } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.general);
  const [mode, setMode] = useState(Mode.edit);
  const [companyFormData, setCompanyFormData] = useState(
    getCompanyFormObject(company)
  );
  const [companyUsersChanges, setCompanyUsersChanges] = useState({});
  const [companyClientsChanges, setCompanyClientsChanges] = useState({});
  const [companySuppliersChanges, setCompanySuppliersChanges] = useState({});
  const [companyInvoiceChanges, setCompanyInvoiceChanges] = useState({});
  const [companyExpenseChanges, setCompanyExpenseChanges] = useState({});
  const [companyDocuments, setCompanyDocuments] = useState([]);

  const text = `${t('missing-documents')}: ${t('services-agreement')}`;

  const tabsConfig = useMemo(
    () => [
      { id: tabIds.general, label: t('general'), defaultSelectedTab: true },
      { id: tabIds.termsAndConditions, label: t('terms-and-conditions') },
      {
        id: tabIds.users,
        label: t('employees'),
        itemsCount: company.users?.length,
      },
      {
        id: tabIds.clients,
        label: t('clients'),
        itemsCount: company.clients?.length,
      },
      {
        id: tabIds.suppliers,
        label: t('suppliers'),
        itemsCount: company.suppliers?.length,
      },
      { id: tabIds.documents, label: t('documents') },
      {
        id: tabIds.accountDocuments,
        label: t('account-documents'),
        itemsCount: `${
          companyDocuments?.length > 0
            ? companyDocuments.filter(
                (c) => c.fileType === CompanyDocumentType.SERVICE_AGREEMENT
              )?.length
            : 0
        }/1`,
      },
    ],
    // eslint-disable-next-line
    [i18n.language, company, companyDocuments]
  );

  useEffect(() => {
    if (company && company.id) {
      getCompanyAccountDocuments(company.id).then((res) => {
        setCompanyDocuments(res);
      });
    }
  }, [company]);

  useEffect(() => {
    if (mode === Mode.edit) setCompanyFormData(getCompanyFormObject(company));
    // eslint-disable-next-line
  }, [mode]);

  const onSaveClick = () => {
    const companyUpdate = {
      ...company,
      company_name: companyFormData.company_name.value,
      business_number: companyFormData.business_number.value,
      default_daily_rate: companyFormData.default_daily_rate.value,
      email: companyFormData.email.value,
      phone: companyFormData.phone.value,
      country: companyFormData.country.value,
      city: companyFormData.city.value,
      zip: companyFormData.zip.value,
      address: companyFormData.address.value,
      withholding_tax: +companyFormData.withholding_tax.value,
      withholding_tax_expiration:
        companyFormData.withholding_tax_expiration.value,
      general_ledger: companyFormData.general_ledger.value,
      companyTerms: {
        ...company.companyTerms,
        yeuls_fee: companyFormData.yeuls_fee.value,
        salary_date: companyFormData.salary_date.value,
        calculation_type: companyFormData.calculation_type.value,
        max_yeuls_fee: +companyFormData.max_yeuls_fee.value,
        included_vat: companyFormData.included_vat.value,
        report_type: companyFormData.report_type.value,
        report_fee: +companyFormData.report_fee.value,
        currency: companyFormData.currency.value,
        start_date: companyFormData.start_date.value,
        end_date: companyFormData.end_date.value,
        notes: companyFormData.notes.value,
      },
      removedDocuments: companyFormData.removedDocuments.value,
    };

    const formData = {
      serviceAgreement: companyFormData.serviceAgreement.value,
      otherDocs: companyFormData.otherDocs.value,
      formData: JSON.stringify(companyUpdate),
    };

    onUpdateCompany(
      companyUpdate,
      companyUsersChanges,
      companyClientsChanges,
      companySuppliersChanges,
      companyInvoiceChanges,
      companyExpenseChanges,
      jsonToFormData(formData),
      true
    )
      .then(() => {
        setCompanyUsersChanges({});
      })
      // .then(() => {
      //   setMode(Mode.view);
      // })
      .catch((e) => {});
  };

  const companyUserChangeHandler = (value, userId) => {
    setCompanyUsersChanges({
      ...companyUsersChanges,
      [userId]: value
        ? _.find(company.companyUsers, { user_id: userId }) || {
            user_id: userId,
          }
        : null,
    });
  };

  const companyClientChangeHandler = (value, clientId) => {
    setCompanyClientsChanges({
      ...companyClientsChanges,
      [clientId]: value
        ? _.find(company.companyClients, { client_id: clientId }) || {
            client_id: clientId,
          }
        : null,
    });
  };

  const companySupplierChangeHandler = (value, supplierId) => {
    setCompanySuppliersChanges({
      ...companySuppliersChanges,
      [supplierId]: value
        ? _.find(company.companySuppliers, { supplier_id: supplierId }) || {
            supplier_id: supplierId,
          }
        : null,
    });
  };

  const onCancelClick = () => {
    // setMode(Mode.view);
    setCompanyClientsChanges({});
    setCompanySuppliersChanges({});
    setCompanyFormData(getCompanyFormObject(company));
  };

  return (
    <div className="company-details-wrapper">
      <div className="company-details-tabs-wrapper">
        <YeulsTabs config={tabsConfig} onChange={setCurrentTabId} />
        {/*{mode === Mode.view && <YeulsButton onClick={() => setMode(Mode.edit)}>{t("edit")}</YeulsButton>}*/}
        {mode === Mode.edit && (
          <div className="company-details-button-container">
            <YeulsButton variant="negative" onClick={() => onCancelClick()}>
              {t('reset')}
            </YeulsButton>
            <YeulsButton onClick={onSaveClick}>{t('save')}</YeulsButton>
          </div>
        )}
      </div>
      <div style={{ marginBottom: '10px' }}>
        {companyDocuments.filter(
          (c) => c.fileType === CompanyDocumentType.SERVICE_AGREEMENT
        )?.length === 0 && (
          <span className={i18n.language !== 'heb' ? 'red' : 'red-reverse'}>
            {text}
          </span>
        )}
      </div>
      <div className="company-details-content">
        {currentTabId === tabIds.general && (
          <GeneralCompanyDetails
            mode={mode}
            company={company}
            companyFormData={companyFormData}
            setCompanyFormData={setCompanyFormData}
          />
        )}
        {currentTabId === tabIds.termsAndConditions && (
          <TermsAndConditionsCompanyDetails
            mode={mode}
            company={company}
            companyFormData={companyFormData}
            setCompanyFormData={setCompanyFormData}
          />
        )}
        {currentTabId === tabIds.users && (
          <CompanyUsersItemDetails
            mode={mode}
            company={company}
            users={users}
            onCompanyUserChange={companyUserChangeHandler}
          />
        )}
        {currentTabId === tabIds.clients && (
          <CompanyClientsListDetails
            mode={mode}
            company={company}
            clients={clients}
            onCompanyClientChange={companyClientChangeHandler}
            companyClientsChanges={companyClientsChanges}
          />
        )}
        {currentTabId === tabIds.suppliers && (
          <CompanySuppliersListDetails
            mode={mode}
            company={company}
            suppliers={suppliers}
            onCompanySupplierChange={companySupplierChangeHandler}
            companySuppliersChanges={companySuppliersChanges}
          />
        )}
        {currentTabId === tabIds.documents && (
          <AgentDocuments
            invoices={company.invoices}
            expenses={company.expenses}
            salaries={company.salaries}
            receipts={company.receipts}
            clients={clients}
            suppliers={suppliers}
            invoiceChanges={companyInvoiceChanges}
            setInvoiceChanges={setCompanyInvoiceChanges}
            expenseChanges={companyExpenseChanges}
            setExpenseChanges={setCompanyExpenseChanges}
          />
        )}
        {currentTabId === tabIds.accountDocuments && (
          <CompanyAccountDocuments
            companyFormData={companyFormData}
            setCompanyFormData={setCompanyFormData}
            companyDocuments={companyDocuments}
          />
        )}
      </div>
    </div>
  );
};

function getCompanyFormObject(company) {
  return {
    company_name: { value: company.company_name, error: false },
    business_number: { value: company.business_number, error: false },
    email: { value: company.email, error: false },
    phone: { value: company.phone, error: false },
    country: { value: company.country, error: false },
    city: { value: company.city, error: false },
    zip: { value: company.zip, error: false },
    address: { value: company.address, error: false },
    withholding_tax: { value: company.withholding_tax, error: false },
    withholding_tax_expiration: {
      value: company.withholding_tax_expiration,
      error: false,
    },
    general_ledger: { value: company.general_ledger, error: false },

    yeuls_fee: { value: company.companyTerms?.yeuls_fee, error: false },
    salary_date: { value: company.companyTerms?.salary_date, error: false },
    calculation_type: {
      value: company.companyTerms?.calculation_type,
      error: false,
    },
    max_yeuls_fee: { value: company.companyTerms?.max_yeuls_fee, error: false },
    registration_fee: {
      value: company.companyTerms?.registration_fee,
      error: false,
    },
    registration_fee_free: {
      value: !company.companyTerms?.registration_fee,
      error: false,
    },
    included_vat: { value: company.companyTerms?.included_vat, error: false },
    report_type: { value: company.companyTerms?.report_type, error: false },
    report_fee: { value: company.companyTerms?.report_fee, error: false },
    default_daily_rate: {
      value: company.companyTerms?.default_daily_rate,
      error: false,
    },
    currency: { value: company.companyTerms?.currency, error: false },
    start_date: { value: company.companyTerms?.start_date, error: false },
    end_date: { value: company.companyTerms?.end_date, error: false },
    notes: { value: company.companyTerms?.notes, error: false },
    serviceAgreement: { value: null, error: false },
    otherDocs: { value: null, error: false },
    removedDocuments: { value: null, error: false },
  };
}

export default CompanyDetails;
