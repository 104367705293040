import React from "react";

import "./Loader.scss";
import Spinner from "../spinner";

const Loader = () => {
  return <div className="loader-wrapper">
    <Spinner/>
  </div>

}

export default Loader;