import { Button, Grid } from '@material-ui/core';
import React from 'react';
import './ClientAccountDocument.scss';
import { FileComponent } from '../../../common-components/file-component/input';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete, AiOutlineEye, AiOutlinePlus } from 'react-icons/ai';
import Text from '../../../../../containers/Text';
import { useDialog } from '../../../../../hooks/useDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { OtherDocsDialog } from '../../../companies-list/new-company/company-account-documents';

export const ClientAccountDocuments = ({
  clientFormData,
  setClientFormData,
}) => {
  const { t } = useTranslation();
  const { dialogOpen, handleDialogOpen, handleDialogClose } = useDialog();

  return (
    <div className="user-account-documents-wrapper">
      <Grid container spacing={3}>
        <FileComponent
          label={t('services-agreement')}
          fileType="application/pdf"
          size={6}
          multiple={true}
          field="serviceAgreement"
          format={t('pdf')}
          formData={clientFormData}
          setFormData={setClientFormData}
          removedFile={(id) => {}}
        />
        <Grid item xs={12} className="file-wrapper">
          <label className="input-component-item-label">
            {t('add-other-docs')}
          </label>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              handleDialogOpen();
            }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              color: '#003164',
            }}
            startIcon={<AiOutlinePlus />}
          >
            <Text text={t('add')} />
          </Button>
        </Grid>
        {clientFormData?.otherDocs?.value &&
          clientFormData?.otherDocs?.value?.length > 0 && (
            <>
              <Grid item xs={12} className="file-wrapper">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {t('document-name')}
                        </TableCell>
                        <TableCell align="center">{t('file')}</TableCell>
                        <TableCell align="center">{t('action')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clientFormData?.otherDocs?.value?.map((row, index) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.file.name}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  URL.createObjectURL(row.file),
                                  '_blank',
                                  'noreferrer'
                                );
                              }}
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                color: '#003164',
                              }}
                              startIcon={<AiOutlineEye />}
                            ></Button>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                setClientFormData({
                                  ...clientFormData,
                                  otherDocs: {
                                    value: clientFormData?.otherDocs?.value.filter(
                                      (item, i) => i !== index
                                    ),
                                    error: false,
                                  },
                                });
                              }}
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                color: '#003164',
                              }}
                              startIcon={<AiOutlineDelete />}
                            ></Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
      </Grid>
      {dialogOpen && (
        <OtherDocsDialog
          open={dialogOpen}
          onClose={() => handleDialogClose()}
          submitForm={(data) => {
            setClientFormData({
              ...clientFormData,
              otherDocs: {
                value: clientFormData?.otherDocs?.value
                  ? clientFormData?.otherDocs?.value.concat([data])
                  : [data],
                error: false,
              },
            });
            handleDialogClose();
          }}
        />
      )}
    </div>
  );
};
