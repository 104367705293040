import * as _ from "lodash";
import React, { useMemo, useState } from "react";
import { Checkbox } from "@material-ui/core";

import "./ClientAgentsDetails.scss";
import { Mode } from "../../../../../shared/constants";
import { useTranslation } from "react-i18next";
import YeulsTabs from "../../../../yeuls-tabs";
import AccountsSearchField from "../../../common-components/accounts-search-field";
import YeulsTable from "../../../../yeuls-table";

const tabIds = {
  agentsList: 0,
  companiesList: 1
}
const ClientAgentsDetails = (props) => {
  const {
    mode,
    users,
    companies,
    client,
    onClientAgentChange,
    clientUserAgentsChanges,
    clientCompanyAgentsChanges
  } = props;
  const { i18n, t } = useTranslation();
  const [filterValue, setFilterValue] = useState("");
  const [currentTabId, setCurrentTabId] = useState(tabIds.agentsList);
  const [updateTable, setUpdateTable] = useState(true);
  const userHeaders = useMemo(() => mode === Mode.edit ?
    [".", t("name"), t("client-number"), t("business-number")] :
    [t("name"), t("client-number"), t("business-number")], [i18n.language, mode]);
  const userColumns = useMemo(() =>
      mode === Mode.edit ?
        "selectedView.nameView.santec_account_id.business_number" :
        "nameView.santec_account_id.business_number"
    , [mode]);
  const companyHeaders = useMemo(() => mode === Mode.edit ?
    [".", t("company-name"), t("client-number"), t("business-number")] :
    [t("company-name"), t("client-number"), t("business-number")], [i18n.language, mode]);
  const companyColumns = useMemo(() =>
      mode === Mode.edit ?
        "selectedView.company_name.santec_account_id.business_number" :
        "company_name.santec_account_id.business_number"
    , [mode]);
  const tabsConfig = useMemo(() => [
    {
      id: tabIds.agentsList,
      label: "agents-list",
      defaultSelectedTab: currentTabId === tabIds.agentsList,
      itemsCount: _.filter(client.clientAgents, (sa) => !!sa.user_id)?.length
    }, {
      id: tabIds.companiesList,
      label: "companies-list",
      itemsCount: _.filter(client.clientAgents, (sa) => !!sa.company_id)?.length
      // eslint-disable-next-line
    }], [i18n.language, client]);

  const filteredUsers = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    const clientUserIds = _.map(client.clientAgents, "user_id").filter(Boolean);
    const filteredValues = _.filter(users, (user) =>
      ( user.firstname?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.email?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);

    return [
      ..._.filter(filteredValues, (value) => _.includes(clientUserIds, value.id)),
      ..._.filter(filteredValues, (value) => !_.includes(clientUserIds, value.id)),
    ]
    // eslint-disable-next-line
  }, [filterValue, mode, users]);

  const mappedUsers = useMemo(() => {
    const clientUserIds = _.map(client.clientAgents, "user_id").filter(Boolean);

    if (!_.values(clientUserAgentsChanges)?.length) {
      setUpdateTable(false);
      setTimeout(() => setUpdateTable(true));
    }

    return _(filteredUsers)
      .filter((user) => mode === Mode.edit || _.includes(clientUserIds, user.id))
      .map((user) => {
        let nameView = user.firstname;
        if (user.lastname)
          nameView += ` ${user.lastname}`

        return {
          ...user,
          nameView,
          selectedView: <Checkbox checked={( !!_.find(client.clientAgents, { user_id: user.id }) ||
            !!clientUserAgentsChanges[user.id] ) && !clientUserAgentsChanges[user.id]?.delete}
                                  onChange={(e) => onAgentChange(e, { user })}/>,
        }
      })
      .value();
  }, [filteredUsers, mode, clientUserAgentsChanges]);

  const filteredCompanies = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    const clientCompanyIds = _.map(client.clientAgents, "company_id").filter(Boolean);
    const filteredValues = _.filter(companies, (company) =>
      ( company.company_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( company.email?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);

    return [
      ..._.filter(filteredValues, (value) => _.includes(clientCompanyIds, value.id)),
      ..._.filter(filteredValues, (value) => !_.includes(clientCompanyIds, value.id)),
    ]
    // eslint-disable-next-line
  }, [filterValue, mode, companies]);

  const mappedCompanies = useMemo(() => {
    const clientCompanyIds = _.map(client.clientAgents, "company_id").filter(Boolean);

    if (!_.values(clientCompanyAgentsChanges)?.length) {
      setUpdateTable(false);
      setTimeout(() => setUpdateTable(true));
    }

    return _(filteredCompanies)
      .filter((company) => mode === Mode.edit || _.includes(clientCompanyIds, company.id))
      .map((company) => {
        return {
          ...company,
          selectedView: <Checkbox checked={( !!_.find(client.clientAgents, { company_id: company.id }) ||
            !!clientCompanyAgentsChanges[company.id] ) && !clientCompanyAgentsChanges[company.id]?.delete}
                                  onChange={(e) => onAgentChange(e, { company })}/>,
        }
      })
      .value();
  }, [filteredCompanies, mode, clientCompanyAgentsChanges]);

  const onAgentChange = (e, { user, company }) => {
    if (!e.target.checked && user)
      onClientAgentChange({ userId: user.id }, {
        ..._.find(client.clientAgents, { user_id: user.id }),
        delete: true
      } || null);

    if (e.target.checked && user)
      onClientAgentChange({ userId: user.id }, _.find(client.clientAgents, { user_id: user.id }) || {
        user_id: user.id,
        client_id: client.id
      });

    if (!e.target.checked && company)
      onClientAgentChange({ companyId: company.id }, {
        ..._.find(client.clientAgents, { company_id: company.id }),
        delete: true
      } || null);

    if (e.target.checked && company)
      onClientAgentChange({ companyId: company.id }, _.find(client.clientAgents, { company_id: company.id }) || {
        company_id: company.id,
        client_id: client.id
      });
  }

  return <div className="client-agents-details-wrapper">
    <YeulsTabs
      config={tabsConfig}
      onChange={(value) => {
        setFilterValue("");
        setCurrentTabId(value)
      }}/>
    {currentTabId === tabIds.agentsList && <>
      {mode === Mode.edit &&
      <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>}
      {updateTable && <YeulsTable
        data={mappedUsers}
        headers={userHeaders}
        columns={userColumns}
        totalCount={mappedUsers.length}
        perPageItemCount={12}
        partialPageCount={3}
        nextPageText=">"
        prePageText="<"
        className={`agents-table-container ${mode === Mode.edit ? "edit" : "view"}`}
      />}

      {mode === Mode.view && !_.find(client.clientAgents, (agent) => agent.user_id) &&
      <div className="empty-list">{t("client-users-empty-list")}</div>}
    </>}
    {currentTabId === tabIds.companiesList && <>
      {mode === Mode.edit &&
      <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>}
      {updateTable && <YeulsTable
        data={mappedCompanies}
        headers={companyHeaders}
        columns={companyColumns}
        totalCount={mappedCompanies.length}
        perPageItemCount={12}
        partialPageCount={3}
        nextPageText=">"
        prePageText="<"
        className={`agents-table-container ${mode === Mode.edit ? "edit" : "view"}`}
      />}
      {mode === Mode.view && !_.find(client.clientAgents, (agent) => agent.company_id) &&
      <div className="empty-list">{t("client-companies-empty-list")}</div>}
    </>}
  </div>
}

export default ClientAgentsDetails;
