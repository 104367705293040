import axios from "axios";

import * as endpoints from "../endpoints";
import { constants } from "../constants";

const baseUrl = constants.baseURL;

export const addWorkerReport = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.WorkerReport.REPORT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const addWorkerReportEmployee = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.WorkerReport.REPORT_EMPLOYEE,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

// export const addWorkerReportItem = async (data) => {
//   return axios({
//     method: 'post',
//     url: endpoints.WorkerReport.REPORT_ITEM,
//     baseURL: baseUrl,
//     data: data,
//   }).then((response) => response.data.result);
// };

export const updateWorkerReport = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.WorkerReport.REPORT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateWorkerReportEmployee = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.WorkerReport.REPORT_EMPLOYEE,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateWorkerReportEmployeeItem = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.WorkerReport.REPORT_EMPLOYEE_ITEM,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const submitWorkerReport = async (workerReportId, userCompanyId, exchangeRate)=>{
  return axios({
    method: 'put',
    url: endpoints.WorkerReport.SUBMIT_BY_MANAGER,
    baseURL: baseUrl,
    data: {workerReportId, userCompanyId, exchangeRate},
  }).then((response) => response.data.result);
}

export const submitWorkerReportEmployee = async (workerReportId)=>{
  return axios({
    method: 'put',
    url: `${endpoints.WorkerReport.SUBMIT_BY_EMPLOYEE}/${workerReportId}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
}