export const RateType = {
  halfDay: 50,
  transit: 60,
  day: 100,
  dayOff: 150
}

export const Role = {
  admin: 'admin',
  user: 'user',
};

export const CompanyRole = {
  manager: "manager",
  employee: "employee"
}

export const SalaryDate = {
  first: "first",
  ninth: "ninth"
}

export const CalculationType = {
  cost: "cost",
  gross: "gross"
}

export const SalaryStatus = {
  open: "open",
  done: "done"
}

export const Mode = {
  view: "view",
  edit: "edit",
}

export const ReportType = {
  hourly: "hourly",
  daily: "daily",
  fixed: "fixed",
  invoice: "invoice"
}

export const Currency = {
  usd: "USD",
  nis: "NIS",
  eur: "EUR"
}

export const Status = {
  active: "active",
  dismissed: "dismissed",
}