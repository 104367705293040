import React from "react";

import "./GeneralSupplierDetails.scss";
import { useTranslation } from "react-i18next";
import { Mode } from "../../../../../shared/constants";
import { Grid } from "@material-ui/core";
import DetailComponent from "../../../common-components/details-component";
import GeneralSupplierForm from "../../../common-components/forms/general-supplier-form";

const GeneralSupplierDetails = (props) => {
  const { t } = useTranslation();
  const { mode, supplier, supplierFormData, setSupplierFormData } = props;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  return <div className="supplier-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("supplier-name")} value={supplier.supplier_name}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("business-number")} value={supplier.business_number}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("email")} value={supplier.email}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("phone")} value={supplier.phone}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("withholding-tax")} value={supplier.withholding_tax}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("withholding-tax-expiration")}
                                         value={supplier.withholding_tax_expiration ?
                                           new Date(supplier.withholding_tax_expiration).toLocaleDateString("en-GB", dateOptions) : ""}/>
      </Grid>
      <Grid item xs={6}><DetailComponent label={t("general-ledger")} value={supplier.general_ledger ?
        new Date(supplier.withholding_tax_expiration).toLocaleDateString("en-GB", dateOptions) : ""}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("created-by")} value={supplier.created_by}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("country")} value={supplier.country}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("city")} value={supplier.city}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("zip")} value={supplier.zip}/> </Grid>
      <Grid item xs={12}><DetailComponent label={t("address")} value={supplier.address}/> </Grid>
    </Grid>}
    {mode === Mode.edit &&
    <GeneralSupplierForm generalFormData={supplierFormData} setGeneralFormData={setSupplierFormData}/>}
  </div>
}

export default GeneralSupplierDetails;
