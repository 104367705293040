import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

import "./AccountsSearchFields.scss";
import SearchIcon from "../../../../shared/icons/search-black-icon.svg";
import CloseIcon from "../../../../shared/icons/close-black-icon.svg";

const AccountsSearchField = (props) => {
  const { t } = useTranslation();
  const {
    filterValue,
    setFilterValue,
    showSearchMessage = true,
    placeholder = t("search-by-name-account-business-number")
  } = props;

  return <div className="accounts-search-field-wrapper">
    {showSearchMessage && <div className="search-message">{t("to-search-message")}</div>}
    <TextField
      variant="outlined"
      className="accounts-search-field-input"
      value={filterValue}
      placeholder={placeholder}
      onChange={(event) => setFilterValue(event.target.value)}
      InputProps={{
        startAdornment: <img
          src={SearchIcon}
          alt="Search-icon"
        />,
        endAdornment: !!filterValue && <img
          src={CloseIcon}
          alt="Clear filter icon"
          onClick={() => setFilterValue("")}
        />
      }}/>
  </div>
}

export default AccountsSearchField;