import * as _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

import "./UserSuppliersList.scss";
import AccountsSearchField from "../../../common-components/accounts-search-field";
import YeulsTable from "../../../../yeuls-table";

const UserSuppliersList = (props) => {
  const { suppliers, userSuppliers, setUserSuppliers } = props;
  const [filterValue, setFilterValue] = useState("");
  const { t, i18n } = useTranslation();
  const suppliersHeaders = useMemo(() => [".", t("name"), t("supplier-number"), t("business-number")], [i18n.language]);
  const suppliersColumns = "selectedView.supplier_name.santec_account_id.business_number";

  const filteredSuppliers = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    return _.filter(suppliers, (supplier) =>
      ( supplier.supplier_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( supplier.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( supplier.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);
  }, [suppliers, filterValue]);

  const userSupplierChangeHandler = (supplierId, userSupplier) => {
    if (userSupplier) {
      setUserSuppliers([..._.filter(userSuppliers, (uc) => uc.supplier_id !== supplierId), userSupplier])
    } else {
      setUserSuppliers([..._.filter(userSuppliers, (uc) => uc.supplier_id !== supplierId)])
    }
  }

  const mappedSuppliers = useMemo(() => {
    return _.map(filteredSuppliers, (supplier) => {
      return {
        ...supplier,
        selectedView: <Checkbox checked={!!!!_.find(userSuppliers, { supplier_id: supplier.id })}
                                onChange={(e) => userSupplierChangeHandler(supplier.id, e.target.checked ? { supplier_id: supplier.id } : null)}/>,
      }
    })
  }, [filteredSuppliers, userSuppliers]);

  return <div className="user-suppliers-details-wrapper">
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>
    <YeulsTable
      data={mappedSuppliers}
      headers={suppliersHeaders}
      columns={suppliersColumns}
      totalCount={mappedSuppliers.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className="agents-table-container"
    />
  </div>

}

export default UserSuppliersList;