import React, { useMemo, useState } from "react";

import "./NewSupplier.scss";
import { useTranslation } from "react-i18next";
import { validateGeneralSupplierForm } from "../../common-components/validators";
import ProgressIndicator from "../../common-components/progress-indicator";
import YeulsButton from "../../../yeuls-button";
import GeneralSupplierForm from "../../common-components/forms/general-supplier-form";
import BankForm from "../../common-components/forms/bank-form";
import SupplierAgentsList from "./supplier-agents-list";

const defaultSupplierFormData = {
  supplier_name: { value: null, error: false },
  country: { value: null, error: false },
  city: { value: null, error: false },
  address: { value: null, error: false },
  zip: { value: null, error: false },
  phone: { value: null, error: false },
  email: { value: null, error: false },
  business_number: { value: null, error: false },
  withholding_tax: { value: null, error: false },
  withholding_tax_expiration: { value: null, error: false },
  general_ledger: { value: null, error: false },
  created_by: { value: null, error: false },
  included_vat: { included_vat: true, error: false },
  currency: { value: null, error: false },

  bank_name: { value: null, error: false },
  bank_number: { value: null, error: false },
  bank_branch_number: { value: null, error: false },
  bank_account_number: { value: null, error: false },
}
const steps = {
  general: 1,
  bank: 2,
  agents: 3
}

const NewSupplier = (props) => {
  const { onCreateSupplier, onCancel, users, companies } = props;
  const { t, i18n } = useTranslation();
  const stepsConfig = useMemo(() => [
      { value: steps.general, label: t("general"), validate: validateGeneralSupplierForm },
      { value: steps.bank, label: t("bank") },
      { value: steps.agents, label: t("agents") },
    ],
    // eslint-disable-next-line
    [i18n.language]);
  const [activeStep, setActiveStep] = useState(stepsConfig[0]);
  const [supplierFormData, setSupplierFormData] = useState(defaultSupplierFormData);
  const [supplierAgents, setSupplierAgents] = useState([]);

  const nextStep = () => {
    if (activeStep.value !== steps.agents) {
      if (!activeStep.validate || ( activeStep.validate, activeStep.validate(supplierFormData, setSupplierFormData) ))
        setActiveStep(stepsConfig[activeStep.value]);
    } else {
      saveSupplier();
    }
  }

  const saveSupplier = () => {
    const supplier = {
      supplier_name: supplierFormData.supplier_name.value,
      country: supplierFormData.country.value,
      city: supplierFormData.city.value,
      address: supplierFormData.address.value,
      zip: supplierFormData.zip.value,
      phone: supplierFormData.phone.value,
      email: supplierFormData.email.value,
      business_number: supplierFormData.business_number.value,
      withholding_tax: +supplierFormData.withholding_tax.value,
      withholding_tax_expiration: supplierFormData.withholding_tax_expiration.value,
      general_ledger: supplierFormData.general_ledger.value,
      created_by: supplierFormData.created_by.value,
      included_vat: supplierFormData.included_vat.value,
      currency: supplierFormData.currency.value,

      bank_name: supplierFormData.bank_name.value,
      bank_number: supplierFormData.bank_number.value,
      bank_branch_number: supplierFormData.bank_branch_number.value,
      bank_account_number: supplierFormData.bank_account_number.value,
      supplierAgents
    };

    onCreateSupplier(supplier);
  }

  return <div className="new-supplier-wrapper">
    <div className="new-supplier-indicator-wrapper">
      <ProgressIndicator steps={stepsConfig} activeStep={activeStep} setActiveStep={setActiveStep}/>
    </div>
    <div className="new-supplier-form-container">
      {activeStep?.value === steps.general &&
      <GeneralSupplierForm generalFormData={supplierFormData} setGeneralFormData={setSupplierFormData}/>}
      {activeStep?.value === steps.bank &&
      <BankForm bankFormData={supplierFormData} setBankFormData={setSupplierFormData}/>}
      {activeStep?.value === steps.agents &&
      <SupplierAgentsList users={users} companies={companies} supplierAgents={supplierAgents} setSupplierAgents={setSupplierAgents}/>}
    </div>
    <div className="new-supplier-buttons-container">
      <YeulsButton variant="negative" onClick={onCancel}>{t("cancel")}</YeulsButton>
      <YeulsButton onClick={nextStep}>{t("next")}</YeulsButton>
    </div>
  </div>
}

export default NewSupplier