import React, { useMemo, useState } from "react";

import "./UserClientsListDetails.scss";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { Mode } from "../../../../../shared/constants";
import { Checkbox, Grid } from "@material-ui/core";
import AccountsSearchField from "../../../common-components/accounts-search-field";
import YeulsTable from "../../../../yeuls-table";

const UserClientsListDetails = (props) => {
  const { t, i18n } = useTranslation();
  const { mode, user, clients, onUserClientChange, userClientsChanges } = props;
  const [filterValue, setFilterValue] = useState("");
  const [updateTable, setUpdateTable] = useState(true);
  const clientsHeaders = useMemo(() => mode === Mode.edit ?
    [".", t("name"), t("client-number"), t("business-number")] :
    [t("name"), t("client-number"), t("business-number")], [i18n.language, mode]);
  const clientsColumns = useMemo(() =>
      mode === Mode.edit ?
        "selectedView.client_name.santec_account_id.business_number" :
        "client_name.santec_account_id.business_number"
    , [mode]);

  const filteredClients = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    const userClientIds = _.map(user.clients, "id");
    const filteredValues = _.filter(clients, (client) =>
      ( client.client_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( client.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( client.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);

    return [
      ..._.filter(filteredValues, (value) => _.includes(userClientIds, value.id)),
      ..._.filter(filteredValues, (value) => !_.includes(userClientIds, value.id)),
    ]
    // eslint-disable-next-line
  }, [clients, filterValue, mode]);

  const mappedClients = useMemo(() => {
    const userClientIds = _.map(user.clients, "id");

    if (!_.values(userClientsChanges)?.length) {
      setUpdateTable(false);
      setTimeout(() => setUpdateTable(true));
    }

    return _(filteredClients)
      .filter((client) => mode === Mode.edit || _.includes(userClientIds, client.id))
      .map((client) => {
        return {
          ...client,
          selectedView: <Checkbox checked={( !!_.find(user.userClients, { client_id: client.id }) ||
            !!userClientsChanges[client.id] ) && userClientsChanges[client.id] !== null}
                                  onChange={(e) => onUserClientChange(e.target.checked, client.id)}/>,
        }
      })
      .value();
  }, [filteredClients, mode, userClientsChanges]);

  return <div className="user-clients-details-wrapper">
    {mode === Mode.edit &&
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>}
    {updateTable && <YeulsTable
      data={mappedClients}
      headers={clientsHeaders}
      columns={clientsColumns}
      totalCount={mappedClients.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className={`agents-table-container ${mode === Mode.edit ? "edit" : "view"}`}
    />}
    {mode === Mode.view && !user.clients?.length &&
    <div className="empty-list">{t("user-clients-empty-list")}</div>}
  </div>
}

export default UserClientsListDetails;