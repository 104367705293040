import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./TermsAndConditionsUserDetais.scss";
import { Mode } from "../../../../../shared/constants";
import DetailComponent from "../../../common-components/details-component";
import TermsAndConditionsForm from "../../../common-components/forms/terms-and-conditions-form";

const TermsAndConditionsUserDetails = (props) => {
  const { t } = useTranslation();
  const { mode, user, companyFormData, setCompanyFormData } = props;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  return <div className="user-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("yeuls-fee")} value={user.userTerms?.yeuls_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("max-yeuls-fee")} value={user.userTerms?.max_yeuls_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("salary-date")} value={user.userTerms?.salary_date}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("calculation-type")} value={user.userTerms?.calculation_type}/></Grid>
      <Grid item xs={6}><DetailComponent
        label={t("start-date")}
        value={user.userTerms?.start_date ?
          new Date(user.userTerms?.start_date).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent
        label={t("end-date")}
        value={user.userTerms?.end_date ?
          new Date(user.userTerms?.end_date).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("included-vat")} value={user.userTerms?.included_vat ? t("yes") : t("no")}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("report-type")} value={user.userTerms?.report_type}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("report-fee")} value={user.userTerms?.report_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("currency")} value={user.userTerms?.currency}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("registration-fee")} value={user.userTerms?.registration_fee}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("registration-fee-paid")} value={user.userTerms?.registration_fee_paid ? t("yes") : t("no")}/></Grid>
      <Grid item xs={12}><DetailComponent label={t("notes")} value={user.userTerms?.notes}/></Grid>
    </Grid>}
    {mode === Mode.edit &&
    <TermsAndConditionsForm termFormData={companyFormData} setTermFormData={setCompanyFormData} showEndDate={true}/>}
  </div>
}

export default TermsAndConditionsUserDetails;