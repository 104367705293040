import React, { useMemo, useState } from "react";

import "./UserLinks.scss";
import { useTranslation } from "react-i18next";
import YeulsTabs from "../../../../yeuls-tabs";
import UserCompaniesList from "../user-companies-list";
import UserClientsList from "../user-clients-list";
import UserSuppliersList from "../user-suppliers-list";

const tabIds = {
  companiesList: 0,
  clientsList: 1,
  suppliersList: 2,
}

const UserLinks = (props) => {
  const {
    companies, userCompanies, setUserCompanies,
    clients, userClients, setUserClients,
    suppliers, userSuppliers, setUserSuppliers
  } = props;
  const { i18n } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.companiesList);
  const tabsConfig = useMemo(() => [{
      id: tabIds.companiesList,
      label: "companies-list",
      defaultSelectedTab: currentTabId === tabIds.companiesList
    }, {
      id: tabIds.clientsList,
      label: "clients-list",
      defaultSelectedTab: currentTabId === tabIds.clientsList
    }, {
      id: tabIds.suppliersList,
      label: "suppliers-list",
      defaultSelectedTab: currentTabId === tabIds.suppliersList
    }],
    // eslint-disable-next-line
    [i18n.language]);

  return <div className="user-links-wrapper">
    <div className="user-links-header-wrapper">
      <YeulsTabs
        config={tabsConfig}
        onChange={setCurrentTabId}/>
    </div>
    {currentTabId === tabIds.companiesList &&
    <UserCompaniesList companies={companies} userCompanies={userCompanies} setUserCompanies={setUserCompanies}/>}
    {currentTabId === tabIds.clientsList &&
    <UserClientsList clients={clients} userClients={userClients} setUserClients={setUserClients}/>}
    {currentTabId === tabIds.suppliersList &&
    <UserSuppliersList suppliers={suppliers} userSuppliers={userSuppliers} setUserSuppliers={setUserSuppliers}/>}
  </div>
}

export default UserLinks;