import * as _ from "lodash";
import React, { useMemo } from "react";
import { Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./IncomeTable.scss";
import YeulsTable from "../../../yeuls-table";

const IncomeTable = (props) => {
  const { viewInvoices, onHeaderCheckboxClick } = props;
  const incomeTableColumns = "paidToClientView.dateView.amountView.numberView.clientView.notesView";//.statusView";
  const { t } = useTranslation();

  const incomeTableHeaders = useMemo(() => [
    <div className="table-checkbox-checked-header">
      <Checkbox
        indeterminate={!_.includes([0, viewInvoices.length], _.filter(viewInvoices, (i) => i.include_in_salary).length)}
        checked={_.filter(viewInvoices, (i) => i.include_in_salary).length === viewInvoices.length}
        onChange={(e) => onHeaderCheckboxClick(e.target.checked)}
        classes={{
          checked: 'table-checkbox-checked',
          indeterminate: 'table-checkbox-checked',
          root: 'table-checkbox-root'
        }}/>
    </div>,
    t("date"), t("amount"), t("number"), t("client"), t("notes")],//, t("status")],
    // eslint-disable-next-line
    [viewInvoices]);

  return <div className="income-table-wrapper">
    <YeulsTable
      data={viewInvoices}
      headers={incomeTableHeaders}
      columns={incomeTableColumns}
      pagination={false}/>
  </div>
}

export default IncomeTable;