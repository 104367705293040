import { combineReducers } from 'redux';
import userState from './user';
import languageState from './language';
import monthlyOverviewState from './monthly-overview'

export const rootReducer = combineReducers({
  userState,
  languageState,
  monthlyOverviewState
});
