import React from "react";

import "./SuppliersList.scss";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import YeulsTable from "../../yeuls-table";

const SuppliersList = (props) => {
  const { t } = useTranslation();
  const { suppliers, setSelectedSupplier } = props;

  const mappedSuppliers = _.map(suppliers, (supplier) => ( {
    ...supplier,
    agentsCount: supplier.supplierAgents?.length || 0,
    nameView: <div className="clickable-block" onClick={() => setSelectedSupplier(supplier)}>{supplier.supplier_name}</div>
  } ));
  const headers = [t("supplier-name"), t("business-number"), t("agent-id"), t("email"), t("agents-count")];

  return <div className={"suppliers-list-wrapper"}>
    <YeulsTable
      data={mappedSuppliers}
      headers={headers}
      columns="nameView.business_number.santec_account_id.email.agentsCount"
      totalCount={mappedSuppliers.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className="suppliers-table-container"
    />
  </div>
}

export default SuppliersList;