import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getMissedReceipts = async (data) => {
  return axios({
    method: 'get',
    url: endpoints.Receipt.MISSED_RECEIPT,
    baseURL: baseUrl,
    data: data,
  });
};

export const getAllReceipts = async () => {
  return axios({
    method: 'get',
    url: endpoints.Receipt.RECEIPT,
    baseURL: baseUrl,
  })
    .then((response) => response.data.result);
};


export const getUserReceipts = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Receipt.USER_RECEIPT}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};