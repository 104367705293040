import { Grid } from '@material-ui/core';
import React from 'react';
import './UserAccountDocument.scss';
import { useTranslation } from 'react-i18next';
import { FileComponent } from '../../../common-components/file-component/input';
import { AgentDocumentType } from '../../../../../api/constants';

export const UserAccountDocuments = ({
  userFormData,
  setUserFormData,
  userDocuments,
}) => {
  const { t } = useTranslation();
  return (
    <div className="user-account-documents-wrapper">
      <Grid container spacing={3}>
        <FileComponent
          label={t('contract')}
          fileType="application/pdf"
          size={6}
          multiple={false}
          field="contract"
          format={t('pdf')}
          formData={userFormData}
          setFormData={setUserFormData}
          file={userDocuments?.filter(
            (doc) => doc?.fileType === AgentDocumentType.CONTRACT
          )}
          removeFileKey="isRemoveContract"
          removedFile={(id) => {}}
        />
        <FileComponent
          label={t('id-scan')}
          fileType="application/pdf, image/*"
          size={6}
          multiple={false}
          field="idScan"
          format={t('pdf-or-image')}
          formData={userFormData}
          setFormData={setUserFormData}
          file={userDocuments?.filter(
            (doc) => doc?.fileType === AgentDocumentType.ID_SCAN
          )}
          removeFileKey="isRemoveIdScan"
          removedFile={(id) => {}}
        />
        <FileComponent
          label={t('tax-form')}
          fileType="application/pdf"
          size={6}
          multiple={false}
          field="taxForm"
          format={t('pdf')}
          formData={userFormData}
          setFormData={setUserFormData}
          file={userDocuments?.filter(
            (doc) => doc?.fileType === AgentDocumentType.TAX_FORM
          )}
          removeFileKey="isRemoveTaxForm"
          removedFile={(id) => {}}
        />
      </Grid>
    </div>
  );
};
