import React from 'react';
import './Text.scss';

const Text = ({ text, language }) => {
  return (
    <span className={`${language.language === 'heb' ? 'rotate-text' : 'text'}`}>
      {text}
    </span>
  );
};

export default Text;
