import * as _ from "lodash";
import React, { useMemo } from "react";

import { Button } from "@material-ui/core";

import './YeulsButton.scss';

const YeulsButton = (props) => {
  const { children, variant, ...otherProps } = props;
  const variantClass = useMemo(() => {
    if (_.includes(['outlined', 'negative'], variant))
      return variant;

    if (variant === 'negative')
      return;

    return 'standard';

  }, [variant]);


  return <Button
    {...otherProps}
    className={`yeuls-button ${variantClass} ${otherProps.className}`}
  >
    {children}
  </Button>
}

export default YeulsButton;

