import React from "react";
import { useTranslation } from "react-i18next";

import "./MonthlyReportHeader.scss";

const MonthlyReportHeader = (props) => {
  const { t } = useTranslation();
  const {hourlyRate, dailyRate, exchangeRate} = props;

  return <div className="monthly-report-header-table-info-wrapper">
    <div className="monthly-report-header-table-info-item">
      <div className="monthly-report-header-table-info-title">{t(hourlyRate? "hourly-rate-with-colon":"daily-rate-with-colon")}</div>
      <div>{hourlyRate|| dailyRate}$</div>
    </div>
    <div className="monthly-report-header-table-info-item">
      <div className="monthly-report-header-table-info-title">{t("exchange-rate")}</div>
      <div>1$ = {exchangeRate}₪</div>
    </div>
  </div>
}

export default MonthlyReportHeader;