import React from "react";

import "./GeneralClientDetails.scss";
import { useTranslation } from "react-i18next";
import { Mode } from "../../../../../shared/constants";
import { Grid } from "@material-ui/core";
import DetailComponent from "../../../common-components/details-component";
import GeneralClientForm from "../../../common-components/forms/general-client-form";

const GeneralClientDetails = (props) => {
  const { t } = useTranslation();
  const { mode, client, clientFormData, setClientFormData } = props;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  return <div className="client-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("client-name")} value={client.client_name}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("business-number")} value={client.business_number}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("email")} value={client.email}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("phone")} value={client.phone}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("withholding-tax")} value={client.withholding_tax}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("withholding-tax-expiration")}
                                         value={client.withholding_tax_expiration ?
                                           new Date(client.withholding_tax_expiration).toLocaleDateString("en-GB", dateOptions) : ""}/>
      </Grid>
      <Grid item xs={6}><DetailComponent label={t("general-ledger")} value={client.general_ledger ?
        new Date(client.withholding_tax_expiration).toLocaleDateString("en-GB", dateOptions) : ""}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("created-by")} value={client.created_by}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("country")} value={client.country}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("city")} value={client.city}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("zip")} value={client.zip}/> </Grid>
      <Grid item xs={12}><DetailComponent label={t("address")} value={client.address}/> </Grid>
    </Grid>}
    {mode === Mode.edit &&
    <GeneralClientForm generalFormData={clientFormData} setGeneralFormData={setClientFormData}/>}
  </div>
}

export default GeneralClientDetails;
