import * as _ from "lodash";
import React, { useState } from "react";

import "./YeulsButtonSwitcher.scss";

const YeulsButtonSwitcher = (props) => {
  const { options } = props;
  const [activeButton, setActiveButton] = useState(_.find(options, (option) => option.activeByDefault));

  return <div className="button-switcher-wrapper">
    {_.map(options, (option) => {
      return <div
        className={`button-switcher-item ${option.id === activeButton.id ? 'active' : ''}`}
        key={option.id}
        onClick={()=>{
          setActiveButton(option);
          option.onSelect && option.onSelect(option);
        }}>
        {option.label}
      </div>
    })}
  </div>
}

export default YeulsButtonSwitcher;