import * as _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

import "./UserClientsList.scss";
import AccountsSearchField from "../../../common-components/accounts-search-field";
import YeulsTable from "../../../../yeuls-table";

const UserClientsList = (props) => {
  const { t, i18n } = useTranslation();
  const { clients, userClients, setUserClients } = props;
  const [filterValue, setFilterValue] = useState("");
  const clientsHeaders = useMemo(() => [".", t("name"), t("client-number"), t("business-number")], [i18n.language]);
  const clientsColumns = "selectedView.client_name.santec_account_id.business_number";

  const filteredClients = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    return _.filter(clients, (client) =>
      ( client.client_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( client.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( client.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);
  }, [clients, filterValue]);

  const userClientChangeHandler = (clientId, userClient) => {
    if (userClient) {
      setUserClients([..._.filter(userClients, (uc) => uc.client_id !== clientId), userClient])
    } else {
      setUserClients([..._.filter(userClients, (uc) => uc.client_id !== clientId)])
    }
  }

  const mappedClients = useMemo(() => {
    return _.map(filteredClients, (client) => {
      return {
        ...client,
        selectedView: <Checkbox checked={!!_.find(userClients, { client_id: client.id })}
                                onChange={(e) => userClientChangeHandler(client.id, e.target.checked ? { client_id: client.id } : null)}/>,
      }
    })
  }, [filteredClients, userClients]);

  return <div className="user-clients-details-wrapper">
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>
    <YeulsTable
      data={mappedClients}
      headers={clientsHeaders}
      columns={clientsColumns}
      totalCount={mappedClients.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className="agents-table-container"
    />
  </div>

}

export default UserClientsList;