import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./InsuranceUserDetails.scss";
import { Mode } from "../../../../../shared/constants";
import DetailComponent from "../../../common-components/details-component";
import InsuranceUserForm from "../../../common-components/forms/insurance-user-form";


const InsuranceUserDetails = (props) => {
  const { t } = useTranslation();
  const { mode, user, userFormData, setUserFormData } = props;

  return <div className="user-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={12}><DetailComponent label={t("insurance-notes")} value={user.insurance_notes}/></Grid>
    </Grid>}
    {mode === Mode.edit && <InsuranceUserForm insuranceFormData={userFormData} setInsuranceFormData={setUserFormData}/>}
  </div>
}

export default InsuranceUserDetails;