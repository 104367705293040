import React from 'react';
import Text from '../../../containers/Text';
import './../UserPage.scss';
import { useTranslation } from 'react-i18next';
import { MenuItem, TextField } from '@material-ui/core';
import { CalculationType, ReportType, SalaryDate } from '../../../shared/constants';

export const MoreInfo = ({ user }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="user-page-header">
        <h3>{t('bank-details')}</h3>
      </div>
      <div className="user-info">
        <div className="user-page-content">
          <div className="user-data">
            <Text text={t('bank-name')} />
            <input
              value={user.bank_name}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>

          <div className="user-data">
            <Text text={t('bank-branch-number')} />
            <input
              value={user.bank_branch_number}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>
        </div>
        <div className="user-password">
          <div className="user-data">
            <Text text={t('bank-number')} />
            <input
              value={user.bank_number}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>
          <div className="user-data">
            <Text text={t('bank-account-number')} />
            <input
              value={user.bank_account_number}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>
        </div>
      </div>
      <div className="user-page-header">
        <h3>{t('address-details')}</h3>
      </div>
      <div className="user-info">
        <div className="user-page-content">
          <div className="user-data">
            <Text text={t('address')} />
            <input
              value={user.address}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>

          <div className="user-data">
            <Text text={t('country')} />
            <input
              value={user.country}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>
        </div>
        <div className="user-password">
          <div className="user-data">
            <Text text={t('city')} />
            <input
              value={user.city}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>
          <div className="user-data">
            <Text text={t('zip')} />
            <input
              value={user.zip}
              type="text"
              autoComplete="off"
              disabled="true"
            />
          </div>
        </div>
      </div>
      <div className="user-page-header">
        <h3>{t('salary-details')}</h3>
      </div>
      <div className="user-info">
        <div className="user-page-content">
          <div className="user-data">
            <Text text={t('salary-date')} />
            <TextField
              variant="outlined"
              className="input-component-item-input"
              value={user.userTerms.salary_date}
              select
              disabled="true"
            >
              <MenuItem key={SalaryDate.first} value={SalaryDate.first}>
                {t(SalaryDate.first)}
              </MenuItem>
              <MenuItem key={SalaryDate.ninth} value={SalaryDate.ninth}>
                {t(SalaryDate.ninth)}
              </MenuItem>
            </TextField>
          </div>

          <div className="user-data">
            <Text text={t('report-type')} />
            <TextField
              variant="outlined"
              className="input-component-item-input"
              value={user.userTerms.report_type}
              select
              disabled="true"
            >
              <MenuItem key={ReportType.fixed} value={ReportType.fixed}>
                {t(ReportType.fixed)}
              </MenuItem>
              <MenuItem key={ReportType.invoice} value={ReportType.invoice}>
                {t(ReportType.invoice)}
              </MenuItem>
              <MenuItem key={ReportType.daily} value={ReportType.daily}>
                {t(ReportType.daily)}
              </MenuItem>
              <MenuItem key={ReportType.hourly} value={ReportType.hourly}>
                {t(ReportType.hourly)}
              </MenuItem>
            </TextField>
          </div>
        </div>
        <div className="user-password">
          <div className="user-data">
            <Text text={t('calculation-type')} />
            <TextField
              variant="outlined"
              className="input-component-item-input"
              value={user.userTerms.calculation_type}
              select
              disabled="true"
            >
              <MenuItem key={CalculationType.cost} value={CalculationType.cost}>
                {t(CalculationType.cost)}
              </MenuItem>
              <MenuItem
                key={CalculationType.gross}
                value={CalculationType.gross}
              >
                {t(CalculationType.gross)}
              </MenuItem>
            </TextField>
          </div>
        </div>
      </div>
    </>
  );
};
