import { connect } from 'react-redux';
import Text from '../components/text';

export function mapStateToProps({ languageState }) {
  return {
    language: languageState,
  };
}

export default connect(mapStateToProps)(Text);
