import React from 'react';
import { useTranslation } from 'react-i18next';

import BankInformation from './bank-information';
import MailInformation from './mail-information';

import Text from '../../containers/Text';
import './HomePage.scss';

import useWindowDimensions from '../../hooks/windowResize';

const YeulSachir = () => {
  const { t } = useTranslation();
  const { screenWidth } = useWindowDimensions();

  if (screenWidth < 600) {
    return (
      <>
        <div className="mail-information">
          <p className="title">
            <Text text={t('mail-info-title')} />
          </p>
          <MailInformation />
        </div>
        <div className="bank-information">
          <p className="title">
            <Text text={t('bank-info-title')} />
          </p>
          <BankInformation />
        </div>
      </>
    );
  }

  return (
    <div className="yeul-sachir">
      <p className="title">
        <Text text={t('yeul-sachir-title')} />
      </p>
      <MailInformation />
      <BankInformation />
    </div>
  );
};

export default YeulSachir;
