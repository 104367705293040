import React from "react";

import "./BankClientDetails.scss";
import { Mode } from "../../../../../shared/constants";
import { Grid } from "@material-ui/core";
import DetailComponent from "../../../common-components/details-component";
import BankForm from "../../../common-components/forms/bank-form";
import { useTranslation } from "react-i18next";

const BankClientDetails = (props) => {
  const { t } = useTranslation();
  const { mode, client, clientFormData, setClientFormData } = props;

  return <div className="client-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("bank-name")} value={client.bank_name}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("bank-number")} value={client.bank_number}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("bank-branch-number")} value={client.bank_branch_number}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("bank-account-number")} value={client.bank_account_number}/> </Grid>
    </Grid>}
    {mode === Mode.edit && <BankForm bankFormData={clientFormData} setBankFormData={setClientFormData}/>}
  </div>
}

export default BankClientDetails;
