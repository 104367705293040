import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./GeneralCompanyForm.scss";
import InputComponent from "../../input-component";
import { KeyboardDatePicker } from "@material-ui/pickers";

const GeneralCompanyForm = (props) => {
  const { generalFormData, setGeneralFormData } = props;
  const { t } = useTranslation();

  return <Grid spacing={3} container>
    <Grid item xs={6}>
      <InputComponent field="company_name" tLabel="company-name" formData={generalFormData}
                      setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="business_number" tLabel="business-number" formData={generalFormData}
                      setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="email" tLabel="email" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="phone" tLabel="phone" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent type="number" field="withholding_tax" tLabel="withholding-tax" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <div className="input-component-item">
        <label className="input-component-item-label">{t("withholding-tax-expiration")}</label>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.yyyy"
          value={generalFormData.withholding_tax_expiration.value}
          className="terms-and-conditions-date-picker"
          InputAdornmentProps={{ position: 'end', variant: 'standard' }}
          onChange={(newValue) => {
            setGeneralFormData({ ...generalFormData, withholding_tax_expiration: { value: newValue?.toDate(), error: false } })
          }}
        />
      </div>
    </Grid>
    <Grid item xs={6}>
      <div className="input-component-item">
        <label className="input-component-item-label">{t("general-ledger")}</label>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.yyyy"
          value={generalFormData.general_ledger.value}
          className="terms-and-conditions-date-picker"
          InputAdornmentProps={{ position: 'end', variant: 'standard' }}
          onChange={(newValue) => {
            setGeneralFormData({ ...generalFormData, general_ledger: { value: newValue?.toDate(), error: false } })
          }}
        />
      </div>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="country" tLabel="country" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="city" tLabel="city" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="zip" tLabel="zip" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={12}>
      <InputComponent multiline minRows={2} field="address" tLabel="address" formData={generalFormData}
                      setFormData={setGeneralFormData}/>
    </Grid>
  </Grid>
}

export default GeneralCompanyForm;