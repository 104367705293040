import * as actions from '../redux/actions/index';
import { connect } from 'react-redux';
import Header from '../components/header';

export function mapStateToProps({ languageState }) {
  return {
    language: languageState,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (payload) => dispatch(actions.setLanguage(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
