import React, { useEffect } from 'react';

import './Header.scss';

import Text from '../../containers/Text';
import '../../languages/i18n';
import AppLogo from '../../shared/icons/logo.svg';

import useWindowDimensions from '../../hooks/windowResize';

import { GiHamburgerMenu } from 'react-icons/gi';
import { useHistory } from 'react-router-dom';
import { ActivePageService } from '../../services/active-page';

const Header = ({
  changeLanguage,
  setLanguage,
  user,
  handleDrawer,
  setActivePage,
}) => {
  const { screenWidth } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    const page = ActivePageService.getActivePageUrl(history.location.pathname);

    setActivePage(page);
  }, [history.location.pathname, setActivePage]);

  function redirectToHome() {
    history.push('/');
  }

  return (
    <div className={!user.id ? 'auth-header-container' : 'header-container'}>
      {user.id && (
        <GiHamburgerMenu
          size={35}
          className={`nav-bar-icon`}
          onClick={handleDrawer}
        ></GiHamburgerMenu>
      )}
      {screenWidth < 600 ? (
        <div className="lng-change-container">
          <Text
            text={
              <img
                src={AppLogo}
                onClick={redirectToHome}
                className="app-logo"
                alt="app logo"
              />
            }
          />
        </div>
      ) : (
    <div/>
      )}
    </div>
  );
};

export default Header;
