import React from "react";

import "./UserCompaniesForm.scss";
import { useTranslation } from "react-i18next";
import {  Grid, MenuItem, Switch } from "@material-ui/core";
import InputComponent from "../../input-component";
import { CompanyRole } from "../../../../../shared/constants";

const UserCompaniesFormItem = (props) => {
  const { companyUserFormData, setCompanyUserFormData } = props;
  const { t } = useTranslation();

  return <Grid container spacing={3}>
    <Grid xs={6} item>
      <InputComponent
        type="number"
        field="daily_rate"
        tLabel="daily-rate"
        formData={companyUserFormData}
        setFormData={setCompanyUserFormData}/>
    </Grid>
    <Grid xs={6} item>
      <InputComponent
        type="number"
        field="hourly_rate"
        tLabel="hourly-rate"
        formData={companyUserFormData}
        setFormData={setCompanyUserFormData}/>
    </Grid>
    <Grid xs={6} item>
      <InputComponent field="company_role" tLabel="role" formData={companyUserFormData}
                      setFormData={setCompanyUserFormData} select>
        <MenuItem key={CompanyRole.employee} value={CompanyRole.employee}>{t(CompanyRole.employee)}</MenuItem>
        <MenuItem key={CompanyRole.manager} value={CompanyRole.manager}>{t(CompanyRole.manager)}</MenuItem>
      </InputComponent>
    </Grid>
    <Grid xs={6} item className="grid-switch-wrapper">
      <div className="company-item">
        <div className="company-item-label">{t("is-use-daily-rate")}</div>
        <Switch
          classes={{ root: 'switch-root', track: 'switch-track', switchBase: 'switch-base' }}
          defaultChecked={companyUserFormData.is_daily_rate.value}
          onChange={(e) => setCompanyUserFormData({
            ...companyUserFormData,
            is_daily_rate: { value: e.target.checked }
          })}/>
      </div>
    </Grid>
  </Grid>

}

export default UserCompaniesFormItem;