import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider, Grid } from "@material-ui/core";

import "./UserCompanyItem.scss";
import UserCompaniesFormItem from "../../../../common-components/forms/user-companies-form";
import { getCompanyNameView } from "../../../../common-components/utils";
import { CompanyRole } from "../../../../../../shared/constants";

const defaultUserCompanyFormData = {
  daily_rate: { value: null, error: false },
  hourly_rate: { value: null, error: false },
  company_role: { value: CompanyRole.employee, error: false },
  is_daily_rate: { value: true, error: false },
}

const UserCompanyItem = (props) => {
  const { t } = useTranslation();
  const { company, onChange } = props;
  const [expanded, setExpanded] = useState(false)
  const [companyUserFormData, setCompanyUserFormData] = useState(defaultUserCompanyFormData);

  useEffect(() => {

    onChange(!expanded ? null : {
      daily_rate: companyUserFormData.daily_rate.value,
      hourly_rate: companyUserFormData.hourly_rate.value,
      company_role: companyUserFormData.company_role.value || CompanyRole.employee,
      is_daily_rate: companyUserFormData.is_daily_rate.value,
      company_id: company.id
    });
    // eslint-disable-next-line
  }, [companyUserFormData, expanded]);

  return <div className="user-companies-form-item">
    <div className="user-companies-form-item-header">
      <Grid container>
        <Grid xs={1} item className="vertically-center">
          <Checkbox checked={expanded} onChange={(e) => setExpanded(e.target.checked)}/>
        </Grid>
        <Grid xs={11} item className="vertically-center">
          {getCompanyNameView(company, t)}
        </Grid>
      </Grid>
    </div>
    {expanded && <Divider className="user-companies-form-divider"/>}
    {expanded && <div className="user-companies-form-item-content">
      <UserCompaniesFormItem companyUserFormData={companyUserFormData} setCompanyUserFormData={setCompanyUserFormData}/>
    </div>}
  </div>

}

export default UserCompanyItem;