import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MissedTable from './MissedTable';
import Spinner from '../spinner';

import Text from '../../containers/Text';
import './Missed.scss';

import { ExpandMore } from '@material-ui/icons';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@material-ui/core';

import { getMissedInvoices } from '../../api/requests/invoice';
import { getMissedInvoiceReceipts } from '../../api/requests/invoice-receipts';
import { getMissedReceipts } from '../../api/requests/receipt';
import { getMissedSalaries } from '../../api/requests/monthly';

const MissedFiles = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [missedFiles, setMissedFiles] = useState({
    invoices: [],
    receipts: [],
    invoiceReceipts: [],
    salaries: [],
  });

  useEffect(() => {
    async function getActiveUsers() {
      try {
        const missedInvoices = getMissedInvoices();
        const missedReceipts = getMissedReceipts();
        const missedInvoiceReceipts = getMissedInvoiceReceipts();
        const missedSalaries = getMissedSalaries();

        const result = await Promise.all([
          missedInvoices,
          missedReceipts,
          missedInvoiceReceipts,
          missedSalaries,
        ]).then((response) => {
          return response;
        });

        setMissedFiles({
          invoices: result[0].data.result,
          receipts: result[1].data.result,
          invoiceReceipts: result[2].data.result,
          salaries: result[3].data.result,
        });
      } catch (err) {
        setErrorMessage(
          'Something went wrong. Refresh page and try again please.'
        );
      } finally {
        setIsLoading(false);
      }
    }
    getActiveUsers();
  }, []);

  const getFilesCount = (collection, filesAttribute = 'files') => {
    return collection.reduce((acc, item) => {
      return acc + item[filesAttribute].length;
    }, 0);
  };

  return (
    <div className="admin-panel-container">
      <header className="admin-panel-header">
        <div className="admin-panel-header-title">
          {t('missed-files')}
        </div>
      </header>

      {errorMessage !== '' && (
        <div className="error-message">
          <Text text={t(errorMessage)} />
        </div>
      )}

      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="salary-content">
          <Accordion className="accordion">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Text text={t('invoices')} />
              <div className="month-number">
                <Text text={t(getFilesCount(missedFiles.invoices))} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <MissedTable userMissedFiles={missedFiles.invoices} />
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Text text={t('receipt')} />
              <div className="month-number">
                <Text text={t(getFilesCount(missedFiles.receipts))} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <MissedTable userMissedFiles={missedFiles.receipts} />
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Text text={t('invoice/receipt')} />
              <div className="month-number">
                <Text text={t(getFilesCount(missedFiles.invoiceReceipts))} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <MissedTable userMissedFiles={missedFiles.invoiceReceipts} />
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Text text={t('monthly-files')} />
              <div className="month-number">
                <Text
                  text={t(getFilesCount(missedFiles.salaries, 'monthlies'))}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <MissedTable
                userMissedFiles={missedFiles.salaries}
                filesAttribute="monthlies"
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordion">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Text text={t('salaries')} />
              <div className="month-number">
                <Text
                  text={t(getFilesCount(missedFiles.salaries, 'salaries'))}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <MissedTable
                userMissedFiles={missedFiles.salaries}
                filesAttribute="salaries"
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default MissedFiles;
