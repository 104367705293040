import React from 'react';
import { useTranslation } from 'react-i18next';

import UserProfileIcon from '../../shared/icons/user-profile-icon.svg';

import useWindowDimensions from '../../hooks/windowResize';
import ContactUs from './contact-us';
import YeulSachir from './yeul-sachir';
import { Link } from 'react-router-dom';

import Text from '../../containers/Text';
import './HomePage.scss';

const HomePage = ({ user }) => {
  const { t } = useTranslation();
  const { screenWidth } = useWindowDimensions();

  return (
    <div className="home-page-container">
      <div className="home-page-title-container">
        <div className="home-page-title">
          <Text text={t('home-page-title')}/>
          {screenWidth > 600 && (
            <Link
              to={`/user/${user.id}`}
              className="profile-button"
            >
              <img
                className="user-avatar"
                src={UserProfileIcon}
                alt="profile"
              />
              <Text text={user.firstName + ' ' + ( user.lastName || '' )}/>
            </Link>
          )}
        </div>
      </div>
      <YeulSachir/>
      <ContactUs/>
    </div>
  );
};

export default HomePage;
