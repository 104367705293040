import * as _ from "lodash";
import React, { useEffect, useState } from "react";

import "./CompaniesList.scss";
import { useTranslation } from "react-i18next";
import YeulsTabs from "../../yeuls-tabs";
import YeulsTable from "../../yeuls-table";
import { Role } from "../../../shared/constants";

const tabIds = {
  open: 0,
  closed: 1,
}

const CompaniesList = (props) => {
  const { companies, currentMonth, onCompanyClick, user } = props;
  const { t, i18n } = useTranslation();
  const [tabsConfig, setTabsConfig] = useState([
    { id: tabIds.open, label: "open", itemsCount: 0, defaultSelectedTab: true },
    { id: tabIds.closed, label: "closed", itemsCount: 0 }]);
  const [currentTabId, setCurrentTabId] = useState(tabIds.open);
  const [openCompanies, setOpenCompanies] = useState([]);
  const [closedCompanies, setClosedCompanies] = useState([]);
  const openCompanyTableHeaders = [t("company-name"), t("agent-id"), t("status")];
  const openCompanyTableColumns = "companyNameView.santec_account_id.reportSubmitStatusView";
  const closeCompanyTableHeaders = [t("company-name"), t("agent-id"), t("status"), t("invoice")];
  const closeCompanyTableColumns = "companyNameView.santec_account_id.reportSubmitStatusView.invoice";

  useEffect(() => {
    if (!companies?.length || !currentMonth)
      return;

    const openCompanies = [];
    const closedCompanies = [];

    _.forEach(companies, (company) => {
      const report = _.find(company.reports, (report) => report.month === currentMonth.month && report.year === currentMonth.year)

      if (report?.invoice_id)
        closedCompanies.push(company)
      else
        openCompanies.push(company)
    });

    setOpenCompanies(updateCompaniesStatus(openCompanies));
    setClosedCompanies(updateCompaniesStatus(closedCompanies));
    setTabsConfig([
      { id: tabIds.open, label: "open", itemsCount: openCompanies?.length, defaultSelectedTab: true },
      { id: tabIds.closed, label: "closed", itemsCount: closedCompanies?.length },
    ]);
    // eslint-disable-next-line
  }, [companies, currentMonth, i18n.language]);

  const updateCompaniesStatus = (companies) => {
    return _.map(companies, (company) => {
      const report = _.find(company.reports, (report) => report.month === currentMonth.month && report.year === currentMonth.year)

      company.reportSubmitStatusView =
        <div
          className={`companies-list-report-status ${report?.submitted_by_manager_at ? 'ready' : ''}`}>
          {report?.submitted_by_manager_at ? ( report?.invoice_id ? t('done') : t('ready') ) : t('pending')}
        </div>;

      company.companyNameView = <div
        className="clickable-block"
        onClick={() => {
          if (!report?.submitted_by_manager_at && user.role === Role.admin)
            return;

          onCompanyClick(company.id)
        }}>{company.company_name}</div>;

      return company;
    })
  }

  return <div className="companies-list-wrapper">
    <div className="tabs-wrapper">
      <YeulsTabs
        config={tabsConfig}
        onChange={setCurrentTabId}/>
    </div>
    {currentTabId === tabIds.open &&
    <div className={'tab-content-wrapper'}>
      <YeulsTable
        data={openCompanies}
        headers={openCompanyTableHeaders}
        columns={openCompanyTableColumns}
        pagination={false}/>
    </div>
    }
    {currentTabId === tabIds.closed &&
    <div className={'tab-content-wrapper'}>
      <YeulsTable
        data={closedCompanies}
        headers={closeCompanyTableHeaders}
        columns={closeCompanyTableColumns}/>
    </div>
    }
  </div>
}

export default CompaniesList;