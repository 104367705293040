import TextField from "@material-ui/core/TextField";
import React from "react";
import { useTranslation } from "react-i18next";

import "./Search.scss";
import SearchIcon from "../../shared/icons/search-icon.svg";
import CloseIcon from "../../shared/icons/close-icon.svg";

const SearchField = (props) => {
  const { value, onChange, onClearFilter, placeholderKey ="search-by-all-fields" } = props;
  const { t } = useTranslation();

  return <TextField
    value={value}
    onChange={(event) => onChange(event.target.value)}
    variant="standard"
    classes={{ root: "search-root" }}
    InputProps={{
      classes: { underline: "search-underline" },
      startAdornment: <img
        src={SearchIcon}
        className="search-icon"
        alt="Search-icon"
      />,
      endAdornment: !!value && <img
        src={CloseIcon}
        className="close-icon"
        alt="Clear filter icon"
        onClick={onClearFilter}
      />
    }}
    placeholder={t(placeholderKey)}
  />
}

export default SearchField;