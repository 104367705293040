import * as _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

import YeulsTable from "../../../yeuls-table";
import "./SalaryDoneOutcomeTable.scss";

const SalaryDoneOutcomeTable = (props) => {
  const { viewExpenses, onHeaderCheckboxClick } = props;
  const outcomeTableColumns = "paidToClientView.record_type.record_id.createdView.dateView.notesView.supplierView.amountBeforeVatView.vatView.amountView.paidByView.statusView";
  const { t } = useTranslation();

  const outcomeTableHeaders = useMemo(() => [
      <div className="table-checkbox-checked-header">
        <Checkbox
          checked={true}
          classes={{
            checked: 'table-checkbox-checked',
            indeterminate: 'table-checkbox-checked',
            root: 'table-checkbox-root'
          }}/>
      </div>,
      t("record-type"), t("number"), t("created-date"), t("due-date"), t("notes"), t("client"), t("amount-before-vat"), t("vat"), t("amount"), t("paid-by"), t("status")],
    // eslint-disable-next-line
    [viewExpenses]);

  return <div className="salary-done-outcome-table-wrapper">
    <YeulsTable
      data={viewExpenses}
      headers={outcomeTableHeaders}
      columns={outcomeTableColumns}
      pagination={false}/>
  </div>
}

export default SalaryDoneOutcomeTable;