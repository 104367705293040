import * as constants from '../constants';
import { SessionStorageService } from '../../shared/services/session-storage';

const initialState = {
  language: SessionStorageService.getAppLanguage(),
};

const actionHandlers = {
  [constants.SET_LANGUAGE](state, language) {
    return { ...state, ...language };
  },
};

export default function languageState(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
