import React, {useState} from 'react';
import Text from "../../containers/Text";

import { useTranslation } from 'react-i18next';

import '../../languages/i18n';
import './Auth.scss';
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";

const Admin = ({config}) => {
    const [isPasswordShowing, setPasswordShowing] = useState(false);

    const { t } = useTranslation();

    return (
        <div className="content-wrapper">
            <div className={"input-wrapper"}>
                  <span className="email">
                    <Text text={t('e-mail')} />
                  </span>
                <input
                    type="email"
                    name="email"
                    onChange={(e) => config.setEmail(e.target.value)}
                />
            </div>
            <div className="input-wrapper">
                    <span className="password">
                        <Text text={t('password')} />
                    </span>
                <div className="password-container">
                    <input
                        type={isPasswordShowing ? 'text' : 'password'}
                        onChange={(e) => config.setPassword(e.target.value)}
                    />
                    <div onClick={() => setPasswordShowing(!isPasswordShowing)}>
                        {isPasswordShowing ? (
                            <AiFillEye className="eye" />
                        ) : (
                            <AiFillEyeInvisible className="eye" />
                        )}
                    </div>
                </div>
            </div>
            {config.error.active && (
                <span className="error">
                    <Text text={config.error.message} />
                  </span>
            )}
            <input
                className="margin-top--50"
                type="submit"
                value={t('sign-in')}
            />
        </div>
    );
};

export default Admin;