import React, { useMemo, useState } from "react";

import "./CompanySuppliersListDetails.scss";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { Mode } from "../../../../../shared/constants";
import { Checkbox } from "@material-ui/core";
import AccountsSearchField from "../../../common-components/accounts-search-field";
import YeulsTable from "../../../../yeuls-table";

const CompanySuppliersListDetails = (props) => {
  const { t, i18n } = useTranslation();
  const { mode, company, suppliers, companySuppliersChanges, onCompanySupplierChange } = props;
  const [filterValue, setFilterValue] = useState("");
  const [updateTable, setUpdateTable] = useState(true);
  const suppliersHeaders = useMemo(() => mode === Mode.edit ?
    [".", t("name"), t("supplier-number"), t("business-number")] :
    [t("name"), t("supplier-number"), t("business-number")], [i18n.language, mode]);
  const suppliersColumns = useMemo(() =>
      mode === Mode.edit ?
        "selectedView.supplier_name.santec_account_id.business_number" :
        "supplier_name.santec_account_id.business_number"
    , [mode]);

  const filteredSuppliers = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    const companySupplierIds = _.map(company.suppliers, "id");
    const filteredValues = _.filter(suppliers, (supplier) =>
      ( supplier.supplier_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( supplier.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( supplier.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);

    return [
      ..._.filter(filteredValues, (value) => _.includes(companySupplierIds, value.id)),
      ..._.filter(filteredValues, (value) => !_.includes(companySupplierIds, value.id)),
    ]
    // eslint-disable-next-line
  }, [suppliers, filterValue, mode]);

  const mappedSuppliers = useMemo(() => {
    const companySupplierIds = _.map(company.suppliers, "id");

    if (!_.values(companySuppliersChanges)?.length) {
      setUpdateTable(false);
      setTimeout(() => setUpdateTable(true));
    }

    return _(filteredSuppliers)
      .filter((supplier) => mode === Mode.edit || _.includes(companySupplierIds, supplier.id))
      .map((supplier) => {
        return {
          ...supplier,
          selectedView: <Checkbox checked={( !!_.find(company.companySuppliers, { supplier_id: supplier.id }) ||
            !!companySuppliersChanges[supplier.id] ) && companySuppliersChanges[supplier.id] !== null}
                                  onChange={(e) => onCompanySupplierChange(e.target.checked, supplier.id)}/>,
        }
      })
      .value();
  }, [filteredSuppliers, mode, companySuppliersChanges]);

  return <div className="company-suppliers-details-wrapper">
    {mode === Mode.edit &&
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>}
    {updateTable && <YeulsTable
      data={mappedSuppliers}
      headers={suppliersHeaders}
      columns={suppliersColumns}
      totalCount={mappedSuppliers.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className={`agents-table-container ${mode === Mode.edit ? "edit" : "view"}`}
    />}
    {mode === Mode.view && !company.suppliers?.length &&
    <div className="empty-list">{t("company-suppliers-empty-list")}</div>}
  </div>
}

export default CompanySuppliersListDetails;