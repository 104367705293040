import * as _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Divider, Grid, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./EmployeeSalary.scss";
import YeulsButton from "../../yeuls-button";
import ConfirmDialog from "../../confirm-dialog";
import EditableLineInput from "../../editable-line-input";
import { Currency, SalaryStatus } from "../../../shared/constants";
import IncomeTable from "./income-table";
import OutcomeTable from "./outcome-table";
import EmployeeSalaryFooter from "./employee-salary-footer";
import { NumbersService } from "../../../services/numbers";
import YeulsInputComponent from "../../yeuls-input-component";
import SalaryDoneOutcomeTable from "./salary-done-outcome-table";
import SalaryDoneIncomeTable from "./salary-done-income-table";

const DialogType = {
  validateAndApprove: "validateAndApprove",
  splitIncome: "splitIncome",
  splitOutcome: "splitOutcome",
  addIncome: "addIncome",
}

const updateType = {
  invoice: "invoice",
  expense: "expense",
}

const EmployeeSalary = (props) => {
  const { currentMonth, employee, updateEmployeeSalary, suppliers, clients } = props;
  const { t } = useTranslation();
  const [dialogConfig, setDialogConfig] = useState(null);
  const [viewInvoices, setViewInvoices] = useState([]);
  const [viewExpenses, setViewExpenses] = useState([]);
  const [invoiceUpdates, setInvoiceUpdates] = useState({});
  const [expenseUpdates, setExpenseUpdates] = useState({});
  const [showNotPaidIncomes, setShowNotPaidIncomes] = useState(false);
  const [showNotPaidOutcomes, setShowNotPaidOutcomes] = useState(false);
  const [update, setUpdate] = useState(null);

  const currentSalary = useMemo(() => {
    return _.find(employee.salaries, (salary) =>
      salary.year === currentMonth.year &&
      salary.month === currentMonth.month);
  }, [employee, currentMonth]);

  const salary = useMemo(() => {
    return _.find(employee.salaries, (salary) =>
      salary.year === currentMonth.year &&
      salary.month === currentMonth.month);
  }, [currentMonth, employee])

  const onIncomeCheckboxClick = (value) => {
    if (salary?.status === SalaryStatus.done)
      return;

    let updates = { ...invoiceUpdates };

    setViewInvoices(_.map(viewInvoices, (invoice) => {
      if (invoice.include_in_salary !== value)
        updates = {
          ...updates, [invoice.id]: {
            ...( invoiceUpdates[invoice.id] || {} ),
            include_in_salary: value
          }
        }

      return getViewInvoice({ ...invoice, include_in_salary: value });
    }));
    setInvoiceUpdates(updates);
  }

  const onOutcomeCheckboxClick = (value) => {
    if (salary?.status === SalaryStatus.done)
      return;

    let updates = { ...expenseUpdates };

    setViewExpenses(_.map(viewExpenses, (expense) => {
      if (expense.include_in_salary !== value)
        updates = {
          ...updates, [expense.id]: {
            ...( expenseUpdates[expense.id] || {} ),
            include_in_salary: value
          }
        }

      return getViewExpense({ ...expense, include_in_salary: value });
    }));
    setExpenseUpdates(updates);
  }

  useEffect(() => {
    if (!update || salary?.status === SalaryStatus.done)
      return;

    if (update.type === updateType.invoice) {
      setInvoiceUpdates({
        ...invoiceUpdates,
        [update.value.id]: {
          ...( invoiceUpdates[update.id] || {} ),
          ...update.value
        }
      });

      refreshViewInvoices({ ..._.find(viewInvoices, { id: update.value.id }), ...update.value });
    } else {
      setExpenseUpdates({
        ...expenseUpdates,
        [update.value.id]: {
          ...( expenseUpdates[update.id] || {} ),
          ...update.value
        }
      });

      refreshViewExpenses({ ..._.find(viewExpenses, { id: update.value.id }), ...update.value });
    }
    setUpdate(null);
    // eslint-disable-next-line
  }, [update])

  useEffect(() => {
    const currentMonthInvoices = _.filter(employee.invoices, (invoice) => {
      const invoiceCreateDate = new Date(invoice.created_on);

      return invoiceCreateDate.getMonth() + 1 === currentMonth.month && invoiceCreateDate.getFullYear() === currentMonth.year;
    });
    const currentMonthExpenses = _.filter(employee.expenses, (expense) => {
      return expense.month === currentMonth.month && expense.year === currentMonth.year;
    });

    if (currentSalary?.status !== SalaryStatus.done) {
      const invoiceUpdate = {};
      const expenseUpdate = {};
      setViewInvoices(_(currentMonthInvoices)
        .filter((invoice) => invoice.paid || showNotPaidIncomes)
        .map((invoice) => {
          const includeInSalary = invoiceUpdates[invoice.id] ? invoiceUpdates[invoice.id].include_in_salary : !!invoice.paid;
          invoiceUpdate[invoice.id] = {
            id: invoice.id,
            invoice_number: invoice.invoice_number,
            include_in_salary: includeInSalary
          }

          return getViewInvoice({ ...invoice, include_in_salary: includeInSalary })
        })
        .value());

      setViewExpenses(_(currentMonthExpenses)
        .filter((expense) => expense.paid || showNotPaidOutcomes)
        .map((expense) => {
          const includeInSalary = expenseUpdates[expense.id] ? expenseUpdates[expense.id].include_in_salary : !!expense.paid;

          expenseUpdate[expense.id] = { id: expense.id, include_in_salary: includeInSalary };

          return getViewExpense({ ...expense, include_in_salary: includeInSalary })
        })
        .value());

      setInvoiceUpdates(invoiceUpdate);
      setExpenseUpdates(expenseUpdate);
    } else {
      const currentMonthIncomeSalaryRecords = _.filter(employee.salaryRecords, (record) => record.month === currentMonth.month && record.year === currentMonth.year && ( record.record_type === 30 || record.record_type === 31 ));
      const currentMonthOutcomeSalaryRecords = _.filter(employee.salaryRecords, (record) => record.month === currentMonth.month && record.year === currentMonth.year && ( record.record_type === 10 || record.record_type === 11 ));

      setViewInvoices(_.map(currentMonthIncomeSalaryRecords, (record) => getViewSalaryRecord(record)));
      setViewExpenses(_.map(currentMonthOutcomeSalaryRecords, (record) => getViewSalaryRecord(record)));
    }
    // eslint-disable-next-line
  }, [employee, currentMonth, showNotPaidIncomes, showNotPaidOutcomes]);

  const refreshViewInvoices = (newInvoice) => {
    const updatedViewInvoices = [...viewInvoices];
    const index = _.findIndex(updatedViewInvoices, { id: newInvoice.id });
    updatedViewInvoices.splice(index, 1, getViewInvoice(newInvoice));

    setViewInvoices(updatedViewInvoices);
  }

  const refreshViewExpenses = (newExpense) => {
    const updatedViewExpenses = [...viewExpenses];
    const index = _.findIndex(updatedViewExpenses, { id: newExpense.id });
    updatedViewExpenses.splice(index, 1, getViewExpense(newExpense));

    setViewExpenses(updatedViewExpenses);
  }

  const getViewInvoice = (invoice) => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const viewInvoice = { ...invoice };
    viewInvoice.paidToClientView = <Checkbox
      classes={{ checked: 'table-checkbox-checked', root: 'table-checkbox-root' }}
      checked={!!invoice.include_in_salary}
      onChange={() => setUpdate({
        type: updateType.invoice,
        value: { id: invoice.id, invoice_number: invoice.invoice_number, include_in_salary: !invoice.include_in_salary }
      })}
    />;
    viewInvoice.dateView = new Date(viewInvoice.created_on).toLocaleDateString("en-GB", dateOptions);
    viewInvoice.amountView = `₪ ${NumbersService.numberWithCommas(viewInvoice.amount_with_vat, true)}`;
    viewInvoice.amountVatView = `₪ ${NumbersService.numberWithCommas(viewInvoice.amount_before_vat, true)}`;
    viewInvoice.numberView = `${viewInvoice.invoice_number}`;
    viewInvoice.notesView = salary?.status !== SalaryStatus.done ?
      <EditableLineInput
        value={viewInvoice.notes}
        onSave={(notes) => setUpdate({
          type: updateType.invoice,
          value: { id: invoice.id, notes }
        })}>{viewInvoice.notes}</EditableLineInput> :
      viewInvoice.notes;
    viewInvoice.clientView = viewInvoice.client?.client_name || "";
    viewInvoice.statusView = viewInvoice.paid ?
      <div className="company-salary-status paid">{t('paid')}</div> :
      <div className="company-salary-status">{t('not-paid')}</div>;

    return viewInvoice;
  }

  const getViewExpense = (expense) => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const viewExpense = { ...expense };
    viewExpense.includeInSalaryView = <Checkbox
      classes={{ checked: 'table-checkbox-checked', root: 'table-checkbox-root' }}
      checked={!!expense.include_in_salary}
      onChange={() => setUpdate({
        type: updateType.expense,
        value: { id: expense.id, include_in_salary: !expense.include_in_salary }
      })}
    />;
    viewExpense.dateView = new Date(viewExpense.date).toLocaleDateString("en-GB", dateOptions);
    viewExpense.amountView = `₪ ${NumbersService.numberWithCommas(viewExpense.amount, true)}`;
    viewExpense.numberView = `${viewExpense.expense_number}`;
    viewExpense.notesView = salary?.status !== SalaryStatus.done ?
      <EditableLineInput
        value={viewExpense.salary_notes}
        onSave={(notes) => setUpdate({
          type: updateType.expense,
          value: { id: expense.id, salary_notes: notes }
        })}>{viewExpense.salary_notes}</EditableLineInput> :
      viewExpense.salary_notes;
    viewExpense.paidByView = t(viewExpense.paid_by);
    viewExpense.supplierView = viewExpense.supplier?.supplier_name || "";
    viewExpense.statusView = viewExpense.paid ?
      <div className="company-salary-status paid">{t('paid')}</div> :
      <div className="company-salary-status">{t('not-paid')}</div>;

    return viewExpense;
  }

  const getViewSalaryRecord = (record) => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const viewRecord = { ...record };
    viewRecord.paidToClientView = <Checkbox
      classes={{ checked: 'table-checkbox-checked', root: 'table-checkbox-root' }}
      checked={true}
      disabled={true}
    />;
    viewRecord.includeInSalaryView = viewRecord.paidToClientView;
    viewRecord.createdView = new Date(viewRecord.created_on).toLocaleDateString("en-GB", dateOptions);
    viewRecord.dateView = new Date(viewRecord.due_date).toLocaleDateString("en-GB", dateOptions);
    viewRecord.amountBeforeVatView = `₪ ${NumbersService.numberWithCommas(viewRecord.amount_before_vat, true)}`;
    viewRecord.vatView = `₪ ${NumbersService.numberWithCommas(viewRecord.amount_vat, true)}`;
    viewRecord.amountView = `₪ ${NumbersService.numberWithCommas(viewRecord.amount, true)}`;
    viewRecord.numberView = `${viewRecord.record_id}`;
    viewRecord.notesView = viewRecord.notes;

    if (record.record_type === 30 || record.record_type === 31) {
      viewRecord.clientView = _.find(clients, (client) => !!_.find(client.clientAgents, { santec_account_id: record.santec_account_id }))?.client_name || record.santec_account_id || "";
    } else {
      viewRecord.supplierView = _.find(suppliers, (supplier) => !!_.find(supplier.supplierAgents, { santec_account_id: record.santec_account_id }))?.supplier_name || record.santec_account_id || "";
    }
    viewRecord.statusView = <div className="company-salary-status paid">{t('paid')}</div>;

    return viewRecord;
  }

  const totalIncome = useMemo(() => {
    return Math.floor(_(viewInvoices).map(i => +i.amount_with_vat).sum() * 100) / 100;
  }, [viewInvoices]);

  const totalOutcome = useMemo(() => {
    return Math.floor(_.sumBy(viewExpenses, "amount") * 100) / 100;
    // eslint-disable-next-line
  }, [viewInvoices]);

  const totalCost = useMemo(() => {
    if (currentSalary?.status !== SalaryStatus.done) {
      const totalSelectedInvoices = _(viewInvoices)
        .filter((invoice) => invoice.include_in_salary)
        .map((invoice) => +invoice.amount_with_vat)
        .sum();
      const totalSelectedExpenses = _(viewExpenses).filter((expense) => expense.include_in_salary).sumBy("amount");

      return Math.floor(( totalSelectedInvoices - totalSelectedExpenses ) * 100) / 100;
    } else {
      return currentSalary.agent_total_cost;
    }
  }, [viewInvoices, viewExpenses]);

  const totalFeeCost = useMemo(() => {
    if (currentSalary?.status !== SalaryStatus.done) {
      const userFee = Math.floor(totalCost * employee.userTerms.yeuls_fee) / 100;

      return userFee > employee.userTerms.max_yeuls_fee ? employee.userTerms.max_yeuls_fee : userFee;
    }

    return currentSalary.agent_total_fee_from_salary
    // eslint-disable-next-line
  }, [viewInvoices, viewExpenses, totalCost]);

  const submitValidate = () => {
    const salaryToUpdate = {
      ...salary,
      status: SalaryStatus.done,
      cost: totalCost,
      cost_fee: totalFeeCost
    };

    updateEmployeeSalary(employee, salaryToUpdate, Object.values(invoiceUpdates), Object.values(expenseUpdates))
      .then(() => {
        setDialogConfig(null);
      });
  }

  const submitSplitIncome = () => {}

  const submitSplitOutcome = () => {}

  const submitAddIncome = () => {}

  const getDialogContent = (type) => {
    if (type === DialogType.validateAndApprove)
      return <div>
        <p>
          Make sure you check each employee and complete all fields. After clicking the "Confirm" button, you will not
          be able to modify the reports.
        </p>
        <p>
          If you made a mistake or forget to fill some information please contacts with administrator to resolve this
          issue.
        </p>
      </div>;

    if (type === DialogType.addIncome)
      return <div className="employee-salary-dialog-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <YeulsInputComponent select value={Currency.nis} tLabel={t("currency")}>
              <MenuItem key={Currency.nis} value={Currency.nis}>{t(Currency.nis)}</MenuItem>
              <MenuItem key={Currency.usd} value={Currency.usd}>{t(Currency.usd)}</MenuItem>
              <MenuItem key={Currency.eur} value={Currency.eur}>{t(Currency.eur)}</MenuItem>
            </YeulsInputComponent>
          </Grid>
          <Grid item xs={6}>
            <YeulsInputComponent value={""} tLabel={t("amount")}/>
          </Grid>
        </Grid>
      </div>;

    if (type === DialogType.splitIncome)
      return <div className="employee-salary-dialog-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YeulsInputComponent value={""} tLabel={t("amount")}/>
          </Grid>
        </Grid>
      </div>;

    if (type === DialogType.splitOutcome)
      return <div className="employee-salary-dialog-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YeulsInputComponent value={""} tLabel={t("amount")}/>
          </Grid>
        </Grid>
      </div>;
  }

  return <div className="company-salary-wrapper">
    <div className="income-salary-table-wrapper">
      <div className="company-salary-table-header">
        <div className="company-salary-table-header-title">
          <span>{t('total-income-with-colon')}</span> ₪ {
          currentSalary?.status !== SalaryStatus.done ?
            NumbersService.numberWithCommas(totalIncome, true) :
            NumbersService.numberWithCommas(currentSalary.agent_total_incomes, true)}
        </div>
        <div className="company-salary-table-header-button-wrapper">
          <YeulsButton
            disabled={salary?.status === SalaryStatus.done}
            variant="outlined"
            onClick={() => {setShowNotPaidIncomes(!showNotPaidIncomes)}}>{
            showNotPaidIncomes ? t('hide-not-paid-income') : t('show-not-paid-income')}</YeulsButton>
          <YeulsButton
            variant="outlined"
            disabled={salary?.status === SalaryStatus.done}
            onClick={() => setDialogConfig({
              type: DialogType.splitIncome,
              titleKey: 'split-income',
              submit: submitSplitIncome
            })}>{t('split-income')}</YeulsButton>
          <YeulsButton
            disabled={salary?.status === SalaryStatus.done}
            onClick={() => setDialogConfig({
              type: DialogType.addIncome,
              titleKey: 'add-income',
              submit: submitAddIncome
            })}>{t('add-income')}</YeulsButton>
        </div>
      </div>
      {salary?.status === SalaryStatus.done &&<SalaryDoneIncomeTable
        viewInvoices={viewInvoices}
        onHeaderCheckboxClick={onIncomeCheckboxClick}/>}
      {salary?.status !== SalaryStatus.done &&<IncomeTable
        viewInvoices={viewInvoices}
        onHeaderCheckboxClick={onIncomeCheckboxClick}/>}
    </div>
    <Divider className="salary-table-divider"/>
    <div className="outcome-salary-table-wrapper">
      <div className="company-salary-table-header">
        <div className="company-salary-table-header-title">
          <span>{t('total-outcome-with-colon')}</span> ₪ {currentSalary?.status !== SalaryStatus.done ?
          NumbersService.numberWithCommas(totalOutcome, true) :
          NumbersService.numberWithCommas(currentSalary.agent_total_expenses, true)}
        </div>
        <div className="company-salary-table-header-button-wrapper">
          <YeulsButton
            disabled={salary?.status === SalaryStatus.done}
            variant="outlined"
            onClick={() => {setShowNotPaidOutcomes(!showNotPaidOutcomes)}}>{
            showNotPaidOutcomes ? t('hide-not-paid-outcome') : t('show-not-paid-outcome')}</YeulsButton>
          <YeulsButton
            variant="outlined"
            disabled={salary?.status === SalaryStatus.done}
            onClick={() => setDialogConfig({
              type: DialogType.splitOutcome,
              titleKey: 'split-outcome',
              submit: submitSplitOutcome
            })}>{t('split-outcome')}</YeulsButton>
        </div>
      </div>
      {salary?.status === SalaryStatus.done && <SalaryDoneOutcomeTable
        viewExpenses={viewExpenses}
        onHeaderCheckboxClick={onOutcomeCheckboxClick}/>}
      {salary?.status !== SalaryStatus.done && <OutcomeTable
        viewExpenses={viewExpenses}
        onHeaderCheckboxClick={onOutcomeCheckboxClick}/>
      }
    </div>
    <EmployeeSalaryFooter totalCost={totalCost} totalFeeCost={totalFeeCost} feePercent={employee.userTerms.yeuls_fee}
                          currentSalary={currentSalary}/>
    <div className="company-salary-footer">
      <YeulsButton variant="negative">{t('reset-salary')}</YeulsButton>
      <YeulsButton
        disabled={salary?.status === SalaryStatus.done}
        onClick={() => setDialogConfig({
          type: DialogType.validateAndApprove,
          titleKey: 'save-salary',
          submit: submitValidate
        })}>{t('save-salary')}</YeulsButton>
    </div>

    <ConfirmDialog
      open={!!dialogConfig}
      onClose={() => setDialogConfig(null)}
      title={t(dialogConfig?.titleKey)}
      onSubmit={dialogConfig?.submit}
    >
      {getDialogContent(dialogConfig?.type)}
    </ConfirmDialog>
  </div>
}

export default EmployeeSalary;