import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getMissedInvoices = async (data) => {
  return axios({
    method: 'get',
    url: endpoints.Invoice.MISSED_INVOICE,
    baseURL: baseUrl,
    data: data,
  });
};

export const getAllInvoices = async () => {
  return axios({
    method: 'get',
    url: endpoints.Invoice.INVOICE,
    baseURL: baseUrl
  })
    .then((response) => response.data.result);
};

export const getUserInvoices = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Invoice.USER_INVOICE}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const updateInvoice = (data) => {
  return axios({
    method: 'put',
    url: endpoints.Invoice.INVOICE_UPDATE,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
}