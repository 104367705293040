import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../languages/i18n';
import './Auth.scss';

import Text from "../../containers/Text";

const SignForm = ({ config = {} }) => {

    const { t } = useTranslation();

    return (
        <div className="content-wrapper">
                <div className={"input-wrapper"}>
                    <span className="identity-number">
                      <Text text={t('identity-number')} />
                    </span>
                    <input
                        type="text"
                        maxLength="9"
                        onChange={(e) => config.setIdentityNumber(e.target.value)}
                    />
                </div>
                <div className={"input-wrapper margin-top--20"}>
                      <span className="email">
                        <Text text={t('e-mail')} />
                      </span>
                    <input
                        type="email"
                        name="email"
                        onChange={(e) => config.setEmail(e.target.value)}
                    />
                </div>
                {config.error.active && (
                    <span className="error">
                    <Text text={config.error.message} />
                  </span>
                )}
                <input
                    className="margin-top--50"
                    type="submit"
                    value={t('get-one-time-password')}
                />
        </div>
    );
};

export default SignForm;