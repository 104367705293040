import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AuthService } from '../../services/auth';
import * as _ from 'lodash';
import Spinner from '../spinner';
import { AiFillEyeInvisible, AiFillEye, AiOutlineEye } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Text from '../../containers/Text';
import './UserPage.scss';
import { AgentDocumentType, constants } from '../../api/constants';
import YeulsTabs from '../yeuls-tabs';
import { useMemo } from 'react';
import { getUserAccountDocuments, getUserData } from '../../api/requests/users';
import { Button, Grid } from '@material-ui/core';
import { IoIosAttach } from 'react-icons/io';
import { MoreInfo } from './more-info';

const tabIds = {
  general: 0,
  accountDocument: 1,
  moreInfo: 2,
};

const UserFile = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="user-data">
      <Text text={t(props?.label)} />
      <div className="file">
        <div
          className={i18n.language !== 'heb' ? 'filename' : 'filename-revert'}
        >
          <IoIosAttach />
          {props?.file?.fileName}
        </div>
        <div>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              window.open(props?.file?.file, '_blank', 'noreferrer');
            }}
            style={{
              backgroundColor: 'none',
              color: '#003164',
            }}
            startIcon={<AiOutlineEye />}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default function UserPage({
  user,
  setUserInfo,
  setMonthlyOverview,
  readOnly = false,
}) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [userData, setUserData] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordShowing, setPasswordShowing] = useState(false);
  const [currentTabId, setCurrentTabId] = useState(tabIds.general);
  const [userDocuments, setUserDocuments] = useState([]);
  const [contractFile, setContractFile] = useState(null);
  const [idScanFile, setIdScanFile] = useState(null);
  const [taxFormFile, setTaxFormFile] = useState(null);

  const tabsConfig = useMemo(
    () => [
      {
        id: tabIds.general,
        label: 'general',
        defaultSelectedTab: currentTabId === tabIds.general,
      },
      {
        id: tabIds.accountDocument,
        label: 'account-documents',
        itemsCount: `${userDocuments?.length}/3`,
        defaultSelectedTab: currentTabId === tabIds.accountDocument,
      },
      {
        id: tabIds.moreInfo,
        label: 'more-info',
        defaultSelectedTab: currentTabId === tabIds.moreInfo,
      },
    ],
    [userDocuments]
  );

  useEffect(() => {
    if (+user.id !== +id && !readOnly) {
      history.push('/');
      setIsLoading(false);
      return;
    } else {
      getUserData(user.id)
        .then((res) => {
          const data = {
            ...res,
            password: '',
          };
          setUserData(data);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [user, id, history]);

  const updateUserData = (value, field) => {
    setUserData({ ...userData, [field]: value });
  };

  const updateUser = async () => {
    setIsLoading(true);

    if (_.isEmpty(userData.email)) {
      setIsLoading(false);
      return;
    }

    const accessToken = localStorage.getItem('access_token');
    await fetch(`${constants.baseURL}/user/update`, {
      method: 'PUT',
      body: JSON.stringify(userData),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${accessToken}`,
      },
    });

    const updatedTokenResponse = await fetch(
      `${constants.baseURL}/auth/refresh`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${accessToken}`,
        },
      }
    );

    const parsedUpdatedTokenResult = await updatedTokenResponse.json();
    AuthService.setToken(parsedUpdatedTokenResult.result);
    const decodedToken = AuthService.getTokenData();
    setUserInfo({ ...decodedToken });
    setIsLoading(false);
  };

  useEffect(() => {
    if (user?.id && userDocuments.length === 0) {
      getUserAccountDocuments(user?.id).then((res) => {
        setUserDocuments(res);
        const contract = res.find(
          (doc) => doc?.fileType === AgentDocumentType.CONTRACT
        );
        const idScan = res.find(
          (doc) => doc?.fileType === AgentDocumentType.ID_SCAN
        );
        const taxForm = res.find(
          (doc) => doc?.fileType === AgentDocumentType.TAX_FORM
        );
        setContractFile(contract ? contract : null);
        setIdScanFile(idScan ? idScan : null);
        setTaxFormFile(taxForm ? taxForm : null);
      });
    }
  }, [user?.id]);

  const signOut = () => {
    AuthService.deleteToken();
    setUserInfo({ id: null });
    setMonthlyOverview(null);
    history.push('/');
  };

  return (
    <div className="user-page-container">
      <div className="user-content-row">
        {!readOnly && (
          <p className="user-page-title">
            <Text text={t('profile-info')} />
          </p>
        )}

        <div className="employees-tabs-wrapper">
          <YeulsTabs
            config={tabsConfig}
            onChange={(value) => {
              setCurrentTabId(value);
            }}
          />
          {currentTabId === tabIds.general && (
            <>
              {isLoading && (
                <div className="user-page-container">
                  <Spinner />
                </div>
              )}

              {!isLoading && (
                <div className="user-info">
                  <div className="user-page-content">
                    <div className="user-data">
                      <Text text={t('name')} />
                      <input
                        value={userData.firstname}
                        type="text"
                        autoComplete="off"
                        disabled={readOnly}
                        onChange={(e) =>
                          updateUserData(e.target.value, 'firstname')
                        }
                      />
                    </div>

                    <div className="user-data">
                      <Text text={t('e-mail')} />
                      <input
                        value={userData.email}
                        type="text"
                        autoComplete="off"
                        disabled={readOnly}
                        onChange={(e) =>
                          updateUserData(e.target.value, 'email')
                        }
                      />
                    </div>

                    <div className="user-data">
                      <Text text={t('phone')} />
                      <input
                        value={userData.phone}
                        type="text"
                        autoComplete="off"
                        disabled={readOnly}
                        onChange={(e) =>
                          updateUserData(e.target.value, 'phone')
                        }
                      />
                    </div>

                    <div className="user-data">
                      <Text text={t('job')} />
                      <input
                        value={userData.job_title}
                        type="text"
                        autoComplete="off"
                        disabled={readOnly}
                        onChange={(e) =>
                          updateUserData(e.target.value, 'job_title')
                        }
                      />
                    </div>
                  </div>
                  {!readOnly && (
                    <div className="user-password">
                      <div className="user-data">
                        <Text text={t('password')} />
                        <div className="input-password">
                          <input
                            value={userData.password}
                            type={isPasswordShowing ? 'text' : 'password'}
                            onChange={(e) =>
                              updateUserData(e.target.value, 'password')
                            }
                          />
                          <div
                            onClick={() =>
                              setPasswordShowing(!isPasswordShowing)
                            }
                          >
                            {isPasswordShowing ? (
                              <AiFillEye className="eye" />
                            ) : (
                              <AiFillEyeInvisible className="eye" />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="user-data button">
                        <button
                          onClick={updateUser}
                          className="update-details-button"
                        >
                          <Text
                            text={t('update-details')}
                            className="update-button"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {!isLoading && !readOnly && (
                <div className="sign-out-button-container">
                  <button onClick={signOut} className="sign-out">
                    <Text text={t('sign-out')} />
                  </button>
                </div>
              )}
            </>
          )}
          {currentTabId === tabIds.accountDocument && (
            <>
              <div className="user-info">
                <Grid container spacing={3}>
                  <Grid item xs={4} className="file-wrapper">
                    <div className="user-page-content">
                      {contractFile && (
                        <UserFile label="contract" file={contractFile} />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4} className="file-wrapper">
                    <div className="user-page-content">
                      {idScanFile && (
                        <UserFile label="id-scan" file={idScanFile} />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4} className="file-wrapper">
                    <div className="user-page-content">
                      {taxFormFile && (
                        <UserFile label="tax-form" file={taxFormFile} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          {currentTabId === tabIds.moreInfo && (
            <MoreInfo user={userData} />
          )}
        </div>
      </div>
    </div>
  );
}
