import React, { useState } from 'react';
import { ClickAwayListener, TextField } from "@material-ui/core";

import './EditableLineInput.scss';

const EditableLineInput = (props) => {
  const [value, setValue] = useState(props.value);
  const [editMode, setEditMode] = useState(props.autoFocus);

  const handleEditMode = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditMode(true);
    if (props.handleChange) {
      props.handleChange(value);
    }
  };

  // const close = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   setEditMode(false);
  // };

  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(e.target.value);
    if (props.handleChange) {
      props.handleChange(e.target.value);
    }
  };

  const handleSave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditMode(false);
    props.onSave(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave(e);
    }
  };

  const handleClickAway = () => {
    setEditMode(false);
    setValue(props.value || '');
    if (props.handleChange) {
      props.handleChange(props.value || '');
    }

    props.onSave(value);
  };

  return <div className="editable-input-wrapper" onClick={handleEditMode}>
      {editMode ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <TextField
              color="primary"
              InputProps={{ disableUnderline: true }}
              onChange={handleChange}
              className="editable-input-field"
              onKeyPress={handleKeyPress}
              onFocus={props.onFocus || ( () => {} )}
              onBlur={props.onBlur || ( () => {} )}
              autoFocus={true}
              type={props.type || "text"}
              error={props.error}
              value={value}
            />
          </div>
        </ClickAwayListener>
      ) : (
        props.children
      )}
    </div>
}

export default EditableLineInput;