import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getAllCompanies = async (accountsView = false) => {
  return axios({
    method: 'get',
    url: `${endpoints.Companies.COMPANY}?accounts_view=${accountsView}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const createCompany = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Companies.COMPANY,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateCompany = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.Companies.COMPANY,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const createCompanyUser = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Companies.USER_COMPANY,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateCompanyUser = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.Companies.USER_COMPANY,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const deleteCompanyUser = async (id) => {
  return axios({
    method: 'delete',
    url: `${endpoints.Companies.USER_COMPANY}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getUserCompanies = async (includeReports=false) => {
  return axios({
    method: 'get',
    url: `${endpoints.Companies.USER_COMPANY}?includeReports=${includeReports}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getCompanyById = async (companyId) => {
  return axios({
    method: 'get',
    url: `${endpoints.Companies.COMPANY}/${companyId}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getCompanyAccountDocuments = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Companies.COMPANY_DOCUMENTS}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};