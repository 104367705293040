import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const uploadMonthlyFiles = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Salary.MONTHLY_FILES,
    baseURL: baseUrl,
    data: data,
  });
};

export const uploadOneZeroSixFiles = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Salary.ONE_ZERO_SIX_FILES,
    baseURL: baseUrl,
    data: data,
  });
};


export const uploadSalariesFiles = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Salary.SALARY_FILES,
    baseURL: baseUrl,
    data: data,
  });
};

export const uploadInvoiceReceiptsFiles = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.InvoiceReceipts.INVOICE_RECEIPT_FILES,
    baseURL: baseUrl,
    data: data,
  });
};
