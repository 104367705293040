import React from 'react';
import { useTranslation } from 'react-i18next';

import UserProfileIcon from '../../shared/icons/user-profile-icon.svg';

import Text from '../../containers/Text';
import './HomePage.scss';

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-us">
      <p className="title">
        <Text text={t('contact-us-title')} />
      </p>
      <div className="contact-info">
        <div>
          <img
            className="info-icon us us"
            src={UserProfileIcon}
            alt="profile"
          />
          <div>
            <Text text={t('general-manager')} />
            <a
              href="mailto:rodan@yeuls.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={t('rodan@yeuls.co.il')} />
            </a>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div>
          <img className="info-icon us" src={UserProfileIcon} alt="profile" />
          <div>
            <Text text={t('office-manager')} />
            <a
              href="mailto:rima@yeuls.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={t('rima@yeuls.co.il')} />
            </a>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div>
          <img className="info-icon us" src={UserProfileIcon} alt="profile" />
          <div>
            <Text text={t('payroll-accounting')} />
            <a
              href="mailto:salary@yeuls.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={t('salary@yeuls.co.il')} />
            </a>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div>
          <img
            className="info-icon us"
            src={UserProfileIcon}
            alt="profile"
            rel="noopener noreferrer"
          />
          <div>
            <Text text={t('billing')} />
            <a
              href="mailto:billing@yeuls.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={t('billing@yeuls.co.il')} />
            </a>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div>
          <img className="info-icon us" src={UserProfileIcon} alt="profile" />
          <div>
            <Text text={t('accounting-manager')} />
            <a
              href="mailto:Inbal@yeuls.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={t(`Inbal@yeuls.co.il`)} />
            </a>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div>
          <img className="info-icon us" src={UserProfileIcon} alt="profile" />
          <div>
            <Text text={t('savings-pension')} />
            <a
              href="mailto:rivka@yeuls.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={t('rivka@yeuls.co.il')} />
            </a>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div>
          <img className="info-icon us" src={UserProfileIcon} alt="profile" />
          <div>
            <Text text={t('operations-manager')} />
            <a
              href="mailto:info@yeuls.co.il"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text text={t('info@yeuls.co.il')} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
