export const getUserNameView = (user, t) => {
  let result = `${t('name')}: ${user.firstname}`;

  if (user.lastname) result += ` ${user.lastname}`;

  if (user.santec_account_id)
    result += `, ${t('client-number')}: ${user.santec_account_id}`;

  if (user.business_number)
    result += `, ${t('business-number')}: ${user.business_number}`;

  return result;
};

export const getCompanyNameView = (company, t) => {
  return getEntityNameView(company, t, 'company_name');
};

export const getClientNameView = (client, t) => {
  return getEntityNameView(client, t, 'client_name');
};

export const getSupplierNameView = (supplier, t) => {
  return getEntityNameView(supplier, t, 'supplier_name');
};

const getEntityNameView = (entity, t, nameKey) => {
  let result = `${t('name')}: ${entity[nameKey]}`;

  if (entity.santec_account_id)
    result += `, ${t('client-number')}: ${entity.santec_account_id}`;

  if (entity.business_number)
    result += `, ${t('business-number')}: ${entity.business_number}`;

  return result;
};

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    formData.append(parentKey, data);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const enumToOptions = (enums, labelText = "") =>
	Object.entries(enums).map(([value, label]) => ({
		value: label,
		label: `${labelText}${label.toLowerCase()}`,
	}));
