import React from "react";

import "./DetailComponent.scss";

const DetailComponent = (props)=>{
  const {label, value} = props;

  return <div className="details-wrapper">
    <div className="details-label">{label}</div>
    <div className="details-value">{value || "—"}</div>
  </div>
}

export default DetailComponent;