import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid, MenuItem, Switch } from "@material-ui/core";

import "./GeneralClientForm.scss";
import InputComponent from "../../input-component";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Currency } from "../../../../../shared/constants";

const GeneralClientForm = (props) => {
  const { generalFormData, setGeneralFormData } = props;
  const { t } = useTranslation();

  return <Grid spacing={3} container>
    <Grid item xs={6}>
      <InputComponent field="client_name" tLabel="client-name" formData={generalFormData}
                      setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="business_number" tLabel="business-number" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="email" tLabel="email" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="phone" tLabel="phone" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Divider className="general-client-divider"/>
    <Grid item xs={6}>
      <InputComponent field="country" tLabel="country" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="city" tLabel="city" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="address" tLabel="address" formData={generalFormData}
                      setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="zip" tLabel="zip" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Divider className="general-client-divider"/>
    <Grid item xs={6}>
      <InputComponent type="number" field="withholding_tax" tLabel="withholding-tax" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6}>
      <div className="input-component-item">
        <label className="input-component-item-label">{t("withholding-tax-expiration")}</label>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.yyyy"
          value={generalFormData.withholding_tax_expiration.value}
          className="client-withholding-tax-date-picker"
          InputAdornmentProps={{ position: 'end', variant: 'standard' }}
          onChange={(newValue) => {
            setGeneralFormData({ ...generalFormData, withholding_tax_expiration: { value: newValue?.toDate(), error: false } })
          }}
        />
      </div>
    </Grid>
    <Grid item xs={6}>
      <div className="input-component-item">
        <label className="input-component-item-label">{t("general-ledger")}</label>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="DD.MM.yyyy"
          value={generalFormData.general_ledger.value}
          className="client-withholding-tax-date-picker"
          InputAdornmentProps={{ position: 'end', variant: 'standard' }}
          onChange={(newValue) => {
            setGeneralFormData({ ...generalFormData, general_ledger: { value: newValue?.toDate(), error: false } })
          }}
        />
      </div>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="created_by" tLabel="created-by" formData={generalFormData} setFormData={setGeneralFormData}/>
    </Grid>
    <Grid item xs={6} className="employees-switch-wrapper">
      <div className="employees-form-item">
        <div className="employees-form-item-label">{t("included-vat")}</div>
        <Switch
          classes={{ root: 'switch-root', track: 'switch-track', switchBase: 'switch-base' }}
          checked={generalFormData.included_vat.value}
          onChange={(e) => setGeneralFormData({
            ...generalFormData,
            included_vat: { value: e.target.checked }
          })}/>
      </div>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="currency" tLabel="currency" formData={generalFormData} setFormData={setGeneralFormData} select>
        <MenuItem key={Currency.nis} value={Currency.nis}>{t(Currency.nis)}</MenuItem>
        <MenuItem key={Currency.usd} value={Currency.usd}>{t(Currency.usd)}</MenuItem>
        <MenuItem key={Currency.eur} value={Currency.eur}>{t(Currency.eur)}</MenuItem>
      </InputComponent>
    </Grid>
  </Grid>
}

export default GeneralClientForm;