import { connect } from 'react-redux';
import SalaryTable from '../components/salary-table';

export function mapStateToProps({ languageState }) {
  return {
    language: languageState,
  };
}

export default connect(mapStateToProps)(SalaryTable);
