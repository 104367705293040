import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../languages/i18n';
import './Auth.scss';

import Text from "../../containers/Text";

const OTPGetCode = ({ config = {} }) => {

    const { t } = useTranslation();

    const handleInput = (e) => {
        config.setCode(e.target.value)
        !!config.error.active && config.setError({active: false, message: ""})
    }

    return (
        <div className="content-wrapper">
            <div className="input-wrapper">
                  <span className="email">
                    <Text text={t('code')} />
                  </span>
                <input
                    type="text"
                    name="code"
                    value={config.code}
                    onChange={handleInput}
                />
            </div>
            {config.error.active && (
                <span className="error">
                    <Text text={config.error.message} />
                </span>
            )}
            <input
                type="submit"
                value={t('get-code')}
            />
            <div className="resend-code-wrapper">
                <p className="contact-us ">
                    <Text text={t('got-password')} />
                </p>
                <p onClick={config.action} className="resend-password">
                    <Text text={t("send-password-again")} />
                </p>
            </div>
        </div>
    );
};

export default OTPGetCode;