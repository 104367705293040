import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import i18n from 'i18next';
import { connect } from 'react-redux';

import history from './history';
import { ActivePageService } from './services/active-page';
import Menu from './containers/Menu';
import UserPage from './containers/User';
import MonthlyOverview from './containers/MonthlyOverview';
import Auth from './containers/Auth';
import Header from './containers/Header';

import HomePage from './components/home-page';
import AdminPanel from './components/admin-panel';
import FilesUpload from './components/files-upload';
import MissedFiles from './components/missed';
import Accounts from './components/accounts';
import MonthlyReport from './components/monthly-report';
import Salaries from './components/salaries';

import './App.scss';
import { Routes } from "./api/routes";
import { checkIsManager } from './api/requests/users';
import Worker from './containers/Worker';

export function mapStateToProps({ userState }) {
  return {
    user: userState.user,
  };
}

const App = (props) => {
  const { user } = props;
  const [currentLng, setCurrentLng] = useState(i18n.language);
  const [menuClassName, setMenuClassName] = useState('nav-bar');
  const [refreshingContent, setRefreshingContent] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [activePage, setActivePage] = useState(
    ActivePageService.getActivePageUrl(history.location.pathname)
  );

  useEffect(() => {
    if (refreshingContent)
      setRefreshingContent(false);
  }, [refreshingContent]);

  const handleNavBar = () => {
    if (menuClassName === 'nav-bar') {
      setMenuClassName('nav-bar close');
      setTimeout(() => setMenuClassName('nav-bar disappear'), 1000);
      return;
    }
    setMenuClassName('nav-bar close');
    setTimeout(() => setMenuClassName('nav-bar'), 0);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLng(lng);
  };

  useEffect(() => {
    if (user && user.id && user.role !== 'admin') {
      checkIsManager().then((result) => {
        setIsManager(result);
      });
    }
  }, [user]);

  return (
    <Router history={history}>
      <main className={`${currentLng === 'heb' ? 'rotate' : ''}`}>
        {user.id && (
          <Menu
            user={user}
            menuClassName={menuClassName}
            setMenuClassName={setMenuClassName}
            handleDrawer={handleNavBar}
            currentLng={currentLng}
            changeLanguage={changeLanguage}
            activePage={activePage}
            isManager={isManager}
            refreshContent={() => setRefreshingContent(true)}
          />
        )}
        {!refreshingContent &&
        <div className="content">
          <Header
            user={user}
            currentLng={currentLng}
            changeLanguage={changeLanguage}
            handleDrawer={handleNavBar}
            setActivePage={setActivePage}
          />
          <Switch>
            <Route
              exact
              path={Routes.main}
              render={() => {
                return user.id ? (
                  <HomePage user={user} handleDrawer={handleNavBar}/>
                ) : <Redirect to={Routes.signIn}/>
              }}
            />
            <Route
              exact
              path={Routes.signIn}
              render={() => !user.id ? <Auth currentLng={currentLng} changeLanguage={changeLanguage}/> :
                <Redirect to={Routes.main}/>}
            />
            <Route
              exact
              path={Routes.admin}
              render={() => !user.id ? <Auth currentLng={currentLng} changeLanguage={changeLanguage}/> :
                <Redirect to={Routes.main}/>}
            />
            <Route
              path={Routes.oneTimePassword}
              render={() => {
                return !user.id ? <Auth currentLng={currentLng} changeLanguage={changeLanguage}/> :
                  <Redirect to={Routes.main}/>;
              }}
            />
            <Route
              exact
              path="/monthly-overview"
              render={() => {
                return user.id ? <MonthlyOverview user={user}/> : null;
              }}
            />
            <Route
              exact
              path="/workers"
              render={() => {
                return isManager ? <Worker user={user}/> : null;
              }}
            />
            <Route
              exact
              path="/admin-panel"
              render={() => {
                return user.id && user.role === 'admin' ? (
                  <AdminPanel user={user}/>
                ) : null;
              }}
            />
            <Route
              exact
              path={Routes.accounts}
              render={() => {
                return user.id && user.role === 'admin' ? (
                  <Accounts user={user}/>
                ) : null;
              }}
            />
            <Route
              exact
              path={Routes.monthlyReport}
              render={() => <MonthlyReport user={user}/>}
            />
            <Route
              exact
              path={Routes.salaries}
              render={() => <Salaries user={user}/>}
            />
            <Route
              exact
              path="/files-upload"
              render={() => {
                return user.id && user.role === 'admin' ? (
                  <FilesUpload/>
                ) : null;
              }}
            />
            <Route
              exact
              path="/missed"
              render={() => {
                return user.id && user.role === 'admin' ? (
                  <MissedFiles/>
                ) : null;
              }}
            />
            <Route
              exact
              path="/user/:id"
              render={() => {
                return user.id ? <UserPage/> : null;
              }}
            />
            <Route render={() => <Redirect to="/"/>}/>
          </Switch>
        </div>}
      </main>
    </Router>
  );
};

export default connect(mapStateToProps)(App);
