import * as _ from "lodash";
import * as moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";

import "./Salaries.scss";
import Loader from "../loder";
import YeulsTabs from "../yeuls-tabs";
import DateCarousel from "../date-carousel";
import YeulsTable from "../yeuls-table";
import EmployeeSalary from "./employee-salary";
import { getUsers } from "../../api/requests/users";
import { SalaryDate, SalaryStatus } from "../../shared/constants";
import Search from "../search";
import { ReactComponent as ArrowBackIcon } from "../../shared/icons/arrow-back-icon.svg";
import { addSalary, getAllSalaries, getAllSalaryRecords, importSalary, updateSalary } from "../../api/requests/salary";
import { Checkbox } from "@material-ui/core";
import EditableLineInput from "../editable-line-input";
import YeulsButton from "../yeuls-button";
import { getAllInvoices } from "../../api/requests/invoice";
import { getAllReceipts } from "../../api/requests/receipt";
import { getAllExpenses } from "../../api/requests/expense";
import { NumbersService } from "../../services/numbers";
import ConfirmDialog from "../confirm-dialog";
import DownloadInvoiceIcon from "../../shared/icons/download-invoice-icon.svg";
import GreyDownloadInvoiceIcon from "../../shared/icons/grey-download-invoice-icon.svg";
import { getClients } from "../../api/requests/client";
import { getSuppliers } from "../../api/requests/supplier";
import { mergeSalaries } from "../../api/requests/yeuls";


const tabIds = {
  first: 0,
  ninth: 1,
}

const Salaries = (props) => {
  const { t, i18n } = useTranslation();
  const [showLoader, setShowLoader] = useState(false)
  const tableHeaders = [t("name"), t("agent-id"), t("total-income"), t("status"), t("total-cost"), t("oketz-cost"), t("oketz-net"), t("oketz-net-to-pay"), t("notes"), t("paid"), t("monthly-link-table-header"), t("salary-link-table-header")];
  const tableColumns = "nameView.santec_account_id.totalIncomeView.statusView.costView.totalCostView.totalNetView.totalNetToPayView.notesView.paidView.monthlyOverviewLinkView.salaryLinkView";
  const [currentTabId, setCurrentTabId] = useState(tabIds.first);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [updateTable, setUpdateTable] = useState(true);
  const [importData, setImportData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const csvLink = useRef();
  const [clients, setClients] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [showMergeSalariesConfirmDialog, setShowMergeSalariesConfirmDialog] = useState(false);

  const csvHeaders = [
    { label: 'מספר עובד', key: "oketz_number" },
    // { label: t('identity-number'), key: "identity_number" },
    // { label: 'נטו לתשלום', key: "total_oketz_net" },
    // { label: 'נטו', key: "total_oketz_net_to_pay" },
    { label: 'שכר יסוד-ערך', key: "total_oketz_cost" },
  ];
  const [tabsConfig, setTabsConfig] = useState([
    { id: tabIds.first, label: "1st", itemsCount: 0, defaultSelectedTab: true },
    { id: tabIds.ninth, label: "9st", itemsCount: 0 }]);
  const [employees, setEmployees] = useState([]);
  const [groupedEmployees, setGroupedEmployees] = useState({
    firstDateSalaryEmployees: [],
    ninthDateSalaryEmployees: [],
  });

  const [filteredEmployees, setFilteredEmployees] = useState({
    firstDateSalaryEmployees: [],
    ninthDateSalaryEmployees: [],
  });
  const [filterValue, setFilterValue] = useState("");
  const [currentMonth, setCurrentMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });

  const [importMonth, setImportMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });

  useEffect(() => {
    refreshEmployees();
  }, []);

  const refreshEmployees = () => {
    setShowLoader(true);
    Promise.all([getUsers(), getAllInvoices(), getAllReceipts(), getAllExpenses(), getAllSalaries(), getAllSalaryRecords(), getClients(), getSuppliers()])
      .then(([users, invoices, receipts, expenses, salaries, salaryRecords, clients, suppliers]) => {
        setEmployees(_(users)
          .filter((user) => user.userTerms)
          .map((user) => ( {
            ...user,
            invoices: _.filter(invoices, { user_id: user.id }),
            receipts: _.filter(receipts, { user_id: user.id }),
            expenses: _.filter(expenses, { user_id: user.id }),
            salaries: _.filter(salaries, { user_id: user.id }),
            salaryRecords: _.filter(salaryRecords, { user_id: user.id }),
          } ))
          .value()
        );
        setSuppliers(suppliers);
        setClients(clients);
      })
      .finally(() => setShowLoader(false));
  }

  useEffect(() => {
    const calculatedEmployees = _.map(employees, (employee) => {
      const currentMonthInvoices = _.filter(employee.invoices, (invoice) => {
        const invoiceCreateDate = new Date(invoice.created_on);

        return invoiceCreateDate.getMonth() + 1 === currentMonth.month && invoiceCreateDate.getFullYear() === currentMonth.year;
      });
      const totalIncome = Math.floor(_(currentMonthInvoices).map(i => +i.amount_with_vat).sum() * 100) / 100;
      const currentSalary = _.find(employee.salaries, (salary) =>
        salary.year === currentMonth.year &&
        salary.month === currentMonth.month);

      return {
        ...employee,
        totalIncome,
        agentTotalIncomes : currentSalary?.agent_total_incomes || 0,
        nameView: <div className="clickable-block"
                       onClick={() => setSelectedEmployee(employee)}>{employee.firstname}</div>,
        // totalIncomeView: totalIncome ? `₪ ${NumbersService.numberWithCommas(totalIncome)}` : "0",
        totalIncomeView: currentSalary?.status === SalaryStatus.done ?
          <div>₪{` ${NumbersService.numberWithCommas(currentSalary.agent_total_incomes, true)}`}</div> : "",

        costView: currentSalary?.status === SalaryStatus.done ?
          <div>₪{` ${NumbersService.numberWithCommas(currentSalary.agent_total_paid, true)}`}</div> : "",
        totalNetView: <EditableLineInput
          value={currentSalary?.total_oketz_net}
          type="number"
          onSave={(oketzNet) => updateEmployeeSalary(employee, { total_oketz_net: +oketzNet })}>{currentSalary?.total_oketz_net ? `₪ ${NumbersService.numberWithCommas(currentSalary?.total_oketz_net, true)}` : ""}</EditableLineInput>,
        totalNetToPayView: <EditableLineInput
          value={currentSalary?.total_oketz_net_to_pay}
          type="number"
          onSave={(oketzNetToPay) => updateEmployeeSalary(employee, { total_oketz_net_to_pay: +oketzNetToPay })}>{currentSalary?.total_oketz_net_to_pay ? `₪ ${NumbersService.numberWithCommas(currentSalary?.total_oketz_net_to_pay, true)}` : ""}</EditableLineInput>,
        totalCostView: <EditableLineInput
          value={currentSalary?.total_oketz_cost}
          type="number"
          onSave={(oketzCost) => updateEmployeeSalary(employee, { total_oketz_cost: +oketzCost })}>{currentSalary?.total_oketz_cost ? `₪ ${NumbersService.numberWithCommas(currentSalary?.total_oketz_cost, true)}` : ""}</EditableLineInput>,

        statusView: <div
          className={"companies-salary-status" + ( currentSalary?.status === SalaryStatus.done ? " done" : "" )}>
          {currentSalary?.status === SalaryStatus.done ? t("done") : t("open")}
        </div>,
        notesView: <EditableLineInput
          value={currentSalary?.notes}
          onSave={(notes) => updateEmployeeSalary(employee, { notes })}>{currentSalary?.notes}</EditableLineInput>,
        paidView: <Checkbox
          classes={{ checked: 'table-checkbox-checked', root: 'table-checkbox-root' }}
          checked={!!currentSalary?.paid}
          onChange={(e) => updateEmployeeSalary(employee, { paid: e.target.checked })}
        />,
        monthlyOverviewLinkView: currentSalary?.monthly_overview_file_link ?
          <span onClick={() => {window.open(currentSalary?.monthly_overview_file_link, '_blank')}}><img
            src={DownloadInvoiceIcon} alt="download monthly overview file"/></span> :
          <img src={GreyDownloadInvoiceIcon} alt="download monthly overview file"/>,
        salaryLinkView: currentSalary?.salary_file_link ?
          <span onClick={() => {window.open(currentSalary?.salary_file_link, '_blank')}}><img src={DownloadInvoiceIcon}
                                                                                              alt="download salary file"/></span> :
          <img src={GreyDownloadInvoiceIcon} alt="download salary file"/>
      };
    });

    const firstDateEmployees = _.filter(calculatedEmployees, (employee) => employee.userTerms?.salary_date === SalaryDate.first)
    const ninthDateEmployees = _.filter(calculatedEmployees, (employee) => employee.userTerms?.salary_date === SalaryDate.ninth)

    setGroupedEmployees({
      firstDateSalaryEmployees: _.sortBy(firstDateEmployees, "agentTotalIncomes").reverse(),
      ninthDateSalaryEmployees: _.sortBy(ninthDateEmployees, "agentTotalIncomes").reverse(),
    });
    // eslint-disable-next-line
  }, [employees, currentMonth]);


  useEffect(() => {
    setTabsConfig([{
      id: tabIds.first,
      label: "1st",
      itemsCount: groupedEmployees.firstDateSalaryEmployees.length,
      defaultSelectedTab: currentTabId === tabIds.first
    }, {
      id: tabIds.ninth,
      label: "9st",
      itemsCount: groupedEmployees.ninthDateSalaryEmployees.length,
      defaultSelectedTab: currentTabId === tabIds.ninth
    }]);
  }, [groupedEmployees, i18n.language, selectedEmployee]);


  useEffect(() => {
    if (!filterValue) {
      setFilteredEmployees({ ...groupedEmployees })
    } else {
      setFilteredEmployees({
        firstDateSalaryEmployees: filterEmployee(groupedEmployees.firstDateSalaryEmployees, filterValue),
        ninthDateSalaryEmployees: filterEmployee(groupedEmployees.ninthDateSalaryEmployees, filterValue),
      });
    }

    setUpdateTable(false);
    setTimeout(() => setUpdateTable(true));

  }, [filterValue, groupedEmployees]);

  useEffect(() => {
    if (!selectedEmployee)
      return;

    const currentSalary = _.find(selectedEmployee.salaries, (salary) =>
      salary.year === currentMonth.year &&
      salary.month === currentMonth.month);

    if (!currentSalary)
      createEmployeeSalary();

    // eslint-disable-next-line
  }, [selectedEmployee, currentMonth]);

  const createEmployeeSalary = async (employee = selectedEmployee, salaryForCreate = {}) => {
    const salary = {
      ...salaryForCreate,
      user_id: employee.id,
      month: currentMonth.month,
      year: currentMonth.year,
      status: SalaryStatus.open
    }

    const createdSalary = await addSalary(salary);
    const updatedEmployee = { ...employee, salaries: [...( employee.salaries || [] ), createdSalary] };
    refreshEmployee(updatedEmployee);

    if (selectedEmployee?.id === updatedEmployee.id)
      setSelectedEmployee(updatedEmployee);
  }

  const updateEmployeeSalary = (employee, salaryUpdate, invoiceUpdates, expenseUpdates) => {
    const currentSalary = _.find(employee.salaries, (salary) =>
      salary.year === currentMonth.year &&
      salary.month === currentMonth.month);

    if (currentSalary) {
      const update = { salary: { id: currentSalary.id, ...salaryUpdate } };

      if (invoiceUpdates)
        update.invoiceUpdates = invoiceUpdates;

      if (expenseUpdates)
        update.expenseUpdates = expenseUpdates;

      return updateSalary(update)
        .then((updatedSalary) => {
          const salaries = [...employee.salaries]
          const index = _.findIndex(salaries, { id: updatedSalary.id });
          salaries.splice(index, 1, updatedSalary);

          const invoices = _.map(employee.invoices, invoice => {
            const invoiceUpdate = _.find(invoiceUpdates, (update) => update.id === invoice.id && update.invoice_number === invoice.invoice_number) || {};

            return { ...invoice, ...invoiceUpdate };
          });

          const expenses = _.map(employee.expenses, expense => {
            const expenseUpdate = _.find(expenseUpdates, (update) => update.id === expense.id) || {};

            return { ...expense, ...expenseUpdate };
          });

          const updatedEmployee = { ...employee, salaries, invoices, expenses };

          if (selectedEmployee?.id === updatedEmployee.id)
            setSelectedEmployee(updatedEmployee);

          refreshEmployee(updatedEmployee);
        });
    } else {
      return createEmployeeSalary(employee, salaryUpdate);
    }
  }

  const refreshEmployee = (employee) => {
    const updatedEmployees = [...employees];
    const index = _.findIndex(updatedEmployees, { id: employee.id });
    updatedEmployees.splice(index, 1, employee);
    setEmployees(updatedEmployees);
  }

  const exportToCsv = () => {
    const data = _(employees)
      .filter((employee) => ( employee.userTerms?.salary_date === SalaryDate.first && currentTabId === tabIds.first )
        || ( employee.userTerms?.salary_date === SalaryDate.ninth && currentTabId === tabIds.ninth ))
      .map((employee) => {
        const currentSalary = _.find(employee.salaries, (salary) =>
          salary.year === currentMonth.year &&
          salary.month === currentMonth.month);

        if (!currentSalary)
          return;

        return {
          oketz_number: employee.oketz_number,
          // identity_number: employee.identity_number,
          // total_oketz_net: currentSalary.total_oketz_net,
          // total_oketz_net_to_pay: currentSalary.total_oketz_net_to_pay,
          total_oketz_cost: currentSalary.total_oketz_cost,
        }
      })
      .filter(Boolean)
      .value();

    setExportData(data);
    setTimeout(() => csvLink.current.link.click());
  }

  const handleCsvFileUpload = (e) => {
    if (!e.target.files.length) return;
    const fileReader = new FileReader();


    fileReader.onload = function (event) {
      const csvOutput = event.target.result;
      console.log(csvFileToArray(csvOutput));
      setImportData(csvFileToArray(csvOutput));
    };

    fileReader.readAsText(e.target.files[0]);
  }

  const csvFileToArray = (string) => {
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    return csvRows.map((row) => {
      const values = row.split(",");

      if (_.isNil(+values[0]) || !values[1] || _.isNil(+values[2]) || _.isNil(+values[3]) || _.isNil(+values[4]))
        return;

      return {
        oketz_number: values[0],
        identity_number: +values[1]?.replace("-", ""),
        total_oketz_net_to_pay: +values[2],
        total_oketz_cost: +values[3],
        total_oketz_net: +values[4],
      }
    })
      .filter(Boolean);
  }

  const getDateView = (month) => {
    const date = moment();
    date.set('year', month.year);
    date.set('month', month.month - 1);

    return date.format('MM/YYYY');
  }

  return <div className="salaries-page-wrapper">
    <header className="salaries-header">
      <div className="salaries-header-title">
        {!selectedEmployee && <div>{t('salaries')}</div>}
        {selectedEmployee && <div onClick={() => setSelectedEmployee(null)}>
          <ArrowBackIcon/>{`${t('salaries')} : ${selectedEmployee.firstname}`}
          {_.find(selectedEmployee.salaries, (salary) =>
            salary.year === currentMonth.year &&
            salary.month === currentMonth.month)?.status === SalaryStatus.done &&
          <div className="salaries-header-employee-done">{t('done')}</div>}
        </div>}
      </div>
      <div>
        <DateCarousel onMonthChange={setCurrentMonth}/>
      </div>
    </header>
    {showLoader && <Loader/>}
    {!showLoader && !selectedEmployee && <div className="salaries-page-content-wrapper">
      <div className="salary-header-wrapper">
        <div className="salary-header-tabs-wrapper">
          <YeulsTabs
            config={tabsConfig}
            onChange={(value) => {
              setCurrentTabId(value);
              setFilterValue("");
            }}/>
        </div>
        <div className="salary-header-button-wrapper">
          <YeulsButton onClick={() => setShowMergeSalariesConfirmDialog(true)} variant="outlined"
                       className="sync-button">{t("sync-salaries")}</YeulsButton>
          <YeulsButton variant="outlined">
            <label>
              <input
                autoComplete="off"
                style={{ display: 'none' }}
                tabIndex={-1}
                accept=".csv"
                type="file"
                onChange={(e) => handleCsvFileUpload(e)}
              />
              {t("import-csv")}
            </label>
          </YeulsButton>
          <YeulsButton onClick={exportToCsv}>{t("export-to-csv")}</YeulsButton>
          <CSVLink
            data={exportData}
            headers={csvHeaders}
            filename={`עלות.csv`}
            className="btn btn-primary"
            target="_blank"
            ref={csvLink}
          />
        </div>
      </div>
      <div className={"search-wrapper"}>
        <Search value={filterValue} onChange={setFilterValue} onClearFilter={() => setFilterValue("")}
                placeholderKey="search-by-name-and-id"/>
      </div>
      {currentTabId === tabIds.first && updateTable && <div>
        <YeulsTable
          data={filteredEmployees.firstDateSalaryEmployees}
          headers={tableHeaders}
          columns={tableColumns}
          totalCount={filteredEmployees.firstDateSalaryEmployees?.length}
          pagination={true}
          perPageItemCount={12}
          partialPageCount={3}
          nextPageText=">"
          prePageText="<"/>
      </div>}
      {currentTabId === tabIds.ninth && updateTable && <div>
        <YeulsTable
          data={filteredEmployees.ninthDateSalaryEmployees}
          headers={tableHeaders}
          columns={tableColumns}
          totalCount={filteredEmployees.ninthDateSalaryEmployees?.length}
          pagination={true}
          perPageItemCount={12}
          partialPageCount={3}
          nextPageText=">"
          prePageText="<"/>
      </div>}
    </div>}

    {!showLoader && selectedEmployee && <EmployeeSalary
      currentMonth={currentMonth}
      employee={selectedEmployee}
      updateEmployeeSalary={updateEmployeeSalary}
      clients={clients}
      supplieers={suppliers}
    />}
    <ConfirmDialog
      open={!!importData}
      onClose={() => setImportData(null)}
      title={`${t('import-salaries')} ${getDateView(importMonth)}`}
      onSubmit={() => {
        setShowLoader(true)
        importSalary({ salaries: importData, month: importMonth.month, year: importMonth.year })
          .then(() => {
            enqueueSnackbar(t("import-finished"), { variant: 'success' });
            setImportData(null);
            return refreshEmployees();
          })
          .catch(() => {
            enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
          })
          .finally(() => {
            setShowLoader(false)
          })
      }}>
      <div
        className="dialog-content">
        <div className="import-message">
          {t('select-import-month')}
        </div>
        <DateCarousel onMonthChange={setImportMonth}/>
        {/*<div className="import-message">*/}
        {/*  {t('sure-import').replace('{0}', getDateView(importMonth))}*/}
        {/*</div>*/}
      </div>
    </ConfirmDialog>
    <ConfirmDialog
      open={showMergeSalariesConfirmDialog}
      onClose={() => setShowMergeSalariesConfirmDialog(false)}
      title={t('sync-salaries')}
      onSubmit={() => {
        setShowLoader(true);
        setShowMergeSalariesConfirmDialog(false);
        mergeSalaries()
          .then(() => refreshEmployees())
          .then(() => {
            enqueueSnackbar(t("salaries-merged-successfully"), { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar(t("something-went-wrong"), { variant: 'error' });
          })
          .finally(() => {
            setShowLoader(false);
          })
      }}>
      <div className="dialog-content">{t('sure-sync')}</div>
    </ConfirmDialog>
  </div>
}

const filterEmployee = (employees, filterValue) => {
  const filterValueToLowerCase = filterValue.toLowerCase();

  return _.filter(employees, (employee) =>
    ( employee.firstname?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
    ( employee.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1)
}

export default Salaries;