import * as constants from '../constants';

const initialState = {
  overview: null,
};

const actionHandlers = {
  [constants.SET_MONTHLY_OVERVIEW](state, overview) {
    return { ...state, overview };
  },
};

export default function monthlyOverviewState(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
