import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

import "./InputComponent.scss";

const YeulsInputComponent = ({ value, tLabel, ...props }) => {
  const { t } = useTranslation();

  return <div className="input-component-item">
    <label className="input-component-item-label">{t(tLabel)}</label>
    <TextField
      variant="outlined"
      className="input-component-item-input"
      value={value}

      onChange={(event) => event.target.value}
      {...props}
    />
  </div>
}

export default YeulsInputComponent;