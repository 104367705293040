export const Routes = {
    main: "/",
    signIn: "/signin",
    admin: "/admin",
    forgotPassword: "/forgot-password",
    oneTimePassword: "/one-time-password",
    monthlyReport: "/monthly-report",
    workers: "/workers",
    accounts: "/accounts",
    salaries: "/salaries",
}