import * as constants from '../constants';
import { AuthService } from '../../services/auth';

import * as _ from 'lodash';

const userData = AuthService.getTokenData();
const initialUser = !_.isNull(userData) ? userData : { id: null };

const initialState = {
  user: initialUser,
};

const actionHandlers = {
  [constants.SET_USER_INFO](state, user) {
    return { ...state, user };
  },
};

export default function userState(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
