import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './tabPanel';
import {
  uploadMonthlyFiles,
  uploadSalariesFiles,
  uploadInvoiceReceiptsFiles,
  uploadOneZeroSixFiles,
} from '../../api/requests/files';

import useStyles from './filesUpload.styles';
import Loader from "../loder";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function FilesUpload() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const monthlyValidator = (files) => {
    const notValidFiles = files.map((f) => {
      const splittedFilename = `${f.name}`.split('_');
      const month = splittedFilename[1]?.split('.')[0];
      const noSochen = splittedFilename[0];
      const isValidNoSochen = !isNaN(noSochen);
      const isValidMonth = !isNaN(noSochen) && month.length < 3;

      if (!isValidNoSochen || !isValidMonth) {
        const notValidFile = _.assign(f, { invalid: true });
        return notValidFile;
      }

      return f;
    });

    return notValidFiles;
  };

  const oneZeroSixValidator = (files) => {
    const notValidFiles = files.map((f) => {
      const splittedFilename = `${f.name}`.split('_');
      const year = splittedFilename[1]?.split('.')[0];
      const noSochen = splittedFilename[0];
      const isValidNoSochen = !isNaN(noSochen);
      const isValidYear = !isNaN(noSochen) && year.length === 4;

      if (!isValidNoSochen || !isValidYear) {
        const notValidFile = _.assign(f, { invalid: true });
        return notValidFile;
      }

      return f;
    });

    return notValidFiles;
  };

  const salariesValidator = (files) => {
    const notValidFiles = files.map((f) => {
      const splittedFilename = `${f.name}`.split('_');
      const tlush = splittedFilename[0];
      const idNumber = splittedFilename[1];
      const mmyy = splittedFilename[2]?.split('.')[0];

      const isValidTlush = tlush === 'tlush';
      const isValidIdNumber = !isNaN(idNumber);
      const isValidMMYY = !isNaN(mmyy) && mmyy.length < 5;

      if (!isValidTlush || !isValidIdNumber || !isValidMMYY) {
        const notValidFile = _.assign(f, { invalid: true });
        return notValidFile;
      }

      return f;
    });

    return notValidFiles;
  };

  const invoiceReceiptValidator = (files) => {
    const notValidFiles = files.map((f) => {
      const fileNumber = f.name.split('.')[0];
      const isValidFileNumber = !isNaN(fileNumber);

      if (!isValidFileNumber) {
        const notValidFile = _.assign(f, { invalid: true });
        return notValidFile;
      }

      return f;
    });

    return notValidFiles;
  };

  return (
    <div className="admin-panel-container">
      {showLoader && <Loader/>}
      <header className="admin-panel-header">
        <div className="admin-panel-header-title">
          {t('upload-files')}
        </div>
      </header>

      <div className={classes.root}>
        <AppBar
          className={
            i18n.language === 'heb'
              ? classes.tabHeaderReverse
              : classes.tabHeader
          }
          position="static"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <Tab label={t('monthly-files')} {...a11yProps(0)} />
            <Tab label={t('106')} {...a11yProps(1)} />
            <Tab label={t('salaries')} {...a11yProps(2)} />
            <Tab label={t('invoice/receipt')} {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel
          validator={monthlyValidator}
          uploadFiles={(files) => {
            setShowLoader(true);
            return uploadMonthlyFiles(files)
              .finally(() => setShowLoader(false));
          }}
          value={value}
          format="noSochen_M.pdf"
          fileFormat="application/pdf"
          errorText={t('monthly-files-error-message')}
          index={0}
        />
        <TabPanel
          validator={oneZeroSixValidator}
          uploadFiles={(files) => {
            setShowLoader(true);
            return uploadOneZeroSixFiles(files)
              .finally(() => setShowLoader(false));
          }}
          value={value}
          format="personalId_YYYY.pdf"
          fileFormat="application/pdf"
          errorText={t('one-zero-six-files-error-message')}
          index={1}
        />
        <TabPanel
          validator={salariesValidator}
          uploadFiles={(files) => {
            setShowLoader(true);
            return uploadSalariesFiles(files)
              .finally(() => setShowLoader(false));
          }}
          value={value}
          fileFormat="image/gif"
          format="tlush_idNumber_MMYY.gif"
          errorText={t('salary-files-error-message')}
          index={2}
        />
        <TabPanel
          validator={invoiceReceiptValidator}
          uploadFiles={(files) => {
            setShowLoader(true);
            return uploadInvoiceReceiptsFiles(files)
              .finally(() => setShowLoader(false));
          }}
          value={value}
          format="fileNumber.pdf"
          fileFormat="application/pdf"
          errorText={t('invoice-files-error-message')}
          index={3}
        />
      </div>
    </div>
  );
}

export default FilesUpload;
