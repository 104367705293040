import * as _ from "lodash";
import React, { useMemo, useState } from "react";

import "./CompanyUsersList.scss";
import CompanyUserItemDetails from "./company-user-item-details";
import { Mode } from "../../../../../shared/constants";
import { useTranslation } from "react-i18next";
import AccountsSearchField from "../../../common-components/accounts-search-field";

const UserCompaniesList = (props) => {
  const { t } = useTranslation();
  const { mode, company, users, onCompanyUserChange } = props;
  const [filterValue, setFilterValue] = useState("");

  const filteredUsers = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    const companyUserIds = _.map(company.users, "id");
    const filteredValues = _.filter(users, (user) =>
      ( user.firstname?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.email?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);

    if(filterValue?.length < 1 && mode === Mode.edit)
      return [..._.filter(filteredValues, (value) => _.includes(companyUserIds, value.id))];

    return [
      ..._.filter(filteredValues, (value) => _.includes(companyUserIds, value.id)),
      ..._.filter(filteredValues, (value) => !_.includes(companyUserIds, value.id)),
    ]
    // eslint-disable-next-line
  }, [users, filterValue, mode]);

  return <div className="company-users-details-wrapper">
    {mode === Mode.edit &&
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue}/>}
    {_.map(filteredUsers, (user) =>
      <React.Fragment key={user.id}>
        <CompanyUserItemDetails
          user={user}
          mode={mode}
          companyUser={_.find(company.users, { id: user.id })?.UserCompany}
          onChange={(companyUser) => onCompanyUserChange(companyUser ? true : false, user.id)}/>
      </React.Fragment>)}
    {mode === Mode.view && !company.users?.length &&
    <div className="empty-list">{t("company-users-empty-list")}</div>}
  </div>
}

export default UserCompaniesList;