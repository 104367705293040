import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import "./NewUser.scss";
import GeneralUserForm from "../../common-components/forms/general-user-form";
import TermsAndConditionsForm from "../../common-components/forms/terms-and-conditions-form";
import BankForm from "../../common-components/forms/bank-form";
import InsuranceUserForm from "../../common-components/forms/insurance-user-form";
import ProgressIndicator from "../../common-components/progress-indicator";
import YeulsButton from "../../../yeuls-button";
import { validateTermForm, validateGeneralUserForm } from "../../common-components/validators";
import UserLinks from "./user-links";
import { UserAccountDocuments } from "./user-account-documents";
import { jsonToFormData } from "../../common-components/utils";

const defaultUserFormData = {
  firstname: { value: null, error: false },
  lastname: { value: null, error: false },
  identity_number: { value: null, error: false },
  oketz_number: { value: null, error: false },
  email: { value: null, error: false },
  phone: { value: null, error: false },
  country: { value: null, error: false },
  zip: { value: null, error: false },
  city: { value: null, error: false },
  address: { value: null, error: false },
  car_details: { value: null, error: false },
  business_number: { value: null, error: false },
  withholding_tax: { value: null, error: false },
  withholding_tax_expiration: { value: null, error: false },
  general_ledger: { value: null, error: false },

  yeuls_fee: { value: null, error: false },
  max_yeuls_fee: { value: process.env.REACT_APP_MAX_YEULS_FEE || null, error: false },
  registration_fee: { value: process.env.REACT_APP_REGISTRATION_FEE || null, error: false },
  registration_fee_free: { value: false, error: false },
  salary_date: { value: null, error: false },
  included_vat: { included_vat: true, error: false },
  calculation_type: { value: null, error: false },
  report_type: { value: null, error: false },
  report_fee: { value: null, error: false },
  currency: { value: null, error: false },
  start_date: { value: null, error: false },
  end_date: { value: null, error: false },
  notes: { value: null, error: false },

  bank_name: { value: null, error: false },
  bank_number: { value: null, error: false },
  bank_branch_number: { value: null, error: false },
  bank_account_number: { value: null, error: false },

  insurance_notes: { value: null, error: false },
  contract: { value: null, error: false },
  idScan: { value: null, error: false },
  taxForm: { value: null, error: false },
}

const steps = {
  general: 1,
  termsAndConditions: 2,
  bank: 3,
  insurance: 4,
  links: 5,
  accountDocuments: 6,
}

const NewUser = (props) => {
  const { onCreateUser, onCancel, companies, clients, suppliers } = props;
  const { t, i18n } = useTranslation();
  const stepsConfig = useMemo(() => [
      { value: steps.general, label: t("general"), validate: validateGeneralUserForm },
      { value: steps.termsAndConditions, label: t("terms-and-conditions"), validate: validateTermForm },
      { value: steps.bank, label: t("bank") },
      { value: steps.insurance, label: t("insurance") },
      { value: steps.links, label: t("links") },
      { value: steps.accountDocuments, label: t("account-documents") },
    ],
    // eslint-disable-next-line
    [i18n.language]);
  const [activeStep, setActiveStep] = useState(stepsConfig[0]);
  const [userFormData, setUserFormData] = useState(defaultUserFormData);
  const [userCompanies, setUserCompanies] = useState([]);
  const [userClients, setUserClients] = useState([]);
  const [userSuppliers, setUserSuppliers] = useState([]);
  const nextStep = () => {
    if (activeStep.value !== steps.accountDocuments) {
      if (!activeStep.validate || ( activeStep.validate, activeStep.validate(userFormData, setUserFormData) ))
        setActiveStep(stepsConfig[activeStep.value]);
    } else {
      saveUser();
    }
  }

  const saveUser = () => {
    const user = {
      identity_number: userFormData.identity_number.value,
      firstname: userFormData.firstname.value,
      lastname: userFormData.lastname.value,
      email: userFormData.email.value,
      oketz_number: userFormData.oketz_number.value,
      phone: userFormData.phone.value,
      country: userFormData.country.value,
      city: userFormData.city.value,
      address: userFormData.address.value,
      car_details: userFormData.car_details.value,
      bank_name: userFormData.bank_name.value,
      bank_number: userFormData.bank_number.value,
      bank_branch_number: userFormData.bank_branch_number.value,
      bank_account_number: userFormData.bank_account_number.value,
      insurance_notes: userFormData.insurance_notes.value,
      business_number: userFormData.business_number.value,
      zip: userFormData.zip.value,
      withholding_tax: +userFormData.withholding_tax.value,
      withholding_tax_expiration: userFormData.withholding_tax_expiration.value,
      general_ledger: userFormData.general_ledger.value,
      userCompanies,
      userClients,
      userSuppliers,
      userTerms: {
        yeuls_fee: +userFormData.yeuls_fee.value,
        max_yeuls_fee: +userFormData.max_yeuls_fee.value,
        registration_fee: +userFormData.registration_fee.value,
        registration_fee_paid: !userFormData.registration_fee.value,
        included_vat: userFormData.included_vat.value,
        salary_date: userFormData.salary_date.value,
        calculation_type: userFormData.calculation_type.value,
        report_type: userFormData.report_type.value,
        report_fee: +userFormData.report_fee.value,
        currency: userFormData.currency.value,
        start_date: userFormData.start_date.value,
        end_date: userFormData.end_date.value,
        notes: userFormData.notes.value
      },
    }

    const formData = {
      contract: userFormData.contract.value && userFormData.contract.value.length ? userFormData.contract.value[0] : null,
      idScan: userFormData.idScan.value && userFormData.idScan.value.length ? userFormData.idScan.value[0] : null,
      taxForm: userFormData.taxForm.value && userFormData.taxForm.value.length ? userFormData.taxForm.value[0] : null,
      formData: JSON.stringify(user),
    };

    onCreateUser(jsonToFormData(formData))
  }

  return <div className="new-user-wrapper">
    <div className="new-user-indicator-wrapper">
      <ProgressIndicator steps={stepsConfig} activeStep={activeStep} setActiveStep={setActiveStep}/>
    </div>
    <div className="new-user-form-container">
      {activeStep?.value === steps.general &&
      <GeneralUserForm generalFormData={userFormData} setGeneralFormData={setUserFormData}/>}
      {activeStep?.value === steps.termsAndConditions &&
      <TermsAndConditionsForm termFormData={userFormData} setTermFormData={setUserFormData}
                              showRegistrationFee={true}/>}
      {activeStep?.value === steps.bank &&
      <BankForm bankFormData={userFormData} setBankFormData={setUserFormData}/>}
      {activeStep?.value === steps.insurance &&
      <InsuranceUserForm insuranceFormData={userFormData} setInsuranceFormData={setUserFormData}/>}
      {activeStep?.value === steps.links &&
      <UserLinks
        companies={companies}
        userCompanies={userCompanies}
        setUserCompanies={setUserCompanies}
        clients={clients}
        userClients={userClients}
        setUserClients={setUserClients}
        suppliers={suppliers}
        userSuppliers={userSuppliers}
        setUserSuppliers={setUserSuppliers}
      />}
      {activeStep?.value === steps.accountDocuments &&
      <UserAccountDocuments userFormData={userFormData} setUserFormData={setUserFormData} />}
    </div>
    <div className="new-user-buttons-container">
      <YeulsButton variant="negative" onClick={onCancel}>{t("cancel")}</YeulsButton>
      <YeulsButton onClick={nextStep}>{t("next")}</YeulsButton>
    </div>
  </div>
}

export default NewUser;