import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../../containers/Text';
import './Table.scss';
import * as _ from 'lodash';

import InvoiceRow from './invoice-row';

const SalaryTable = ({ invoices = [], language }) => {
  const { t } = useTranslation();

  const isTableEmpty = invoices.length === 1 && _.isUndefined(invoices[0].id);
  const isOriginAmountNotEmpty = isTableEmpty
    ? false
    : invoices.some((i) => {
        return i.payment_origin !== 0;
      });

  const isNotesNotEmpty = isTableEmpty
    ? false
    : invoices.some((i) => {
        return !!i?.message;
      });

  const tableRows = () => {
    return invoices.map((invoice, id) => (
      <InvoiceRow
        key={id}
        invoice={invoice}
        id={id}
        isOriginAmountNotEmpty={isOriginAmountNotEmpty}
        isNotesNotEmpty={isNotesNotEmpty}
      />
    ));
  };

  const noTableRows = () => {
    return (
      <tr>
        <td className="date">
          <Text text={t('no-files')} />
        </td>
      </tr>
    );
  };

  return (
    <table>
      <thead>
        <tr>
          <th className="date">
            <Text text={t('created-date')} />
          </th>
          <th className="number">
            <Text text={t('number')} />
          </th>
          {isNotesNotEmpty && (
            <th className="notes">
              <Text text={t('notes')} />
            </th>
          )}
          <th className="type">
            <Text text={t('type')} />
          </th>
          <th className="customer">
            <Text text={t('customer')} />
          </th>
          {isOriginAmountNotEmpty && (
            <>
              <th className="origin-amount">
                <Text text={t('origin-amount')} />
              </th>
              <th className="currency">
                <Text text={t('currency-type')} />
              </th>
              <th className="rate">
                <Text text={t('rate')} />
              </th>
            </>
          )}
          <th className={language.language === 'heb' ? 'amount heb' : 'amount'}>
            <Text text={t('total-nis')} />
          </th>
        </tr>
      </thead>
      <tbody>{!isTableEmpty ? tableRows() : noTableRows()}</tbody>
    </table>
  );
};

export default SalaryTable;
