import axios from 'axios';
import { AuthService } from '../services/auth';

const instantiateRequestInterceptor = () => {
  axios.interceptors.request.use(async (config) => {
    const token = await AuthService.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
};

export default instantiateRequestInterceptor;
