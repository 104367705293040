import { Button, Dialog, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import './CompanyAccountDocument.scss';
import { FileComponent } from '../../../common-components/file-component/input';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete, AiOutlineEye, AiOutlinePlus } from 'react-icons/ai';
import Text from '../../../../../containers/Text';
import i18n from 'i18next';
import CloseIcon from '@material-ui/icons/Close';
import YeulsButton from '../../../../yeuls-button';
import { useDialog } from '../../../../../hooks/useDialog';
import InputComponent from '../../../common-components/input-component';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export const OtherDocsDialog = ({ open, onClose, submitForm }) => {
  const { t } = useTranslation();
  const currentLng = i18n.language;

  const [generalFormData, setGeneralFormData] = useState({
    name: { value: '', error: false },
    file: { value: null, error: false },
  });

  const onSubmit = () => {
    if (!generalFormData.name?.value) {
      generalFormData.name = { ...generalFormData.name, error: true };
    }
    if (
      !generalFormData.file?.value ||
      (generalFormData.file?.value && generalFormData.file?.value?.length === 0)
    ) {
      generalFormData.file = { ...generalFormData.file, error: true };
    }
    if (
      generalFormData.file?.value &&
      generalFormData.file?.value?.length > 0 &&
      generalFormData.name?.value
    ) {
      submitForm({
        name: generalFormData.name?.value,
        file: generalFormData.file?.value[0],
      });
    } else {
      setGeneralFormData({ ...generalFormData });
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div
        className={`confirm-dialog-wrapper ${
          currentLng === 'heb' ? 'rotate' : ''
        }`}
      >
        <div className="confirm-dialog-header">
          <div className="confirm-dialog-title">{t('add-other-docs')}</div>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
        <div className="confirm-dialog-content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputComponent
                field="name"
                tLabel={t('document-name')}
                formData={generalFormData}
                setFormData={setGeneralFormData}
              />
            </Grid>
            <FileComponent
              label={t('file')}
              fileType="application/pdf, image/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, applicaion/csv"
              size={12}
              multiple={false}
              field="file"
              format={t('jpeg-png-doc-csv-docx-xlsx-pdf')}
              formData={generalFormData}
              setFormData={setGeneralFormData}
              removedFile={(id) => {}}
            />
          </Grid>
        </div>
        <div
          className="confirm-dialog-footer"
          style={{ justifyContent: 'center', gap: '8px' }}
        >
          <YeulsButton variant="negative" onClick={onClose}>
            {t('cancel')}
          </YeulsButton>
          <YeulsButton onClick={onSubmit}>{t('add')}</YeulsButton>
        </div>
      </div>
    </Dialog>
  );
};

export const CompanyAccountDocuments = ({
  companyFormData,
  setCompanyFormData,
}) => {
  const { t } = useTranslation();
  const { dialogOpen, handleDialogOpen, handleDialogClose } = useDialog();

  return (
    <div className="user-account-documents-wrapper">
      <Grid container spacing={3}>
        <FileComponent
          label={t('services-agreement')}
          fileType="application/pdf"
          size={6}
          multiple={true}
          field="serviceAgreement"
          format={t('pdf')}
          formData={companyFormData}
          setFormData={setCompanyFormData}
          removedFile={(id) => {}}
        />
        <Grid item xs={12} className="file-wrapper">
          <label className="input-component-item-label">
            {t('add-other-docs')}
          </label>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              handleDialogOpen();
            }}
            style={{
              marginLeft: 15,
              marginRight: 15,
              color: '#003164',
            }}
            startIcon={<AiOutlinePlus />}
          >
            <Text text={t('add')} />
          </Button>
        </Grid>
        {companyFormData?.otherDocs?.value &&
          companyFormData?.otherDocs?.value?.length > 0 && (
            <>
              <Grid item xs={12} className="file-wrapper">
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {t('document-name')}
                        </TableCell>
                        <TableCell align="center">{t('file')}</TableCell>
                        <TableCell align="center">{t('action')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyFormData?.otherDocs?.value?.map((row, index) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.file.name}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                window.open(
                                  URL.createObjectURL(row.file),
                                  '_blank',
                                  'noreferrer'
                                );
                              }}
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                color: '#003164',
                              }}
                              startIcon={<AiOutlineEye />}
                            ></Button>
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => {
                                setCompanyFormData({
                                  ...companyFormData,
                                  otherDocs: {
                                    value: companyFormData?.otherDocs?.value.filter(
                                      (item, i) => i !== index
                                    ),
                                    error: false,
                                  },
                                });
                              }}
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                color: '#003164',
                              }}
                              startIcon={<AiOutlineDelete />}
                            ></Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
      </Grid>
      {dialogOpen && (
        <OtherDocsDialog
          open={dialogOpen}
          onClose={() => handleDialogClose()}
          submitForm={(data) => {
            setCompanyFormData({
              ...companyFormData,
              otherDocs: {
                value: companyFormData?.otherDocs?.value
                  ? companyFormData?.otherDocs?.value.concat([data])
                  : [data],
                error: false,
              },
            });
            handleDialogClose();
          }}
        />
      )}
    </div>
  );
};
