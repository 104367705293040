import * as actions from '../redux/actions/index';
import { connect } from 'react-redux';
import MonthlyOverview from '../components/monthly-overview';

export function mapStateToProps({ monthlyOverviewState }) {
  return {
    monthlyOverview: monthlyOverviewState.overview,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setMonthlyOverview: (payload) =>
      dispatch(actions.setMonthlyOverview(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyOverview);
