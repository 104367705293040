import React, {useMemo} from "react";

import "./ReceiptsList.scss";
import {useTranslation} from "react-i18next";
import * as _ from "lodash";
import {NumbersService} from "../../../../../services/numbers";
import DownloadInvoiceIcon from "../../../../../shared/icons/download-invoice-icon.svg";
import GreyDownloadInvoiceIcon from "../../../../../shared/icons/grey-download-invoice-icon.svg";
import YeulsTable from "../../../../yeuls-table";

const ReceiptsList = (props) => {
  const {invoices, receipts, clients, filterValue} = props;
  const {t, i18n} = useTranslation();

  const headers = useMemo(() => [t("created-date"), t("receipt-number"), t("client-name"), t("total"), t("invoice-number"), t("notes"), "."], [i18n.language]);
  const columns = "createdDateView.receipt_number.clientNameView.paymentTotalView.invoiceNumberView.invoiceNotesView.attachmentsView";

  const onAttachmentDownloadClick = (receipt) => {
    if (!_.isNil(receipt.receipt_file_link)) {
      window.open(receipt.receipt_file_link, '_blank');
    }

  }

  const mappedReceipts = useMemo(() => {
    const dateOptions = {year: 'numeric', month: 'numeric', day: 'numeric'};
    const filterValueToLowerCase = filterValue?.toLowerCase();

    return _(receipts)
      .filter((receipt) => `${receipt.receipt_number || ""}`.indexOf(filterValueToLowerCase) !== -1)
      .orderBy("created_on", "desc")
      .map((receipt) => {
        const createdDateView = receipt.created_on ? new Date(receipt?.created_on).toLocaleDateString("en-GB", dateOptions) : "";
        const clientNameView = _.find(clients, {id: receipt.client_id})?.client_name || "";
        const paymentTotalView = NumbersService.numberWithCommas(receipt.amount);
        const invoice = _.find(invoices, {id: receipt.invoice_id});
        const invoiceNumberView = invoice?.invoice_number || "";
        const invoiceNotesView = receipt?.message || "";
        const attachmentsView =
          <div onClick={() => onAttachmentDownloadClick(receipt)}>
            {!_.isNil(receipt.receipt_file_link) ?
              <img src={DownloadInvoiceIcon} alt="download invoice"/> :
              <img src={GreyDownloadInvoiceIcon} alt="download invoice"/>}
          </div>

        return {
          ...receipt,
          createdDateView,
          clientNameView,
          paymentTotalView,
          invoiceNumberView,
          invoiceNotesView,
          attachmentsView
        }
      })
      .value();
  }, [receipts, filterValue]);


  return <YeulsTable
    data={mappedReceipts}
    headers={headers}
    columns={columns}
    totalCount={mappedReceipts.length}
    perPageItemCount={12}
    partialPageCount={3}
    nextPageText=">"
    prePageText="<"
    className="receipts-table-container"
  />;
}

export default ReceiptsList;