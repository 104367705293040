import React from 'react';
import { useTranslation } from 'react-i18next';

import { AiOutlineMail } from 'react-icons/ai';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { AiOutlinePhone } from 'react-icons/ai';
import { FaFax } from 'react-icons/fa';

import Text from '../../containers/Text';
import './HomePage.scss';

const MailInformation = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="contact-info">
        <div>
          <AiOutlineMail className="info-icon" />
          <Text text={t('mailing-address-title')} />
        </div>
        <div className="info-text">
          <Text text={t('mailing-address')} />
        </div>
      </div>
      <div className="contact-info">
        <div>
          <HiOutlineOfficeBuilding className="info-icon" />
          <Text text={t('office-address-title')} />
        </div>
        <div className="info-text">
          <Text text={t('office-address')} />
        </div>
      </div>
      <div className="contact-info">
        <div>
          <AiOutlinePhone className="info-icon" />
          <div className="row">
            <Text text={t('telephone')} />
            <div className="info-text">
              <Text text={t('073-2456500')} />
            </div>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div>
          <FaFax className="info-icon" />
          <div className="row">
            <Text text={t('fax')} />
            <div className="info-text">
              <Text text={t('073-3669767')} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailInformation;
