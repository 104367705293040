import * as jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { constants } from '../api/constants';

export class AuthService {
  static setToken(token) {
    localStorage.setItem('access_token', token);
  }

  static deleteToken() {
    localStorage.removeItem('access_token');
  }

  static async getToken() {
    let accessToken = localStorage.getItem('access_token');
    const isExpired = this.isExpired();

    if (isExpired) {
      const response = await fetch(`${constants.baseURL}/auth/refresh`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${accessToken}`,
        },
      });

      const parsedResult = await response.json();
      AuthService.setToken(parsedResult.result);
      return parsedResult.result;
    }

    return accessToken;
  }

  static getTokenData() {
    const accessToken = localStorage.getItem('access_token');

    try {
      return jwt_decode(accessToken);
    } catch (error) {
      return null;
    }
  }

  static isExpired() {
    const accessToken = localStorage.getItem('access_token');

    try {
      const decoded = jwt_decode(accessToken);
      const expValue = _.get(decoded, 'exp');
      if (!_.isFinite(expValue)) {
        return null;
      }
      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);

      return !(date.valueOf() > new Date().valueOf());
    } catch (error) {
      return true;
    }
  }

  static isExpiredToken(accessToken) {
    try {
      const decoded = jwt_decode(accessToken);
      const expValue = _.get(decoded, 'exp');
      if (!_.isFinite(expValue)) {
        return null;
      }
      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);

      return !(date.valueOf() > new Date().valueOf());
    } catch (error) {
      return true;
    }
  }

  static isInvalidEmailFormat(email) {
    // eslint-disable-next-line
    const emailRgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRgx.test(email);
  }
}
