import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '../../services/auth';

import Salary from '../salary';
import Spinner from '../spinner';
import Year from '../monthly-overview/year';

import Text from '../../containers/Text';
import './AdminPanel.scss';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { constants } from '../../api/constants';
import { getUserSalaries } from "../../api/requests/salary";
import { getUserInvoices } from "../../api/requests/invoice";
import { getUserReceipts } from "../../api/requests/receipt";
import AgentDocuments from "../accounts/common-components/agent-documents";
import { getClients } from "../../api/requests/client";
import { getSuppliers } from "../../api/requests/supplier";

const AdminPanel = () => {
  const { t } = useTranslation();

  const [activeUsers, setActiveUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userIN, setUserIN] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [userName, setUserName] = useState('all-files');
  const [errorMessage, setErrorMessage] = useState('');
  const [documents, setDocuments] = useState(null);
  const [clients, setClients] = useState(null);
  const [suppliers, setSuppliers] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    async function getActiveUsers() {
      const userToken = await AuthService.getToken();
      const response = await fetch(`${constants.baseURL}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${userToken}`,
        },
      });
      const parsedResult = await response.json();

      setActiveUsers(parsedResult.result);
      setIsLoading(false);
    }

    getActiveUsers();

    Promise.all([getClients(), getSuppliers()])
      .then(([clients, suppliers]) => {
        setClients(clients);
        setSuppliers(suppliers);
      })
  }, []);

  const getUserFiles = async () => {
    setErrorMessage('');
    setUserFiles([]);

    if (!userIN) {
      setIsLoading(false);
      return;
    }

    const splittedUserIN = userIN.split(' ');
    const userId = splittedUserIN[0];

    // eslint-disable-next-line
    const user = activeUsers.find((u) => {
      if (+u.identity_number === +userId) {
        return true;
      }
    });

    if (user !== undefined) {
      setUserName(user.firstname);
      setIsLoading(true);

      Promise.all([getUserSalaries(user.id), getUserInvoices(user.id), getUserReceipts(user.id)])
        .then(([salaries, invoices, receipts]) => {
          setDocuments({ salaries, invoices, receipts });
        })
        .finally(() => {setIsLoading(false)})
    }
  };

  return (
    <div className="admin-panel-container">
      <header className="admin-panel-header">
        <div className="admin-panel-header-title">
          {t(userName)}
        </div>
      </header>

      <div className="autocomplete-container">
        <Autocomplete
          freeSolo
          disableClearable
          disabled={isLoading}
          className="autocomplete"
          options={activeUsers.map(
            (option) =>
              option.identity_number?.toString() + ' ' + option.firstname
          )}
          onChange={(event, newValue) => {
            setUserIN(newValue);
          }}
          onInputChange={(event, newValue) => {
            setUserIN(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<Text text={t('search-by-user-id')}/>}
              margin="normal"
              variant="outlined"
              helperText={<Text text={t('search-helper-text')}/>}
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          className={'search-button'}
          startIcon={<SearchIcon/>}
          onClick={getUserFiles}
        >
          <Text text={t('search')}/>
        </Button>
      </div>

      {errorMessage !== '' && (
        <div className="error-message">
          <Text text={t(errorMessage)}/>
        </div>
      )}

      {isLoading && <Spinner/>}
      {!isLoading && documents && <div className="user-documents-wrapper">
        <AgentDocuments
        invoices={documents.invoices}
        receipts={documents.receipts}
        salaries={documents.salaries}
        clients={clients}
        suppliers={suppliers}/>
      </div>}
    </div>
  );
};

export default AdminPanel;
