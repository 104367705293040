import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from '../../containers/Text';

const MissedTable = ({ userMissedFiles = [], filesAttribute = 'files' }) => {
  const { t } = useTranslation();

  const isTableEmpty = userMissedFiles.length === 0;

  const tableRows = () => {
    return userMissedFiles.map((userFiles) => {
      return userFiles[filesAttribute].map((f, id) => {
        return (
          <tr key={id}>
            <td style={{ maxWidth: '100%' }} className="date">
              <Text text={f.fileNumber || f.date} />
            </td>
            <td style={{ maxWidth: '100%' }} className="number">
              <Text text={f.fileName} />
            </td>
            <td style={{ maxWidth: '100%' }} className={'amount'}>
              <Text
                text={`${userFiles.user.firstname} ${userFiles.user.identity_number}`}
              />
            </td>
          </tr>
        );
      });
    });
  };

  const noTableRows = () => {
    return (
      <tr>
        <td className="date">
          <Text text={t('no-files')} />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ maxWidth: '100%' }} className="salary-container">
      <table>
        <thead>
          <tr>
            <th style={{ maxWidth: '100%' }} className="date">
              <Text text={t('file-number')} />
            </th>
            <th style={{ maxWidth: '100%' }} className="number">
              <Text text={t('file-name')} />
            </th>
            <th style={{ maxWidth: '100%' }} className="amount">
              <Text text={t('user')} />
            </th>
          </tr>
        </thead>
        <tbody>{!isTableEmpty ? tableRows() : noTableRows()}</tbody>
      </table>
    </div>
  );
};

export default MissedTable;
