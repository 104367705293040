import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";

import "./UserCompaniesList.scss";
import UserCompanyItemDetails from "./user-company-item-details";
import { Mode } from "../../../../../shared/constants";
import AccountsSearchField from "../../../common-components/accounts-search-field";

const UserCompaniesListDetails = (props) => {
  const { t } = useTranslation();
  const { mode, user, companies, onUserCompanyChange } = props;
  const [filterValue, setFilterValue] = useState("");

  const filteredCompanies = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    const userCompanyIds = _.map(user.companies, "id");
    const filteredValues = _.filter(companies, (company) =>
      ( company.company_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( company.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( company.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);

    if(filterValue?.length < 1 && mode === Mode.edit)
      return [..._.filter(filteredValues, (value) => _.includes(userCompanyIds, value.id))];

    return [
      ..._.filter(filteredValues, (value) => _.includes(userCompanyIds, value.id)),
      ..._.filter(filteredValues, (value) => !_.includes(userCompanyIds, value.id)),
    ]
    // eslint-disable-next-line
  }, [companies, filterValue, mode]);

  return <div className="user-companies-details-wrapper">
    {mode === Mode.edit &&
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue}/>}
    {_.map(filteredCompanies, (company) =>
      <React.Fragment key={company.id}>
        <UserCompanyItemDetails
          company={company}
          mode={mode}
          userCompany={_.find(user.companies, { id: company.id })?.UserCompany}
          onChange={(userCompany) => onUserCompanyChange(company.id, userCompany)}/>
      </React.Fragment>)}
    {mode === Mode.view && !user.companies?.length &&
    <div className="empty-list">{t("user-companies-empty-list")}</div>}
  </div>
}

export default UserCompaniesListDetails;