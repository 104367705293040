import * as _ from "lodash";
import React from 'react';
import { useTranslation } from "react-i18next";

import YeulsTable from "../../yeuls-table";

import "./CompaniesList.scss";

const CompaniesList = (props) => {
  const { t } = useTranslation();
  const { companies, setSelectedCompany } = props;
  const mappedCompanies = _.map(companies, (company) => ( {
    ...company,
    statusView: !company?.companyTerms?.end_date ?
      <div className={"status active"}>{t("active")}</div> :
      <div className={"status dismissed"}>{t("dismissed")}</div>,
    employeesCount: company.users?.length || 0,
    nameView: <div className="clickable-block" onClick={() => setSelectedCompany(company)}>{company.company_name}</div>
  } ));
  const headers = [t("company-name"), t("business-number"), t("agent-id"), t("with-capital-employees"), t("status")];

  return <div className={"companies-list-wrapper"}>
    <YeulsTable
      data={mappedCompanies}
      headers={headers}
      columns="nameView.business_number.santec_account_id.employeesCount.statusView"
      totalCount={mappedCompanies.length}
      perPageItemCount={12}
      partialPageCount={3}
      nextPageText=">"
      prePageText="<"
      className={"company-table-container"}
    />
  </div>
}

export default CompaniesList;