import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '../../services/auth';

import Salary from '../salary';
import Spinner from '../spinner';
import Year from './year';

import * as _ from 'lodash';
import Text from '../../containers/Text';
import './MonthlyOverview.scss';
import { constants } from '../../api/constants';
import { getUserOneZeroSix, getUserSalaries } from "../../api/requests/salary";
import { getUserInvoices } from "../../api/requests/invoice";
import { getUserReceipts } from "../../api/requests/receipt";
import AgentDocuments from "../accounts/common-components/agent-documents";
import { getClients } from "../../api/requests/client";
import { getSuppliers } from "../../api/requests/supplier";

const MonthlyOverview = (props) => {
  const { t } = useTranslation();
  const { user } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [clients, setClients] = useState(null);
  const [suppliers, setSuppliers] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    async function getSalaries() {
      return Promise.all([getUserSalaries(user.id), getUserInvoices(user.id), getUserReceipts(user.id), getClients(), getSuppliers(), getUserOneZeroSix(user.id)])
        .then(([salaries, invoices, receipts, clients, suppliers, oneZeroSix]) => {
          setDocuments({ salaries, invoices, receipts, oneZeroSix });
          setClients(clients);
          setSuppliers(suppliers);
        })
        .finally(() => {setIsLoading(false)});
    }

    getSalaries();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div className="monthly-overview-container">
        <Spinner/>
      </div>
    );
  }

  return (
    <div className="monthly-overview-container">
      <header className="monthly-overview-header">
        <div className="monthly-overview-header-title">
          {t("monthly-overview")}
        </div>
      </header>
      {!isLoading && documents && <div className="monthly-overview-user-documents-wrapper">
        <AgentDocuments
          invoices={documents.invoices}
          receipts={documents.receipts}
          salaries={documents.salaries}
          oneZeroSix={documents.oneZeroSix}
          clients={clients}
          suppliers={suppliers}/>
      </div>}
    </div>
  );
};

export default MonthlyOverview;
