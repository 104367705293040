import * as _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import "./SupplierDetails.scss";
import { useTranslation } from "react-i18next";
import { Mode } from "../../../../shared/constants";
import YeulsTabs from "../../../yeuls-tabs";
import YeulsButton from "../../../yeuls-button";
import GeneralSupplierDetails from "./general-supplier-details";
import BankSupplierDetails from "./bank-supplier-details";
import SupplierAgentsDetails from "./supplier-agents-details";

const tabIds = {
  general: 0,
  bank: 1,
  agents: 2
}

const SupplierDetails = (props) => {
  const { supplier, onUpdateSupplier, users, companies } = props;
  const { t, i18n } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.general);
  const [mode, setMode] = useState(Mode.edit);
  const [supplierFormData, setSupplierFormData] = useState(getSupplierFormObject(supplier));
  const [supplierUserAgentsChanges, setSupplierUserAgentsChanges] = useState({});
  const [supplierCompanyAgentsChanges, setSupplierCompanyAgentsChanges] = useState({});

  const tabsConfig = useMemo(() => [
      { id: tabIds.general, label: t('general'), defaultSelectedTab: true },
      { id: tabIds.bank, label: t('bank') },
      { id: tabIds.agents, label: t('agents'), itemsCount: supplier.supplierAgents?.length }
    ],
    // eslint-disable-next-line
    [i18n.language, supplier]);

  useEffect(() => {
    if (mode === Mode.edit)
      setSupplierFormData(getSupplierFormObject(supplier));

    setSupplierUserAgentsChanges({});
    setSupplierCompanyAgentsChanges({});
    // eslint-disable-next-line
  }, [mode])

  const onSaveClick = () => {
    const supplierUpdate = {
      ...supplier,
      supplier_name: supplierFormData.supplier_name.value,
      country: supplierFormData.country.value,
      city: supplierFormData.city.value,
      address: supplierFormData.address.value,
      zip: supplierFormData.zip.value,
      phone: supplierFormData.phone.value,
      email: supplierFormData.email.value,
      business_number: supplierFormData.business_number.value,
      withholding_tax: +supplierFormData.withholding_tax.value,
      withholding_tax_expiration: supplierFormData.withholding_tax_expiration.value,
      general_ledger: supplierFormData.general_ledger.value,
      created_by: supplierFormData.created_by.value,
      included_vat: supplierFormData.included_vat.value,
      currency: supplierFormData.currency.value,

      bank_name: supplierFormData.bank_name.value,
      bank_number: supplierFormData.bank_number.value,
      bank_branch_number: supplierFormData.bank_branch_number.value,
      bank_account_number: supplierFormData.bank_account_number.value,
    }

    const supplierAgents = [..._.values(supplierUserAgentsChanges).filter(Boolean), ..._.values(supplierCompanyAgentsChanges).filter(Boolean)]

    onUpdateSupplier(supplierUpdate, supplierAgents)
      .then(() => {
        setSupplierUserAgentsChanges({});
        setSupplierCompanyAgentsChanges({});
      })
      // .then(() => {
      //   setMode(Mode.view);
      // })
      .catch((e) => { });
  }

  const supplierAgentChangeHandler = ({ userId, companyId }, supplierAgent) => {
    if (userId)
      setSupplierUserAgentsChanges({ ...supplierUserAgentsChanges, [userId]: supplierAgent });

    if (companyId)
      setSupplierCompanyAgentsChanges({ ...supplierCompanyAgentsChanges, [companyId]: supplierAgent });
  }

  const onCancelClick = () => {
    // setMode(Mode.view);
    setSupplierUserAgentsChanges({});
    setSupplierCompanyAgentsChanges({});
    setSupplierFormData(getSupplierFormObject(supplier));
  }

  return <div className="supplier-details-wrapper">
    <div className="supplier-details-tabs-wrapper">
      <YeulsTabs
        config={tabsConfig}
        onChange={setCurrentTabId}/>
      {/*{mode === Mode.view && <YeulsButton onClick={() => setMode(Mode.edit)}>{t("edit")}</YeulsButton>}*/}
      {mode === Mode.edit && <div className="supplier-details-button-container">
        <YeulsButton variant="negative" onClick={() => onCancelClick()}>{t("reset")}</YeulsButton>
        <YeulsButton onClick={onSaveClick}>{t("save")}</YeulsButton>
      </div>}
    </div>
    <div className="supplier-details-content">
      {currentTabId === tabIds.general &&
      <GeneralSupplierDetails mode={mode} supplier={supplier} supplierFormData={supplierFormData}
                              setSupplierFormData={setSupplierFormData}/>}
      {currentTabId === tabIds.bank &&
      <BankSupplierDetails mode={mode} supplier={supplier} supplierFormData={supplierFormData}
                           setSupplierFormData={setSupplierFormData}/>}
      {currentTabId === tabIds.agents &&
      <SupplierAgentsDetails
        mode={mode} users={users} companies={companies}
        supplier={supplier} onSupplierAgentChange={supplierAgentChangeHandler}
        supplierUserAgentsChanges={supplierUserAgentsChanges}
        supplierCompanyAgentsChanges={supplierCompanyAgentsChanges}
      />}
    </div>
  </div>
}

function getSupplierFormObject(supplier) {
  return {
    supplier_name: { value: supplier.supplier_name, error: false },
    country: { value: supplier.country, error: false },
    city: { value: supplier.city, error: false },
    address: { value: supplier.address, error: false },
    zip: { value: supplier.zip, error: false },
    phone: { value: supplier.phone, error: false },
    email: { value: supplier.email, error: false },
    business_number: { value: supplier.business_number, error: false },
    withholding_tax: { value: supplier.withholding_tax, error: false },
    withholding_tax_expiration: { value: supplier.withholding_tax_expiration, error: false },
    general_ledger: { value: supplier.general_ledger, error: false },
    created_by: { value: supplier.created_by, error: false },
    included_vat: { value: supplier.included_vat, error: false },
    currency: { value: supplier.currency, error: false },

    bank_name: { value: supplier.bank_name, error: false },
    bank_number: { value: supplier.bank_number, error: false },
    bank_branch_number: { value: supplier.bank_branch_number, error: false },
    bank_account_number: { value: supplier.bank_account_number, error: false },
  }
}

export default SupplierDetails