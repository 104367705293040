import * as _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './UserDetails.scss';
import YeulsTabs from '../../../yeuls-tabs';
import YeulsButton from '../../../yeuls-button';
import { Mode } from '../../../../shared/constants';
import GeneralUserDetails from './general-user-details';
import TermsAndConditionsUserDetails from './terms-and-conditions-user-detais';
import BankUserDetails from './bank-user-datails';
import InsuranceUserDetails from './insurance-user-details';
import UserCompaniesListDetails from './user-companies-list-details';
import UserClientsListDetails from './user-clients-list-details';
import UserSuppliersListDetails from './user-suppliers-list-details';
import AgentDocuments from '../../common-components/agent-documents';
import { enumToOptions, jsonToFormData } from '../../common-components/utils';
import { getUserAccountDocuments } from '../../../../api/requests/users';
import { UserAccountDocuments } from './user-account-documents';
import { AgentDocumentType } from '../../../../api/constants';

const tabIds = {
  general: 0,
  termsAndConditions: 1,
  bank: 2,
  insurance: 3,
  companies: 4,
  clients: 5,
  suppliers: 6,
  documents: 7,
  accountDocuments: 8,
};

const UserDetails = (props) => {
  const { user, onUpdateUser, companies, clients, suppliers } = props;
  const { t, i18n } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.general);
  const [mode, setMode] = useState(Mode.edit);
  const [userFormData, setUserFormData] = useState(getUserFormObject(user));
  const [userCompaniesChanges, setUserCompaniesChanges] = useState({});
  const [userClientsChanges, setUserClientsChanges] = useState({});
  const [userSuppliersChanges, setUserSuppliersChanges] = useState({});
  const [userInvoiceChanges, setUserInvoiceChanges] = useState({});
  const [userExpenseChanges, setUserExpenseChanges] = useState({});
  const [userDocuments, setUserDocuments] = useState([]);
  const [documentText, setDocumentText] = useState(null);
  const tabsConfig = useMemo(
    () => [
      { id: tabIds.general, label: t('general'), defaultSelectedTab: true },
      { id: tabIds.termsAndConditions, label: t('terms-and-conditions') },
      { id: tabIds.bank, label: t('bank') },
      { id: tabIds.insurance, label: t('insurance') },
      {
        id: tabIds.companies,
        label: t('companies'),
        itemsCount: user.companies?.length,
      },
      {
        id: tabIds.clients,
        label: t('clients'),
        itemsCount: user.clients?.length,
      },
      {
        id: tabIds.suppliers,
        label: t('suppliers'),
        itemsCount: user.suppliers?.length,
      },
      {
        id: tabIds.documents,
        label: t('documents'),
        itemsCount:
          user.invoices?.length +
          user.expenses?.length +
          user.receipts?.length +
          user.salaries?.length,
      },
      {
        id: tabIds.accountDocuments,
        label: t('account-documents'),
        itemsCount: `${userDocuments?.length}/3`,
      },
    ],
    // eslint-disable-next-line
    [i18n.language, user, userDocuments]
  );

  useEffect(() => {
    if (mode === Mode.edit) setUserFormData(getUserFormObject(user));
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (user && user.id) {
      getUserAccountDocuments(user.id).then((res) => {
        setUserDocuments(res);
      });
    }
  }, [user]);

  useEffect(() => {
    const remainingDocuments = enumToOptions(AgentDocumentType)
      .filter(
        (type) => !userDocuments?.some((doc) => doc.fileType === type.value)
      )
      .map((type) => t(type.label));
    if (remainingDocuments.length > 0) {
      setDocumentText(`${t('missing-documents')}: ${remainingDocuments.join(', ')}`);
    }else{
      setDocumentText(null);
    }
  }, [userDocuments, i18n.language]);

  const onSaveClick = () => {
    const userUpdate = {
      ...user,
      identity_number: userFormData.identity_number.value,
      firstname: userFormData.firstname.value,
      lastname: userFormData.lastname.value,
      email: userFormData.email.value,
      oketz_number: userFormData.oketz_number.value,
      phone: userFormData.phone.value,
      country: userFormData.country.value,
      city: userFormData.city.value,
      zip: userFormData.zip.value,
      address: userFormData.address.value,
      car_details: userFormData.car_details.value,
      bank_name: userFormData.bank_name.value,
      bank_number: userFormData.bank_number.value,
      bank_branch_number: userFormData.bank_branch_number.value,
      bank_account_number: userFormData.bank_account_number.value,
      insurance_notes: userFormData.insurance_notes.value,
      business_number: userFormData.business_number.value,
      withholding_tax: +userFormData.withholding_tax.value,
      withholding_tax_expiration: userFormData.withholding_tax_expiration.value,
      general_ledger: userFormData.general_ledger.value,
      userTerms: {
        ...(user.userTerms || {}),
        yeuls_fee: +userFormData.yeuls_fee.value,
        max_yeuls_fee: +userFormData.max_yeuls_fee.value,
        included_vat: +userFormData.included_vat.value,
        salary_date: userFormData.salary_date.value,
        calculation_type: userFormData.calculation_type.value,
        report_type: userFormData.report_type.value,
        report_fee: +userFormData.report_fee.value,
        currency: userFormData.currency.value,
        start_date: userFormData.start_date.value,
        end_date: userFormData.end_date.value,
        notes: userFormData.notes.value,
      },
      isRemoveContract: userFormData.isRemoveContract.value,
      isRemoveIdScan: userFormData.isRemoveIdScan.value,
      isRemoveTaxForm: userFormData.isRemoveTaxForm.value,
    };

    const formData = {
      contract:
        userFormData.contract.value && userFormData.contract.value.length
          ? userFormData.contract.value[0]
          : null,
      idScan:
        userFormData.idScan.value && userFormData.idScan.value.length
          ? userFormData.idScan.value[0]
          : null,
      taxForm:
        userFormData.taxForm.value && userFormData.taxForm.value.length
          ? userFormData.taxForm.value[0]
          : null,
      formData: JSON.stringify(userUpdate),
    };

    onUpdateUser(
      userUpdate,
      userCompaniesChanges,
      userClientsChanges,
      userSuppliersChanges,
      userInvoiceChanges,
      userExpenseChanges,
      jsonToFormData(formData),
      true
    )
      .then(() => {
        setUserCompaniesChanges({});
        setUserClientsChanges({});
        setUserSuppliersChanges({});
        setUserInvoiceChanges({});
      })
      // .then(() => {
      //   setMode(Mode.view);
      // })
      .catch((e) => {});
  };

  const userCompanyChangeHandler = (companyId, userCompany) => {
    setUserCompaniesChanges({
      ...userCompaniesChanges,
      [companyId]: userCompany,
    });
  };

  const userClientChangeHandler = (value, clientId) => {
    setUserClientsChanges({
      ...userClientsChanges,
      [clientId]: value
        ? _.find(user.userClients, { client_id: clientId }) || {
            client_id: clientId,
          }
        : null,
    });
  };

  const userSupplierChangeHandler = (value, supplierId) => {
    setUserSuppliersChanges({
      ...userSuppliersChanges,
      [supplierId]: value
        ? _.find(user.userSuppliers, { supplier_id: supplierId }) || {
            supplier_id: supplierId,
          }
        : null,
    });
  };

  const onCancelClick = () => {
    // setMode(Mode.view);
    setUserClientsChanges({});
    setUserSuppliersChanges({});
    setUserFormData(getUserFormObject(user));
  };

  return (
    <div className="user-details-wrapper">
      <div className="user-details-tabs-wrapper">
        <YeulsTabs config={tabsConfig} onChange={setCurrentTabId} />
        {/*{mode === Mode.view && <YeulsButton onClick={() => setMode(Mode.edit)}>{t("edit")}</YeulsButton>}*/}
        {mode === Mode.edit && (
          <div className="user-details-button-container">
            <YeulsButton variant="negative" onClick={() => onCancelClick()}>
              {t('reset')}
            </YeulsButton>
            <YeulsButton onClick={onSaveClick}>{t('save')}</YeulsButton>
          </div>
        )}
      </div>
      <div style={{ marginBottom: '10px' }}>
        {documentText && (
          <span className={i18n.language !== 'heb' ? 'red' : 'red-reverse'}>{documentText}</span>
        )}
      </div>
      <div className="user-details-content">
        {currentTabId === tabIds.general && (
          <GeneralUserDetails
            mode={mode}
            user={user}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
        )}
        {currentTabId === tabIds.termsAndConditions && (
          <TermsAndConditionsUserDetails
            mode={mode}
            user={user}
            companyFormData={userFormData}
            setCompanyFormData={setUserFormData}
          />
        )}
        {currentTabId === tabIds.bank && (
          <BankUserDetails
            mode={mode}
            user={user}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
        )}
        {currentTabId === tabIds.insurance && (
          <InsuranceUserDetails
            mode={mode}
            user={user}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
          />
        )}
        {currentTabId === tabIds.companies && (
          <UserCompaniesListDetails
            mode={mode}
            user={user}
            companies={companies}
            onUserCompanyChange={userCompanyChangeHandler}
          />
        )}
        {currentTabId === tabIds.clients && (
          <UserClientsListDetails
            mode={mode}
            user={user}
            clients={clients}
            onUserClientChange={userClientChangeHandler}
            userClientsChanges={userClientsChanges}
          />
        )}
        {currentTabId === tabIds.suppliers && (
          <UserSuppliersListDetails
            mode={mode}
            user={user}
            suppliers={suppliers}
            onUserSupplierChange={userSupplierChangeHandler}
            userSuppliersChanges={userSuppliersChanges}
          />
        )}
        {currentTabId === tabIds.documents && (
          <AgentDocuments
            invoices={user.invoices}
            expenses={user.expenses}
            salaries={user.salaries}
            receipts={user.receipts}
            suppliers={suppliers}
            clients={clients}
            invoiceChanges={userInvoiceChanges}
            setInvoiceChanges={setUserInvoiceChanges}
            expenseChanges={userExpenseChanges}
            setExpenseChanges={setUserExpenseChanges}
          />
        )}
        {currentTabId === tabIds.accountDocuments && (
          <UserAccountDocuments
            userFormData={userFormData}
            setUserFormData={setUserFormData}
            userDocuments={userDocuments}
          />
        )}
      </div>
    </div>
  );
};

function getUserFormObject(user) {
  return {
    firstname: { value: user.firstname, error: false },
    lastname: { value: user.lastname, error: false },
    identity_number: { value: user.identity_number, error: false },
    oketz_number: { value: user.oketz_number, error: false },
    email: { value: user.email, error: false },
    phone: { value: user.phone, error: false },
    country: { value: user.country, error: false },
    city: { value: user.city, error: false },
    zip: { value: user.zip, error: false },
    address: { value: user.address, error: false },
    car_details: { value: user.car_details, error: false },
    insurance_notes: { value: user.insurance_notes, error: false },
    business_number: { value: user.business_number, error: false },
    withholding_tax: { value: user.withholding_tax, error: false },
    withholding_tax_expiration: {
      value: user.withholding_tax_expiration,
      error: false,
    },
    general_ledger: { value: user.general_ledger, error: false },

    yeuls_fee: { value: user.userTerms?.yeuls_fee, error: false },
    max_yeuls_fee: { value: user.userTerms?.max_yeuls_fee, error: false },
    registration_fee: { value: user.userTerms?.registration_fee, error: false },
    registration_fee_free: {
      value: !user.userTerms?.registration_fee,
      error: false,
    },
    included_vat: { value: user.userTerms?.included_vat, error: false },
    salary_date: { value: user.userTerms?.salary_date, error: false },
    calculation_type: { value: user.userTerms?.calculation_type, error: false },
    report_type: { value: user.userTerms?.report_type, error: false },
    currency: { value: user.userTerms?.currency, error: false },
    start_date: { value: user.userTerms?.start_date, error: false },
    end_date: { value: user.userTerms?.end_date, error: false },
    report_fee: { value: user.userTerms?.report_fee, error: false },
    notes: { value: user.userTerms?.notes, error: false },

    bank_name: { value: user.bank_name, error: false },
    bank_number: { value: user.bank_number, error: false },
    bank_branch_number: { value: user.bank_branch_number, error: false },
    bank_account_number: { value: user.bank_account_number, error: false },

    contract: { value: null, error: false },
    isRemoveContract: { value: false, error: false },
    idScan: { value: null, error: false },
    isRemoveIdScan: { value: false, error: false },
    taxForm: { value: null, error: false },
    isRemoveTaxForm: { value: false, error: false },
  };
}

export default UserDetails;
