import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import './YeulsTabs.scss';

const YeulsTabs = (props) => {
  const { config, onChange } = props;
  const [currentTab, setCurrentTab] = useState();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState({});

  useEffect(() => {
    const tabsConfig = {};

    _.forEach(config, (tab) => {
      tabsConfig[tab.id] = {
        id: tab.id,
        label: t(tab.label),
        itemsCount: tab.itemsCount,
      };

      if (tab.defaultSelectedTab && !currentTab) {
        setCurrentTab(tabsConfig[tab.id]);
      }
    });

    if (currentTab) {
      setCurrentTab(tabsConfig[currentTab.id]);
    }

    setTabs(tabsConfig);
    // eslint-disable-next-line
  }, [config, i18n.language]);

  return (
    <Tabs
      className="tabs"
      value={_.findIndex(config, (c) => c.id === currentTab?.id)}
      onChange={(event, value) => {
        const clickedTab = config[value];
        onChange(clickedTab.id);
        setCurrentTab(tabs[clickedTab.id]);
      }}
    >
      {_.map(Object.values(tabs), ({ id }) => (
        <Tab
          classes={{ selected: 'selected-tab', root: 'tab-root' }}
          key={id}
          label={
            <div className="tab">
              <span>{tabs[id].label}</span>
              {((tabs[id]?.itemsCount) || (typeof tabs[id]?.itemsCount === "number" && tabs[id]?.itemsCount >= 0)) && (
                <span className={'items-count'}>{tabs[id].itemsCount}</span>
              )}
            </div>
          }
        />
      ))}
    </Tabs>
  );
};

export default YeulsTabs;
