import React from 'react';
import { TablePagination, TableSimple } from 'react-pagination-table';

import "./YeulsTable.scss";

const YeulsTable = (props) => {
  const { pagination = true, data, headers, columns, arrayOption = [], ...otherProps } = props;

  if (pagination) {
    return <TablePagination
      data={data}
      headers={headers}
      columns={columns}
      arrayOption={arrayOption}
      {...otherProps}
      className={`table-container ${props.className}`}
    />
  }

  return <TableSimple
    data={data}
    headers={headers}
    columns={columns}
    arrayOption={arrayOption}
    className={`table-container ${props.className}`}
  />
}

export default YeulsTable;