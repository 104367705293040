import * as _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

import "./SupplierAgentsList.scss";
import YeulsTabs from "../../../../yeuls-tabs";
import AccountsSearchField from "../../../common-components/accounts-search-field";
import YeulsTable from "../../../../yeuls-table";

const tabIds = {
  agentsList: 0,
  companiesList: 1
}

const SupplierAgentsList = (props) => {
  const { users, companies, supplierAgents, setSupplierAgents } = props;
  const [filterValue, setFilterValue] = useState("");
  const { i18n, t } = useTranslation();
  const [currentTabId, setCurrentTabId] = useState(tabIds.agentsList);
  const [supplierUsers, setSupplierUsers] = useState({});
  const [supplierCompanies, setSupplierCompanies] = useState({});

  const tabsConfig = useMemo(() => [
    {
      id: tabIds.agentsList,
      label: "agents-list",
      defaultSelectedTab: currentTabId === tabIds.agentsList
    }, {
      id: tabIds.companiesList,
      label: "companies-list"
      // eslint-disable-next-line
    }], [i18n.language]);

  const userHeaders = useMemo(() => [".", t("name"), t("client-number"), t("business-number")], [i18n.language]);
  const userColumns = "selectedView.nameView.santec_account_id.business_number";
  const companyHeaders = useMemo(() => [".", t("company-name"), t("client-number"), t("business-number")], [i18n.language]);
  const companyColumns = "selectedView.company_name.santec_account_id.business_number";

  const filteredUsers = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    return _.filter(users, (user) =>
      ( user.firstname?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.email?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);
  }, [users, filterValue]);

  const filteredCompanies = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    return _.filter(companies, (company) =>
      ( company.company_name?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( company.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( company.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);
  }, [companies, filterValue]);

  const mappedUsers = useMemo(() => {
    return _.map(filteredUsers, (user) => {
      let nameView = user.firstname;
      if (user.lastname)
        nameView += ` ${user.lastname}`

      return {
        ...user,
        nameView,
        selectedView: <Checkbox checked={!!supplierUsers[user.id]}
                                onChange={(e) => updateSupplierUser(user.id, e.target.checked)}/>,
      }
    })
  }, [filteredUsers, supplierAgents]);

  const mappedCompanies = useMemo(() => {
    return _.map(filteredCompanies, (company) => {
      return {
        ...company,
        selectedView: <Checkbox checked={!!supplierCompanies[company.id]}
                                onChange={(e) => updateSupplierCompany(company.id, e.target.checked)}/>,
      }
    })
  }, [filteredUsers, supplierAgents]);


  useEffect(() => {
    const sUsers = {};
    const sCompanies = {};

    _(supplierAgents)
      .filter((agent) => agent.user_id)
      .forEach((agent) => sUsers[agent.user_id] = agent);

    _(supplierAgents)
      .filter((agent) => agent.company_id)
      .forEach((agent) => sCompanies[agent.company_id] = agent);

    setSupplierUsers(sUsers);
    setSupplierCompanies(sCompanies);
  }, [supplierAgents]);

  const updateSupplierUser = (userId, include) => {
    if (supplierUsers[userId] && !include)
      setSupplierAgents(_.filter(supplierAgents, (agent) => agent.user_id !== userId))
    else
      setSupplierAgents([...supplierAgents, { user_id: userId }])
  }

  const updateSupplierCompany = (companyId, include) => {
    if (supplierCompanies[companyId] && !include)
      setSupplierAgents(_.filter(supplierAgents, (agent) => agent.company_id !== companyId))
    else
      setSupplierAgents([...supplierAgents, { company_id: companyId }])
  }

  return <div className="supplier-agents-details-wrapper">
    <YeulsTabs
      config={tabsConfig}
      onChange={(value) => {
        setFilterValue("");
        setCurrentTabId(value)
      }}/>
    {currentTabId === tabIds.agentsList && <>
      <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>
     <YeulsTable
        data={mappedUsers}
        headers={userHeaders}
        columns={userColumns}
        totalCount={mappedUsers.length}
        perPageItemCount={12}
        partialPageCount={3}
        nextPageText=">"
        prePageText="<"
        className="agents-table-container"
      />
    </>}
    {currentTabId === tabIds.companiesList && <>
      <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue} showSearchMessage={false}/>
      <YeulsTable
        data={mappedCompanies}
        headers={companyHeaders}
        columns={companyColumns}
        totalCount={mappedCompanies.length}
        perPageItemCount={12}
        partialPageCount={3}
        nextPageText=">"
        prePageText="<"
        className="agents-table-container"
      />
    </>}
  </div>
}

export default SupplierAgentsList;