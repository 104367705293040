import React from 'react';
import { useTranslation } from 'react-i18next';

import { AiOutlineBank } from 'react-icons/ai';

import Text from '../../containers/Text';
import './HomePage.scss';

const BankInformation = () => {
  const { t } = useTranslation();

  return (
    <div className="bank-info">
      <div className="contact-info">
        <div>
          <AiOutlineBank className="info-icon" />
          <Text text={t('bank-info-title')} />
        </div>
      </div>
      <div className="contact-info">
        <div>
          <AiOutlineBank className="info-icon" />
          <Text text={t('bank-title-1')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-mizrachi-tefachot')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-number-20')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-brach-number')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-account-number-1')} />
        </div>
      </div>

      <div className="contact-info">
        <a
          target="_blank"
          href="https://firebasestorage.googleapis.com/v0/b/yeuls-e3a84.appspot.com/o/bank_details_us.pdf?alt=media&token=d62e168f-d3f9-41be-8f3e-665107dc15ad"
          rel="noopener noreferrer"
        >
          <Text text={t('us-bank-details')} />
        </a>
        {/* <div>
          <AiOutlineBank className="info-icon" />
          <Text text={t('bank-title-2')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-name')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-number')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-brach-number')} />
        </div>
        <div className="info-text">
          <Text text={t('bank-account-number-2')} />
        </div> */}
      </div>
    </div>
  );
};

export default BankInformation;
