import axios from 'axios';
import { constants } from '../constants';
import * as endpoints from "../endpoints";

const baseUrl = constants.baseURL;

export const getAllExpenses = async () => {
  return axios({
    method: 'get',
    url: endpoints.Expenses.EXPENSE,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const updateExpense = (data) => {
  return axios({
    method: 'put',
    url: endpoints.Expenses.EXPENSE_UPDATE,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
}