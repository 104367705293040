import axios from "axios";

import * as endpoints from "../endpoints";
import { constants } from "../constants";
import { Expenses } from "../endpoints";

const baseUrl = constants.baseURL;

export const updateSalary = (data) => {
  return axios({
    method: 'put',
    url: endpoints.Salary.SALARY_UPDATE,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
}

export const addSalary = (data) => {
  return axios({
    method: 'post',
    url: endpoints.Salary.SALARY_ADD,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
}

export const importSalary = (data) => {
  return axios({
    method: 'post',
    url: endpoints.Salary.IMPORT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
}


export const getAllSalaries = async () => {
  return axios({
    method: 'get',
    url: endpoints.Salary.SALARY,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getUserSalaries = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Salary.USER_SALARY}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const getUserOneZeroSix = async (id) => {
  return axios({
    method: 'get',
    url: `${endpoints.Salary.USER_ONE_ZERO_SIX}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};


export const getAllSalaryRecords = async () => {
  return axios({
    method: 'get',
    url: endpoints.Salary.SALARY_RECORD,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};