import * as _ from 'lodash';

export class NumbersService {
  static numberWithCommas(number, fractional = false) {
    if (_.isNil(number)) {
      return;
    }

    const commasNumber = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const splittedNumber = commasNumber.split('.');
    return ( !fractional || !splittedNumber[1] ) ? splittedNumber[0] : ( `${splittedNumber[0]}.${splittedNumber[1]?.[0] || ""}${splittedNumber[1]?.[1] || ""}` );
  }

  static roundCurrency(number) {
    return Math.round(number * 100) / 100;
  }

  static getCurrency(cur) {
    switch (cur) {
      case 1: {
        return '$';
      }
      case 2: {
        return '€';
      }
      default:
        return '₪';
    }
  };
}
