import React from 'react';

import {ReactComponent as ArrowNextIcon} from '../../../shared/icons/arrow-next-icon.svg';
import {ReactComponent as ArrowPreviousIcon} from '../../../shared/icons/arrow-previous-icon.svg';
import './DateCarouselItem.scss';

const DateCarouselItem = (props) => {
  const {value, onPreviousClick, onNextClick, className} = props;

  return <div className={`date-carousel-item-wrapper ${className||""}`}>
    <div className="date-carousel-item-button" onClick={onPreviousClick}><ArrowPreviousIcon/></div>
    <div className="date-carousel-item-value">{value}</div>
    <div className="date-carousel-item-button" onClick={onNextClick}><ArrowNextIcon/></div>
  </div>
}

export default DateCarouselItem;