import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Worker.scss';
import { WorkerList } from './worker-list';
import { getUserWorkers } from '../../api/requests/users';
import SearchField from '../search';
import Loader from '../loder';
import { filter } from 'lodash';
import UserPage from '../user-page';

const Worker = (props) => {
  const { t } = useTranslation();
  const [workers, setWorkers] = useState([]);
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const { user } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [updateTable, setUpdateTable] = useState(true);
  const [selectedWorker, setSelectedWorker] = useState(null);

  const handleRedirect = () => {
    if(selectedWorker){
      setSelectedWorker(null);
    }
  }

  useEffect(() => {
    if (!filterValue) {
      setFilteredWorkers(workers);
    } else {
      const filterValueToLowerCase = filterValue.toLowerCase();
      setFilteredWorkers(
        filter(
          workers,
          (worker) =>
            (worker.firstname?.toLowerCase() || '').indexOf(
              filterValueToLowerCase
            ) !== -1 ||
            (worker.lastname?.toLowerCase() || '').indexOf(
              filterValueToLowerCase
            ) !== -1 ||
            (worker.identity_number?.toString() || '').indexOf(
              filterValueToLowerCase
            ) !== -1 ||
            (worker.santec_account_id?.toString() || '').indexOf(
              filterValueToLowerCase
            ) !== -1 ||
            (worker.email?.toLowerCase() || '').indexOf(
              filterValueToLowerCase
            ) !== -1
        )
      );
    }

    setUpdateTable(false);
    setTimeout(() => setUpdateTable(true));
  }, [filterValue, workers]);

  useEffect(() => {
    if (user.id) {
      setIsLoading(true);
      getUserWorkers(user.id)
        .then((result) => {
          setIsLoading(false);
          setWorkers(result);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          setWorkers([]);
        });
    } else {
      setWorkers([]);
    }
  }, [user.id]);

  return (
    <div className="worker-page-wrapper">
      <header className="worker-header">
        <div>
          <div className="worker-header-full-width">
            <div className="worker-header-full-width-title" onClick={() => handleRedirect()}>{t('workers')}</div>
            {selectedWorker && (
              <>
                <div className="worker-header-full-width-title">{'>'}</div>
                <div className="worker-header-full-width-title">
                  {selectedWorker.firstname} {selectedWorker.lastname}
                </div>
              </>
            )}
          </div>
        </div>
      </header>
      {isLoading && <Loader />}
      <div>
        {!selectedWorker && (
          <>
            <div className="search-wrapper">
              <SearchField
                value={filterValue}
                onChange={setFilterValue}
                placeholderKey="search-by-worker-fields"
                onClearFilter={() => setFilterValue('')}
              />
            </div>
            <div className="tab-content-wrapper">
              {updateTable && (
                <WorkerList
                  workers={filteredWorkers}
                  setSelectedWorker={setSelectedWorker}
                />
              )}
            </div>
          </>
        )}
        {selectedWorker && (
          <UserPage
            user={selectedWorker}
            setUserInfo={() => {}}
            setMonthlyOverview={() => {}}
            readOnly={true}
          />
        )}
      </div>
    </div>
  );
};

export default Worker;
