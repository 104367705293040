import React from "react";
import { useTranslation } from "react-i18next";

import "./RateItemOptions.scss";
import { RateType } from "../../../../shared/constants";
import RateButton from "./rate-button";

const RateItemOptions = (props) => {
  const { onRateChange, day, rate } = props;
  const { t } = useTranslation();

  return <div className="monthly-report-employee-table-rate-wrapper">
    <RateButton onClick={() => onRateChange(day, RateType.halfDay)} isActive={rate === RateType.halfDay}>
      {t('half-day-50')}
    </RateButton>
    <RateButton onClick={() => onRateChange(day, RateType.transit)} isActive={rate === RateType.transit}>
      {t('transit-60')}
    </RateButton>
    <RateButton onClick={() => onRateChange(day, RateType.day)} isActive={rate === RateType.day}>
      {t('day-100')}
    </RateButton>
    <RateButton onClick={() => onRateChange(day, RateType.dayOff)} isActive={rate === RateType.dayOff}>
      {t('day-off-150')}
    </RateButton>
  </div>
}

export default RateItemOptions;