import * as _ from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import YeulsTable from "../../../../yeuls-table";

import "./OneZeroSixList.scss";
import DownloadInvoiceIcon from "../../../../../shared/icons/download-invoice-icon.svg";
import GreyDownloadInvoiceIcon from "../../../../../shared/icons/grey-download-invoice-icon.svg";

export const OneZeroSixList = (props) => {
  const { oneZeroSix, filterValue } = props;
  const { t, i18n } = useTranslation();

  const dateOptions = {year: 'numeric', month: 'numeric', day: 'numeric'};
  const headers = useMemo(() => [t("year"), t("file"), t("updated_at")], [i18n.language]);
  const columns = "year.fileLinkView.updatedDateView";

  const mappedOneZeroSix = useMemo(() => {
    const filterValueToLowerCase = filterValue?.toLowerCase();
    return _(oneZeroSix)
      .orderBy(["year"], ["desc", "desc"])
      .filter((ozs) => `${ozs.year || ""}`.indexOf(filterValueToLowerCase) !== -1)
      .map((ozs) => {
        const fileLinkView = ozs.file ? (<span className="centered" onClick={() => { window.open(ozs.file, '_blank') }}><img src={DownloadInvoiceIcon} alt="download invoice" />{ozs.fileName} </span>) : (<img src={GreyDownloadInvoiceIcon} alt="download invoice" />);
        const updatedDateView = ozs.updatedAt ? new Date(ozs?.updatedAt).toLocaleDateString("en-GB", dateOptions) : "";
        return {
          ...ozs,
          fileLinkView,
          updatedDateView
        }
      })
      .value();
  }, [oneZeroSix, filterValue])

  return <YeulsTable
    data={mappedOneZeroSix}
    headers={headers}
    columns={columns}
    totalCount={mappedOneZeroSix.length}
    perPageItemCount={12}
    partialPageCount={3}
    nextPageText=">"
    prePageText="<"
    className="one-zero-six-table-container"
  />;
}