import * as _ from "lodash";
import React, { useMemo } from "react";
import { Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./SalaryDoneIncomeTable.scss";
import YeulsTable from "../../../yeuls-table";

const SalaryDoneIncomeTable = (props) => {
  const { viewInvoices } = props;
  const incomeTableColumns = "paidToClientView.record_type.record_id.createdView.dateView.notesView.clientView.amountBeforeVatView.vatView.amountView";//.statusView";
  const { t } = useTranslation();

  const incomeTableHeaders = useMemo(() => [
    <div className="table-checkbox-checked-header">
      <Checkbox
        checked={true}
        classes={{
          checked: 'table-checkbox-checked',
          indeterminate: 'table-checkbox-checked',
          root: 'table-checkbox-root'
        }}/>
    </div>,
      t("record-type"), t("number"),t("created-date"),t("due-date"), t("notes"), t("client"), t("amount-before-vat"),  t("vat"), t("amount")],//, t("status")],
    // eslint-disable-next-line
    [viewInvoices]);

  return <div className="salary-done-income-table-wrapper">
    <YeulsTable
      data={viewInvoices}
      headers={incomeTableHeaders}
      columns={incomeTableColumns}
      pagination={false}/>
  </div>
}

export default SalaryDoneIncomeTable;