import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

import "./GeneralUserDetails.scss";
import { Mode } from "../../../../../shared/constants";
import DetailComponent from "../../../common-components/details-component";
import GeneralUserForm from "../../../common-components/forms/general-user-form";

const GeneralUserDetails = (props) => {
  const { t } = useTranslation();
  const { mode, user, userFormData, setUserFormData } = props;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  return <div className="user-details">
    {mode === Mode.view && <Grid container spacing={3}>
      <Grid item xs={6}><DetailComponent label={t("firstname")} value={user.firstname}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("lastname")} value={user.lastname}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("email")} value={user.email}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("phone")} value={user.phone}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("identity-number")} value={user.identity_number}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("business-number")} value={user.business_number}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("oketz-number")} value={user.oketz_number}/> </Grid>
      <Grid item xs={6}><DetailComponent
        label={t("general-ledger")}
        value={user.general_ledger ?
          new Date(user.general_ledger).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("withholding-tax")} value={user.withholding_tax}/> </Grid>
      <Grid item xs={6}><DetailComponent
        label={t("withholding-tax-expiration")}
        value={user.withholding_tax_expiration ?
          new Date(user.withholding_tax_expiration).toLocaleDateString("en-GB", dateOptions) : ""}/></Grid>
      <Grid item xs={6}><DetailComponent label={t("country")} value={user.country}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("city")} value={user.city}/> </Grid>
      <Grid item xs={6}><DetailComponent label={t("zip")} value={user.zip}/> </Grid>
      <Grid item xs={12}><DetailComponent label={t("address")} value={user.address}/> </Grid>
      <Grid item xs={12}><DetailComponent label={t("car-details")} value={user.car_details}/> </Grid>
    </Grid>}
    {mode === Mode.edit && <GeneralUserForm generalFormData={userFormData} setGeneralFormData={setUserFormData}/>}
  </div>
}

export default GeneralUserDetails;

