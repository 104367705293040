import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getMissedSalaries = async (data) => {
  return axios({
    method: 'get',
    url: endpoints.Salary.SALARY_MISSED,
    baseURL: baseUrl,
    data: data,
  });
};

export const getMissedMonthly = async (data) => {
    return axios({
      method: 'get',
      url: endpoints.Salary.MONTHLY_MISSED,
      baseURL: baseUrl,
      data: data,
    });
  };
  