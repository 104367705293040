export const validateGeneralUserForm = (userFormData, setUserFormData) => {
  const formData = {};

  if (!userFormData.firstname?.value) {
    formData.firstname = { ...userFormData.firstname, error: true }
  }

  if (!userFormData.lastname?.value) {
    formData.lastname = { ...userFormData.lastname, error: true }
  }

  if (!userFormData.email?.value || !validateEmail(userFormData.email?.value)) {
    formData.email = { ...userFormData.email, error: true }
  }

  if (!userFormData.phone?.value) {
    formData.phone = { ...userFormData.phone, error: true }
  }

  if (!userFormData.identity_number?.value) {
    formData.identity_number = { ...userFormData.identity_number, error: true }
  }

  if (Object.keys(formData).length > 0) {
    setUserFormData({ ...userFormData, ...formData });

    return false;
  }

  return true;
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateTermForm = (termFormData, setTermFormData) => {
  const formData = {};

  if (!termFormData.yeuls_fee?.value) {
    formData.yeuls_fee = { ...termFormData.yeuls_fee, error: true }
  }

  if (!termFormData.max_yeuls_fee?.value) {
    formData.max_yeuls_fee = { ...termFormData.max_yeuls_fee, error: true }
  }

  if (!termFormData.calculation_type?.value) {
    formData.calculation_type = { ...termFormData.calculation_type, error: true }
  }

  if (!termFormData.salary_date?.value) {
    formData.salary_date = { ...termFormData.salary_date, error: true }
  }

  if (!termFormData.report_type?.value) {
    formData.report_type = { ...termFormData.report_type, error: true }
  }

  if (!termFormData.report_fee?.value) {
    formData.report_fee = { ...termFormData.report_fee, error: true }
  }

  if (!termFormData.currency?.value) {
    formData.currency = { ...termFormData.currency, error: true }
  }

  if (Object.keys(formData).length > 0) {
    setTermFormData({ ...termFormData, ...formData });

    return false;
  }

  return true;
}

export const validateGeneralCompanyForm = (companyFormData, setCompanyFormData) => {
  const formData = {};

  if (!companyFormData.company_name?.value) {
    formData.company_name = { ...companyFormData.company_name, error: true }
  }

  if (!companyFormData.business_number?.value) {
    formData.business_number = { ...companyFormData.business_number, error: true }
  }

  if (!companyFormData.email?.value || !validateEmail(companyFormData.email?.value)) {
    formData.email = { ...companyFormData.email, error: true }
  }

  if (!companyFormData.phone?.value) {
    formData.phone = { ...companyFormData.phone, error: true }
  }

  if (Object.keys(formData).length > 0) {
    setCompanyFormData({ ...companyFormData, ...formData });

    return false;
  }

  return true;
}

export const validateGeneralClientForm = (clientFormData, setClientFormData) => {
  const formData = {};

  if (!clientFormData.client_name?.value) {
    formData.client_name = { ...clientFormData.client_name, error: true }
  }

  if (!clientFormData.business_number?.value) {
    formData.business_number = { ...clientFormData.business_number, error: true }
  }

  if (!clientFormData.email?.value || !validateEmail(clientFormData.email?.value)) {
    formData.email = { ...clientFormData.email, error: true }
  }

  if (!clientFormData.phone?.value) {
    formData.phone = { ...clientFormData.phone, error: true }
  }

  if (Object.keys(formData).length > 0) {
    setClientFormData({ ...clientFormData, ...formData });

    return false;
  }

  return true;
}

export const validateGeneralSupplierForm = (supplierFormData, setSupplierFormData) => {
  const formData = {};

  if (!supplierFormData.supplier_name?.value) {
    formData.supplier_name = { ...supplierFormData.supplier_name, error: true }
  }

  if (!supplierFormData.business_number?.value) {
    formData.business_number = { ...supplierFormData.business_number, error: true }
  }

  if (!supplierFormData.email?.value || !validateEmail(supplierFormData.email?.value)) {
    formData.email = { ...supplierFormData.email, error: true }
  }

  if (!supplierFormData.phone?.value) {
    formData.phone = { ...supplierFormData.phone, error: true }
  }

  if (Object.keys(formData).length > 0) {
    setSupplierFormData({ ...supplierFormData, ...formData });

    return false;
  }

  return true;
}

