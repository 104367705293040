import React  from "react";

import "./BankForm.scss";
import { Grid } from "@material-ui/core";
import InputComponent from "../../input-component";

const BankForm = (props) => {
  const { bankFormData, setBankFormData} = props;

  return <Grid spacing={3} container>
    <Grid item xs={6}>
      <InputComponent field="bank_name" tLabel="bank-name" formData={bankFormData} setFormData={setBankFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="bank_number" tLabel="bank-number" formData={bankFormData} setFormData={setBankFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="bank_branch_number" tLabel="bank-branch-number" formData={bankFormData} setFormData={setBankFormData}/>
    </Grid>
    <Grid item xs={6}>
      <InputComponent field="bank_account_number" tLabel="bank-account-number" formData={bankFormData} setFormData={setBankFormData}/>
    </Grid>
  </Grid>
}

export default BankForm;