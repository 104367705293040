import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getSuppliers = async () => {
  return axios({
    method: 'get',
    url: endpoints.Supplier.SUPPLIER,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};

export const createSupplier = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Supplier.SUPPLIER,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateSupplier = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.Supplier.SUPPLIER,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const createSupplierAgent = async (data) => {
  return axios({
    method: 'post',
    url: endpoints.Supplier.SUPPLIER_AGENT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const updateSupplierAgent = async (data) => {
  return axios({
    method: 'put',
    url: endpoints.Supplier.SUPPLIER_AGENT,
    baseURL: baseUrl,
    data: data,
  }).then((response) => response.data.result);
};

export const deleteSupplierAgent = async (id) => {
  return axios({
    method: 'delete',
    url: `${endpoints.Supplier.SUPPLIER_AGENT}/${id}`,
    baseURL: baseUrl,
  }).then((response) => response.data.result);
};