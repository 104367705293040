import * as _ from 'lodash';

export class SessionStorageService {
  static setAppLanguage(lng) {
    sessionStorage.setItem('language', lng);
  }

  static getAppLanguage() {
    const currentLanguage = sessionStorage.getItem('language');
    if (_.isNull(currentLanguage)) {
      sessionStorage.setItem('language', 'heb');
      return 'heb';
    }
    return currentLanguage;
  }
}
