import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, generatePath } from "react-router-dom";

import Text from '../../containers/Text';
import AppLogo from '../../shared/icons/logo.svg';
import BackArrow from '../../shared/icons/back-arrow-icon.svg';

import { AuthService } from '../../services/auth';

import SignForm from "./SignForm";
import Spinner from '../spinner';

import { SessionStorageService } from '../../shared/services/session-storage';
import '../../languages/i18n';
import { constants } from '../../api/constants';
import { Routes } from "../../api/routes";

import './Auth.scss';
import OTPGetCode from "./OTPGetCode";
import Admin from "./Admin";

const Auth = ({ setUserInfo, currentLng, changeLanguage, setLanguage }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [signIn, setSignIn] = useState(true);
  const [isAdminPage, setAdminPage] = useState(false);
  const [error, setError] = useState({ active: false, message: '' });
  const [email, setEmail] = useState('');
  const [code, setCode] = useState("");
  const [password, setPassword] = useState('');
  const [identityNumber, setIdentityNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    location.pathname === Routes.admin ? setAdminPage(true) : setAdminPage(false);
  }, [location.pathname])

  const handleGenerateOTP = async () => {
    if (email && identityNumber) {
      const res = await fetch(`${constants.baseURL}/one-time-password/generate`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email
        })
      }).then(res => res.json())

      return res
    }
  }

  const handleCheckOTP = async () => {
    const res = await fetch(`${constants.baseURL}/one-time-password/check`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: location.search.split("=")[1],
        otp: code
      })
    }).then(res => res.json())

    return res
  }

  const setNewLanguage = (lng) => {
    changeLanguage(lng);
    SessionStorageService.setAppLanguage(lng);
    setLanguage({ language: lng });
  };

  const auth = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (signIn) {
      if (!AuthService.isInvalidEmailFormat(email) || _.isEmpty(isAdminPage ? password : code)) {
        setError({
          active: true,
          message: 'Fill all empty space please',
        });
        setIsLoading(false);
        return;
      }

      const data = {
        email: isAdminPage ? email : location.search.split("=")[1],
        password: isAdminPage ? password : code,
      };

      const response = await fetch(
        `${constants.baseURL}/auth/signin`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const parsedResult = await response.json();

      if (parsedResult.statusCode === 401) {
        setError({ active: true, message: parsedResult.message });
        setIsLoading(false);
        return;
      }

      AuthService.setToken(parsedResult.result);
      const decodedToken = AuthService.getTokenData();
      setUserInfo({ ...decodedToken });
    } else {
      if (
        !AuthService.isInvalidEmailFormat(email) ||
        _.isEmpty(password) ||
        _.isEmpty(identityNumber)
      ) {
        setError({
          active: true,
          message: 'Fill all empty space please',
        });
        setIsLoading(false);
        return;
      }

      const data = {
        email: email,
        password: password,
        identity_number: identityNumber,
      };

      const response = await fetch(
        `${constants.baseURL}/auth/signup`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const parsedResult = await response.json();

      if (parsedResult.statusCode === 401) {
        setError({ active: true, message: parsedResult.message });
        setIsLoading(false);
        return;
      }

      AuthService.setToken(parsedResult.result);
      const decodedToken = AuthService.getTokenData();
      setUserInfo({ ...decodedToken });
    }
  };

  const handleGoBack = () => {
    history.push(Routes.signIn)
  }

  const handleError = (message) => {
    if (message) setError({ active: true, message: message })
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (location.pathname === Routes.signIn) {
        const path = generatePath(`${Routes.oneTimePassword}?email=${email}`)
        history.push(path);
        const { result } = await handleGenerateOTP();

        if (result.data === null) {
          handleError(result.message)
        }
      } else {
        auth(e)

      }

    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="auth-container">
      <div className="auth-lng-change-wrapper">
        <div className="lng-change-container">
          <button
            onClick={() => setNewLanguage('en')}
            className={`lng-button ${currentLng === 'en' ? 'active' : ''}`}
          >
            <Text text={t('eng-lang-button')}/>
          </button>
          <button
            onClick={() => setNewLanguage('heb')}
            className={`lng-button ${currentLng === 'heb' ? 'active' : ''}`}
          >
            <Text text={t('heb-lang-button')}/>
          </button>
        </div>
      </div>
      <div className="auth-wrapper">
        <form onSubmit={handleFormSubmit}>
          <div className="form-head">
            {location.pathname === Routes.oneTimePassword &&
            <img onClick={handleGoBack} src={BackArrow} className="back-arrow" alt="back arrow"/>
            }
            <Text
              text={<img src={AppLogo} className="app-logo" alt="app logo"/>}
            />
          </div>

          <div
            className={signIn ? 'fields-container' : 'fields-container signup'}
          >
            {isLoading ? (
              <Spinner/>
            ) : isAdminPage ? (
              <Admin config={{
                error,
                setEmail,
                setPassword,
              }}/>
            ) : location.pathname === Routes.oneTimePassword ? (
              <OTPGetCode config={{
                code,
                setCode,
                error,
                setError,
                action: handleCheckOTP
              }}/>
            ) : (
              <SignForm config={{
                email,
                setEmail,
                error,
                setError,
                password,
                setPassword,
                identityNumber,
                setIdentityNumber,
                signIn,
                setSignIn
              }}/>
            )
            }

          </div>

        </form>
        {!isAdminPage && (
          <div className="bottom">
            <p className="contact-us ">
              <Text text={t('contact-with-us')}/>
            </p>
            <p className="contact">
              <a href="mailto:info@yeuls.co.il" target="_blank" rel="noopener noreferrer">
                <Text text="portal@yeuls.co.il"/>
              </a>
            </p>
            <p className="contact number">
              <Text text="(+972) 073-2456502"/>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Auth;
