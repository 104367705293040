import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import YeulsTable from "../../../../yeuls-table";

import "./ExpensesList.scss";
import * as _ from "lodash";
import { NumbersService } from "../../../../../services/numbers";
import { Checkbox } from "@material-ui/core";

const ExpensesList = (props) => {
  const { expenses, suppliers, expenseChanges, setExpenseChanges, filterValue } = props;
  const { t, i18n } = useTranslation();

  const headers = useMemo(() => [t("code"), t("description"), t("supplier"), t("created-date"), t("due-date"), t("negdi"), t("nogl"), t("total"), t("currency"), t("rate"), t("total-nis"), t("paid-by"), t("paid"),], [i18n.language]);
  const columns = "type_code.description.supplierNameView.createdOnView.dateView.negdi.nogl.amountCurrencyView.currencyView.currencyRateView.amountNisView.paidByView.paidView";

  const mappedExpenses = useMemo(() => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const filterValueToLowerCase = filterValue?.toLowerCase();

    return _(expenses)
      .orderBy("created_on", "desc")
      .filter((expense) => `${expense.expense_number || ""}`.indexOf(filterValueToLowerCase) !== -1)
      .map((expense) => {
        const supplierNameView = _.find(suppliers, { id: expense.supplier_id })?.supplier_name || "";
        const createdOnView = expense.created_on ? new Date(expense?.created_on).toLocaleDateString("en-GB", dateOptions) : "";
        const dateView = expense.date ? new Date(expense?.date).toLocaleDateString("en-GB", dateOptions) : "";
        const amountCurrencyView = NumbersService.numberWithCommas(expense.amount_currency);
        const currencyView = NumbersService.getCurrency(expense.currency);
        const currencyRateView = NumbersService.roundCurrency(expense.currency_rate);
        const amountNisView = NumbersService.numberWithCommas(expense.amount);
        const paidByView = t(expense.paid_by);
        const paidView = <Checkbox
          classes={{ checked: 'table-checkbox-checked', root: 'table-checkbox-root' }}
          checked={expenseChanges[expense.id] ? !!expenseChanges[expense.id].paid : !!expense.paid}
          onChange={(e) => setExpenseChanges({
            ...expenseChanges,
            [expense.id]: { ...expense, paid: e.target.checked }
          })}
        />;

        return {
          ...expense,
          supplierNameView,
          createdOnView,
          dateView,
          amountCurrencyView,
          currencyView,
          currencyRateView,
          amountNisView,
          paidByView,
          paidView
        }
      })
      .value();
  }, [expenses, expenseChanges, filterValue])

  return <YeulsTable
    data={mappedExpenses}
    headers={headers}
    columns={columns}
    totalCount={mappedExpenses.length}
    perPageItemCount={12}
    partialPageCount={3}
    nextPageText=">"
    prePageText="<"
    className="expenses-table-container"
  />;
}

export default ExpensesList;