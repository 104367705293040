import * as _ from "lodash";
import React, { useMemo, useState } from "react";

import "./CompanyUsersList.scss";
import CompanyUserItem from "./company-user-item";
import AccountsSearchField from "../../../common-components/accounts-search-field";

const UserCompaniesList = (props) => {
  const { users, companyUsers, setCompanyUsers } = props;
  const [filterValue, setFilterValue] = useState("");

  const filteredUsers = useMemo(() => {
    if (filterValue?.length < 1)
      return [];

    const filterValueToLowerCase = filterValue?.toLowerCase();
    return _.filter(users, (user) =>
      ( user.firstname?.toLowerCase() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.business_number?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.santec_account_id?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1 ||
      ( user.email?.toString() || "" ).indexOf(filterValueToLowerCase) !== -1);
  }, [users, filterValue]);

  const companyUserChangeHandler = (userId, companyUser) => {
    if (companyUser) {
      setCompanyUsers([..._.filter(companyUsers, (uc) => uc.user_id !== userId), companyUser])
    } else {
      setCompanyUsers([..._.filter(companyUsers, (uc) => uc.user_id !== userId)])
    }
  }

  return <div className="company-users-details-wrapper">
    <AccountsSearchField filterValue={filterValue} setFilterValue={setFilterValue}/>
    {_.map(filteredUsers, (user) =>
      <React.Fragment key={user.id}>
        <CompanyUserItem
          user={user}
          onChange={(companyUser) => companyUserChangeHandler(user.id, companyUser)}/>
      </React.Fragment>)}
  </div>
}

export default UserCompaniesList;