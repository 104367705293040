import axios from 'axios';
import * as endpoints from '../endpoints';
import { constants } from '../constants';

const baseUrl = constants.baseURL;

export const getMissedInvoiceReceipts = async (data) => {
  return axios({
    method: 'get',
    url: endpoints.InvoiceReceipts.MISSED_INVOICE_RECEIPT,
    baseURL: baseUrl,
    data: data,
  });
};
